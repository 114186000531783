import {gql} from 'apollo-boost';

export const CREATE_CLAIM = gql`
  mutation createClaim($code: String!, $claim: String!, $type: String!) {
    createClaim(data: { code: $code, claim: $claim,  type: $type}) {
      _id
      code
      date
      claim
      type
    }
  }
`;

export const NOTIFICATE_GROUP = gql`
     mutation notificateGroup($client_origin:String!, $group_id: String!, $accountingComment: String!, $subtitle: String!, $text: String!, $link: String!){
        notificateGroup(
            data: {
                client_origin: $client_origin
                group_id: $group_id
                accountingComment: $accountingComment
                subtitle: $subtitle
                text: $text
                link: $link
            }
        ) {
            _id
            user {
                _id
                name
                email
            }
            subtitle
            text
            read
        }
    }
`;

export const ADD_NEW_TICKET = gql`
    mutation AddTiket($ticket: TicketInput!) {
        addTiket(ticket: $ticket) {
            _id
            code
            client
            createdAt
            name
            open
            notificaciones {
                _id
                text
                createdAt
                typeName
                resolved
                user {
                    name
                }
            }
        }
    }
`;

export const GET_CLAIM_TIPE = gql`
    query FindPedidoCliente {
        findPedidoCliente {
            _id
            name
        }
    }
`;

export const GET_RECLAMO_CCTE = gql`
    query FindReclamoCuentaCorriente {
        findReclamoCuentaCorriente {
            _id
            name
        }
    }
`;

export const GET_HISTORIAL_CLAIM = gql`
    query GetTicketOnlyPedidos($client: String!) {
        getTicketOnlyPedidos(client: $client) {
            code
            notificaciones {
                _id
                text
                resolved
                ntType{
                  _id
                  name
                }                
                resolver_date
                createdAt                
                notifLogis {
                    asunto
                }
            }
        }
    }
`;
