import { gql } from "apollo-boost";

export const GET_ALL_ARTICLES_BY_USER = gql`
  query listAllArticles($code: String!) {
    listAllArticles(code: $code) {
      _id
      code
      lista_id
      description
      description_alt
      clasificacion
      price
      image
    }
  }
`;

export const ADD_RECIBO = gql`
  mutation addTkRecibo($obj: tk_recibosInput!) {
    addTkRecibo(rec: $obj) {
      _id
    }
  }
`;

export const ADD_RECIBO_MP = gql`
  mutation AddTkReciboMp($obj: tk_recibosInput!) {
    addTkReciboMp(rec: $obj) {
      id
      url
    }
  }
`;

export const ADD_REMITO = gql`
  mutation addTkRemito($obj: tk_remitosInput!) {
    addTkRemito(remito: $obj) {
      _id
      createdAt
      costo_venta
      estado
      sinSaldar
      detalle {
        cantidad
        description
        price_cost
        subTotalCost
      }    
    }
  }
`;

export const ADD_REP_RECIBO = gql`
  mutation addRepRecibo($repRecibo: tk_repRecibosInput!) {
    addRepRecibo(repRecibo: $repRecibo)
  }
`;

export const CANCEL_REMITO = gql`
  mutation CancelPaymentRemito($idRemito: String!) {
    cancelPaymentRemito(id_remito: $idRemito)
  }
`;

export const CANCEL_RECIBO = gql`
  mutation AnularTkRecibo($idRecibo: String!) {
    anularTkRecibo(id_recibo: $idRecibo) {
      _id
    }
  }
`;
