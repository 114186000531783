import React, { useState, useCallback, useEffect } from "react";
import {
  GET_RECIBOS_BY_RACK,
  GET_SALDO_RACK,
  CHANGE_RECIBOS,
  CHANGE_SALDOS,
} from "../../query/takeaway/racks.tk.query";
import { useSelector } from "react-redux";
import { useApolloClient } from "@apollo/react-hooks";
import moment from "moment";
import Swal from "sweetalert2";
import { CANCEL_RECIBO } from "../../query/takeaway/articles.tk.query";

function TkVentas({ envaStk }) {
  const tkRack = useSelector((state) => state.tkRack);
  const tkUser = useSelector((state) => state.tkUser);

  const client = useApolloClient();

  const [recibos, setRecibos] = useState("");

  //const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [saldo, setSaldo] = useState({});
  const [saldoFtResta, setSaldoFtResta] = useState(0);
  const [envaStock, setEnvaStock] = useState(envaStk);

  /*   useEffect(() => {
    if (tkRack.id) fetchData(tkRack.id);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tkRack]);
 */

  useEffect(() => {
    let sus = null;
    let susSaldos = null;
    if (recibos.length <= 0)
      fetchData().then((recs) => {
        sus = suscri(recs);
        susSaldos = suscriSaldos();
      });

    return (sus, susSaldos) => {
      if (sus) sus.unsubscribe();
      if (susSaldos) susSaldos.unsubscribe();
    };
  }, []);

  //useEffect(() => {}, [recibos]);

  const suscri = (recibs) => {
    return client
      .subscribe({
        query: CHANGE_RECIBOS,
        variables: { idRack: tkRack.id },
        fetchPolicy: "no-cache",
      })
      .subscribe((result) => {
        const reci = result.data.changeRecibos;

        const rid = recibs
          .map((r) => {
            return r.id;
          })
          .indexOf(reci._id);
        if (rid < 0) {
          //agregamos
          console.log("agregamos");
          const r = [
            {
              id: reci._id,
              fecha: reci.createdAt,
              monto: reci.monto_venta,
              metodo: reci.metodo,
              responsable: reci.id_user,
              estado: reci.estado,
              detalle: reci.detalle,
              sinAfectar: reci.sinAfectar,
            },
            ...recibs,
          ];
          setRecibos(r);
          recibs = r;
        } //cambiar
        else {
          console.log("cambiamos");
          console.log("reci", reci);
          let r = [];
          if (reci.estado !== "ELIMINADO") {
            r = recibs.map((re) => {
              if (re.id === reci._id) {
                return {
                  id: reci._id,
                  fecha: reci.createdAt,
                  monto: reci.monto_venta,
                  metodo: reci.metodo,
                  responsable: reci.id_user,
                  estado: reci.estado,
                  detalle: reci.detalle,
                  sinAfectar: reci.sinAfectar,
                };
              } else return re;
            });
          } else {
            r = recibs.filter((r) => r.id !== reci._id);
          }
          recibs = r;
          setRecibos(r);
        }
      });
  };

  const suscriSaldos = () => {
    return client
      .subscribe({
        query: CHANGE_SALDOS,
        variables: { idRack: tkRack.id },
        fetchPolicy: "no-cache",
      })
      .subscribe((result) => {
        console.log("result", result);
        setSaldo(result.data.changeSaldos);
      });
  };

  const fetchData = useCallback(async () => {
    // other code
    try {
      //setIsLoading(true);
      const resultRecibos = await client.query({
        query: GET_RECIBOS_BY_RACK,
        variables: { rackId: tkRack.id },
        fetchPolicy: "no-cache",
      });
      const reciDB = resultRecibos.data.getRecibosByRack;
      if (reciDB) {
        const reciArray = reciDB.map((r) => ({
          id: r._id,
          fecha: r.createdAt,
          monto: r.monto_venta,
          metodo: r.metodo,
          responsable: r.id_user,
          estado: r.estado,
          detalle: r.detalle,
          sinAfectar: r.sinAfectar,
        }));
        setRecibos(reciArray);
        //setIsLoading(false);

        const resultSaldo = await client.query({
          query: GET_SALDO_RACK,
          variables: { rackId: tkRack.id },
          fetchPolicy: "no-cache",
        });
        if (resultSaldo.data.getSaldosOfRack)
          setSaldo(resultSaldo.data.getSaldosOfRack);
        return reciArray;
      }
    } catch (err) {
      setError("¡ Rack no encontrado !");
      console.log(error, err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mostrarDetalle = (recibo) => {
    const fechaRecibo = moment(recibo.fecha).format("DD-MM-YYYY HH:mm");
    const head = `<div class='comprobante ${
      recibo.metodo.toLowerCase() === "efectivo" ? "efectivo" : "mercadoPago"
    }'>`;
    let output = `<br><h4 class="reciboTitle ${recibo.estado.toLowerCase()}">Recibo ${
      recibo.estado
    }</h4><h5>${fechaRecibo} hs</h5><hr>`;
    let outputAux = "";
    const sticker = `<div
        class='comprobanteSticker ${
          recibo.metodo.toLowerCase() === "efectivo"
            ? "efectivo"
            : "mercadoPago"
        }'>
        ${recibo.metodo}
        </div>`;
    recibo.detalle.map((x) => {
      if (x.cantidad && x.price) {
        const lineaDetalle =
          `<b>${x.cantidad}</b> ${x.description} [$ ${x.price}]` +
          `<p class="subTotal">$ ${x.subTotalPrice}</p>`;
        return (output += `<p class="filaSwal">${lineaDetalle}</p>`);
      } else {
        if (x.cantidad && !x.price) {
          const lineaDetalle = `más <b>${x.cantidad} Envase${
            x.cantidad > 1 ? "s" : ""
          }</b>`;
          return (outputAux = `<p>${lineaDetalle}</p>`);
        } else return null;
      }
    });
    const stickerSaldoDisp =
      recibo.metodo.toLowerCase() === "mercado pago" &&
      recibo.estado.toLowerCase() === "pagado" &&
      recibo.sinAfectar > 0
        ? `<div class="stickerSaldoDisp"><p class="text-center font-weight-bold">Disponible </br> $ ${recibo.sinAfectar}</p></div>`
        : "";
    output += `<hr>
              <div class="d-flex justify-content-end font-weight-bold ">
                 
                 <div class="col-6 p-0"> <h5 class="text-right mr-1">Total: $ ${recibo.monto}</h5><h6 class="text-right mr-1">${outputAux}</h6></div>
              </div>
              </div>`;
    Swal.fire({
      icon: `${
        recibo.estado.toLowerCase() === "pagado"
          ? "success"
          : recibo.estado.toLowerCase() === "pendiente"
          ? "warning"
          : "error"
      }`,
      html: sticker + head + stickerSaldoDisp + output,
      padding: "0 0 25px 0",
      showDenyButton:
        tkUser.tipo === "Playero" &&
        recibo.metodo.toLowerCase() !== "mercado pago" &&
        recibo.estado.toLowerCase() !== "anulado",
      denyButtonColor: "#d33",
      denyButtonText: "ANULAR RECIBO",
      allowEscapeKey: false,
    }).then(async (result) => {
      if (result.isDenied) {
        const envases =
          recibo.detalle.find((r) => r.code === "010008")?.modifierStock || 0;
        const tieneEnvases = envases
          ? `y <b>${envases} envase${envases > 1 ? "s" : ""}</b> devuelto${
              envases > 1 ? "s" : ""
            } </br> (de ${envaStock} envase${envases > 1 ? "s" : ""} en stock)`
          : "";
        const msjOk = `<span>El recibo será anulado; el stock restituido ${tieneEnvases} ...</span>`;
        const msjMal = `<span><h5>Cantidad insuficiente de envases!!!</h5> 
         </br> Envases en Stock: ${envaStock} </br> Envases en devolución: ${envases}</span>`;
        Swal.fire({
          html: envaStock >= envases ? msjOk : msjMal,
          icon: envaStock < envases ? "error" : "",
          showConfirmButton: envaStock >= envases,
          showCancelButton: true,
          confirmButtonText: "ACEPTAR",
          confirmButtonColor: "#d33",
          cancelButtonText: "CANCELAR",
        }).then(async (res) => {
          if (res.isConfirmed) {
            const data = await client.query({
              query: CANCEL_RECIBO,
              variables: { idRecibo: recibo.id },
              fetchPolicy: "no-cache",
            });
            const aux = recibos.map((r) => {
              if (r.id === recibo.id) {
                recibo.estado = "ANULADO";
                return recibo;
              } else return r;
            });
            setSaldoFtResta(recibo.monto);
            setRecibos(aux);
            setEnvaStock(envaStock - envases);
            localStorage.setItem("recargarArts", true);
          }
        });
      }
    });
  };

  return !recibos ? (
    <div className="container bg-white p-5 my-5">
      <div className="row">
        <div className="col d-flex justify-content-center">
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Buscando recibos...</span>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div id="tkVentas" className="listados">
      <div className="divScrolleable m-1 text-center">
        <span className="text-success">
          <span className="m-2">
            Saldo: <b>$ {saldo.saldo_ef}</b>
          </span>
          <span className="m-2">
            {" "}
            Mercado Pago: <b>$ {saldo.saldo_mp}</b>
          </span>
        </span>
        <table className="recibosTable table table-striped table-sm">
          <thead className="recibosTitle">
            <tr className="text-center">
              <th>FECHA</th>
              <th>METODO</th>
              <th>MONTO</th>
            </tr>
          </thead>
          <tbody>
            {recibos.map((recibo) => (
              <tr
                key={recibo.id}
                onClick={() => mostrarDetalle(recibo)}
                className={`filaRecibo ${recibo.estado.toLowerCase()} ${
                  recibo.metodo.toLowerCase() === "mercado pago" &&
                  recibo.sinAfectar === 0
                    ? "afectado"
                    : recibo.metodo.toLowerCase() !== "mercado pago" &&
                      recibo.estado.toLowerCase() === "anulado"
                    ? "anulado"
                    : ""
                }`}
              >
                <td className="px-3">
                  {moment(recibo.fecha).format("DD/MM/YYYY HH:mm")}
                </td>
                <td className="px-3 text-center">{recibo.metodo}</td>
                <td className="px-3 text-right">$ {recibo.monto}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TkVentas;
