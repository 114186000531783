import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/takeaway/header.component";
//import { TK_USER_DELETE } from "../../redux/duck/tk.user.duck ";
import tkRack, {
  TK_RACK_CREATE,
  TK_RACK_DELETE,
} from "../../redux/duck/tk.rack.duck ";
import { USER_CREATE } from "../../redux/duck/user-duck";
import { SHOPPING_REMOVE_ALL } from "../../redux/duck/shopping.duck";
import { TK_SHOPPING_REMOVE_ALL } from "../../redux/duck/tk.shopping.duck";
import { TK_USER_DELETE } from "../../redux/duck/tk.user.duck ";
import ProfileSwitcher from "../../components/takeaway/tk.ProfileSwitcher.component";
// apollo
import { useApolloClient } from "@apollo/react-hooks";
//import tk_rack from "../../components/takeaway/tk_rack.json"
//query
import {
  GET_RACK_BY_ID,
  CLEAR_RECIBO,
  GET_RECIBO_BY_ID,
  GET_REMITO_BY_ID,
  CHANGE_STOCK,
} from "../../query/takeaway/racks.tk.query";

//icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import TkVentas from "../../components/takeaway/tk.Ventas.component";
import TkRemitos from "../../components/takeaway/tk.Remitos.component";
import Swal from "sweetalert2";
//import { allowedNodeEnvironmentFlags } from "process";
//import { calendarFormat } from "moment";
import moment from "moment";

export const TakeawayIndex = () => {
  const dispatch = useDispatch();
  const tkUser = useSelector((state) => state.tkUser);
  const tkRack = useSelector((state) => state.tkRack);
  const client = useApolloClient();
  const params = useParams();
  const takeID = params.id;
  const history = useHistory();
  const params1 = new URLSearchParams(window.location.search);
  const externalRef = params1.get("external_reference");
  const status = params1.get("status");
  const [error, setError] = useState("");
  const [articulos, setArticulos] = useState([]);
  const [userType, setUserType] = useState("");
  const [ventas, setVentas] = useState(false);
  const [remitos, setRemitos] = useState(false);
  const [mensaje, setMensaje] = useState("Cargando Rack...");
  const [artsEnvases, setArtsEnvases] = useState(0);





  useEffect(() => {
    //a index puede llegarse escaneando QR o desde orders
    const ufo = localStorage.getItem("userFromOrder");
    if ((ufo && ufo === takeID) || externalRef) {
      console.log("Vuelve de Orders o MP");
      if (localStorage.getItem("remitoPendienteMP")) {
        localStorage.setItem("cargado", true);
        localStorage.removeItem("remitoPendienteMP");
      }
      //viene de orders o tk.remitos.component por MP
      localStorage.setItem("userFromOrder", "");
      if (externalRef) {
        if (localStorage.getItem("reciboPendienteMP")) {
          //RECIBO
          //viene de pago RECIBO mercado pago?
          fetchDataMP(externalRef).then((rec) => {
            if (status === "approved") {
              mostrarDetalleRecibo(rec);
              dispatch({
                type: TK_SHOPPING_REMOVE_ALL,
                shopping: null,
              });
            } else {
              const res = borrarRecibo(externalRef).then(rec);
              if (!res) Swal.fire("Error!", "CLEAR_RECIBO", "warning");
              history.push(`/takeaway/${takeID}`);
            }
            localStorage.removeItem("reciboPendienteMP");
          });
        } else if (localStorage.getItem("remitoPendienteMP")) {
          //REMITO
          //viene de pago REMITO mercado pago?
          fetchDataRemitoMP(externalRef).then((rem) => {
            localStorage.removeItem("remitoPendienteMP");
            localStorage.setItem("mostrarRemitos", true);
            setRemitos(true);

            if (status === "approved") {
              mostrarDetalleRemito(rem);
            } else {
              history.push(`/takeaway/${takeID}`);
            }
            localStorage.removeItem("remitoPendienteMP");
          });
        }
      } else {
        const recID = localStorage.getItem("reciboPendienteMP");
        const remID = localStorage.getItem("remitoPendienteMP");
        if (recID) {
          //excepcion: Es la unica vez que re-escaneando QR ingresaría aca; y es porque
          //re-escaneó el QR estando en MP listo para pagar
          borrarRecibo(recID);
          localStorage.removeItem("reciboPendienteMP");
        }
        if (remID) {
          alert();
          localStorage.removeItem("remitoPendienteMP");
        }
      }
    } else {
      //viene de escaneo QR
      //siempre que se entra a index, se vacía vacía user y shopping, por si estuviera algun usuario del portal logueado
      console.log("escaneo QR");
      localStorage.removeItem("token");
      localStorage.removeItem("client_code");
      dispatch({
        type: USER_CREATE,
        user: null,
      });
      dispatch({
        type: SHOPPING_REMOVE_ALL,
        shopping: null,
      });
      dispatch({
        type: TK_USER_DELETE,
        tkUser: null,
      });
      dispatch({
        type: TK_SHOPPING_REMOVE_ALL,
        shopping: null,
      });
    }

    let sus = null;
    if (articulos.length <= 0) fetchData().then(() => (sus = suscri()));
    return () => {sus.unsubscribe();}

    //localStorage.setItem("cargado", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('tkUser', tkUser)
    setUserType(tkUser.tipo);
    setVentas(false);
    if (localStorage.getItem("mostrarRemitos")) {
      setRemitos(true);
      localStorage.removeItem("mostrarRemitos");
    } else setRemitos(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tkUser]);






  const fetchDataMP = useCallback(async (recId) => {
    try {
      //setIsLoading(true);
      const resultRecibo = await client.query({
        query: GET_RECIBO_BY_ID,
        variables: { getReciboByIdId: recId },
        fetchPolicy: "no-cache",
      });
      const reciDB = resultRecibo.data.getReciboById;
      if (reciDB) {
        return reciDB;
        //setIsLoading(false);
      }
    } catch (err) {
      setError("¡ Rack no encontrado !");
      console.log(error, err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataRemitoMP = useCallback(async (remID) => {
    try {
      //setIsLoading(true);
      const resultRemito = await client.query({
        query: GET_REMITO_BY_ID,
        variables: { idRemito: remID },
        fetchPolicy: "no-cache",
      });
      const remiDB = resultRemito.data.getRemitoById;
      if (remiDB) {
        return remiDB;
        //setIsLoading(false);
      }
    } catch (err) {
      setError("¡ Rack no encontrado !");
      console.log(error, err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const suscri = () => {
    return client
      .subscribe({
        query: CHANGE_STOCK,
        variables: { idRack: takeID },
        fetchPolicy: "no-cache",
      })
      .subscribe((result) => {
        const rack = result.data.changeStock;
        console.log("rack", rack);

        if (rack.activo) {
          const rackArts = rack.articulos.map((r) => ({
            id: r._id,
            code: r.Article.code,
            lista_id: r.Article.lista_id,
            description: r.Article.description,
            price: r.Article.price,
            price_cost: r.Article.price_cost,
            image: r.Article.image,
            stock: r.stock,
            stock_min: r.stock_min,
          }));
          //localStorage.setItem("cache", "si");
          setArticulos(rackArts);
          setMensaje("");
          console.log("rackArts", rackArts);
        } else setMensaje("¡ Rack inactivo !");
      });
  };
  //obtengo datos del rack
  const fetchData = useCallback(async () => {
    try {
      const { data } = await client.query({
        query: GET_RACK_BY_ID,
        variables: { id: takeID },
        fetchPolicy: "network-only",
        partialRefetch: true,
      });
      const rack = data.getRackById;
      if (rack) {
        if (rack.activo) {
          const rackArts = rack.articulos.map((r) => ({
            id: r._id,
            code: r.Article.code,
            lista_id: r.Article.lista_id,
            description: r.Article.description,
            price: r.Article.price,
            price_cost: r.Article.price_cost,
            image: r.Article.image,
            stock: r.stock,
            stock_min: r.stock_min,
          }));
          //localStorage.setItem("cache", "si");
          setArticulos(rackArts);

          //si se venden retornables y no hay envases , se genera un error
          const artBidones =
            rackArts.find((x) => x.code === "010001" || x.code === "010002") ||
            0;
          const artEnvase = rackArts.find((x) => x.code === "010008") || 0;
          setArtsEnvases(artEnvase.stock);
          if (artBidones && !artEnvase) setError("¡Omisión de Retornables!");

          dispatch({
            type: TK_RACK_DELETE,
            tkRack: null,
          });
          dispatch({
            type: TK_RACK_CREATE,
            tkRack: {
              id: rack._id,
              negocio: rack.negocio_name,
              direccion: rack.direccion,
              responsables: rack?.users.map((x) => x.nombre + " " + x.apellido),
            },
          });
          setMensaje("");
        } else setMensaje("¡ Rack inactivo !");
      } else setMensaje("¡ Rack no encontrado !");
    } catch (err) {
      setError("¡ Rack no encontrado !");
      console.log(error, err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const borrarRecibo = useCallback(async (rec) => {
    try {
      const result = await client.query({
        query: CLEAR_RECIBO,
        variables: { idRecibo: rec },
        fetchPolicy: "no-cache",
      });
      const id = result.data.clearReciboByID;
      if (id) {
        return true;
      }
    } catch (err) {
      setError("¡ Recibo no encontrado !");
      console.log(error, err);
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mostrarDetalleRecibo = (recibo) => {
    const fechaRecibo = moment(recibo.fecha).format("DD-MM-YYYY HH:mm");
    const head = `<div class='comprobante ${
      recibo.metodo.toLowerCase() === "efectivo" ? "efectivo" : "mercadoPago"
    }'>`;
    let output = `<br><h4 class="reciboTitle Pagado">Recibo PAGADO</h4><h5>${fechaRecibo} hs</h5><hr>`;

    let outputAux = "";
    let mensajeRetiro = "";
    const responsables = tkRack.responsables;
    const sticker = `<div
        class='comprobanteSticker ${
          recibo.metodo.toLowerCase() === "efectivo"
            ? "efectivo"
            : "mercadoPago"
        }'>
        ${recibo.metodo}
        </div>`;
    recibo.detalle.map((x) => {
      if (x.cantidad && x.price) {
        //tiene que haber  cant y precio para que sea mostrado el prod en el det
        const lineaDetalle =
          `<b>${x.cantidad}</b>  ${x.description} [$ ${x.price}]` +
          `<p class="subTotal">$ ${x.subTotalPrice}</p>`;
        return (output += `<p class="filaSwal">${lineaDetalle}</p>`);
      } else {
        if (x.cantidad && !x.price) {
          //si hay cant y no hay precio es porque es envase
          const lineaDetalle = `<br>más <span class="text-danger"><b>${
            x.cantidad
          } Envase${x.cantidad > 1 ? "s" : ""}</b></span>`;
          return (outputAux = `${lineaDetalle}<br>`);
        } else return null;
      }
    });
    /*     https://ee03-190-122-147-42.ngrok.io/takeaway/6398bdc79c058b30ccd1e17e/paysuccess/rec/?collection_id=1311646106&collection_status=approved&payment_id=1311646106&status=approved&external_reference=63eb0561095163a39c9d5813&payment_type=account_money&merchant_order_id=7733260478&preference_id=233042952-0ee8ce81-58d2-4bf5-b0f0-6615c035efb5&site_id=MLA&processing_mode=aggregator&merchant_account_id=null
     */
    if (recibo.estado.toLowerCase() === "pagado")
      mensajeRetiro = `Mostrá el ticket a <b>${responsables}</b> ${outputAux} para obtener la mercadería`;
    output += `<hr><h5 class="text-right mr-1">Total: $ ${recibo.monto_venta}</h5><br/><h6 class="text-right mr-1">${mensajeRetiro}</h6></div>`;
    if (1) {
      Swal.fire({
        icon: `${
          recibo.estado.toLowerCase() === "pagado"
            ? "success"
            : recibo.estado.toLowerCase() === "pendiente"
            ? "warning"
            : "error"
        }`,
        html: sticker + head + output,
        padding: "0",
      });
      history.push(`/takeaway/${takeID}`);
      //restar del stock recien ahora
    }
  };

  const mostrarDetalleRemito = (remito) => {
    let estadoEstilo = "";
    let estado = "";
    //let saldoMPMsj = `<p class="text-right mr-1 text-success">Saldo a favor (MP): $ ${saldoMP}</p>`;
    let saldo = "";
    /* `<h5 class="text-right mr-1">Total a pagar: $ ${calcTotPagar(
      remito.costo_venta
    )}`; */
    let msj = "";
    switch (remito.estado) {
      case "PAGADO":
        estadoEstilo = "pagado";
        estado = "PAGADO";
        //aca se podria mostrar el componente de MP del total pagado
        break;
      case "PENDIENTE":
        estadoEstilo = "pendiente";
        estado = "PENDIENTE";
        saldo = "";
        break;
      case "CANCELADO":
        estadoEstilo = "cancelado";
        estado = "CANCELADO";
        saldo = "";
        break;
      default:
        break;
    }
    const fechaRemito = moment(remito.createdAt).format("DD-MM-YYYY HH:mm");
    const head = `<div class='comprobante ${estadoEstilo}'>`;
    let output = "<h4>Remito</h4><h5>" + fechaRemito + " hs</h5><hr>";
    const sticker = `<div
        class='comprobanteSticker ${estadoEstilo}'>
        ${estado}
        </div>`;
    remito.detalle.map((x) => {
      if (x.cantidad) {
        const line =
          `<b>${x.cantidad}</b> ${x.description} [$ ${x.price_cost}]` +
          `<p class="subTotal">$ ${x.subTotalCost}</p>`;
        return (output += `<p class="filaSwal">${line}</p>`);
      }
    });
    if (remito.estado === "PAGADO") {
      output += `<hr><p class="text-right mr-1">Total: $ ${remito.costo_venta}</p><h5 class="text-right mr-1">Total pagado MP: $ ${remito.pagadoMp}</h5></div>`;
      msj = `</br><p>Mostrá este comprobante al repositor para que te baje la mercadería!</p>`;
    } else {
      //a pagar con MP
      output += `<hr><p class="text-right mr-1">Total a pagar: $ ${remito.sinSaldar}</p>${saldo}</h5></div>`;
      if (tkUser.tipo === "Playero") {
        msj = `</br><p>Pagá y mostrá este comprobante al repositor para que te baje la mercadería!</p>`;
      }
    }
    Swal.fire({
      html: sticker + head + output,
      padding: "25px 0 0 0",
    });
  };

  const ReciboShoppingSwitch = () => {
    setVentas(!ventas);
    setRemitos(!remitos ? remitos : !remitos);
  };

  const RemitosShoppingSwitch = () => {
    setRemitos(!remitos);
    setVentas(!ventas ? ventas : !ventas);
  };

  return articulos.length > 0 ? (
    articulos.length > 0 ? (
      <div id="takeawayPage">
        {/* <div>hola{userType}</div> */}
        <Header takeID={takeID} />

        {ventas && (
          <div className="body d-flex    my-auto     justify-content-center">
            <TkVentas envaStk={artsEnvases} />
          </div>
        )}
        {remitos && ( 
          <div className="body d-flex    my-auto     justify-content-center">
            <TkRemitos /> {console.log("tkRemitos")}
          </div>
        )}
        {!ventas && !remitos && (
          <div className="body d-flex justify-content-center my-auto ">
            <ProfileSwitcher arts={articulos} />
          </div>
        )}

        {/*  BOTONERA PLAYERO  */}
        {userType === "Playero" && (
          <div className="m-2">
            <div
              id="botoneraPlayero"
              className="botoneraPlayero d-flex justify-content-between "
            >
              <div className="mx-1">
                <button
                  className={`btn ${
                    !ventas ? "btn-secondary " : "btn-success"
                  } justify-content-center align-items-center 
                  `}
                  type="button"
                  onClick={() => ReciboShoppingSwitch()}
                >
                  Ventas
                </button>
              </div>
              <div className="mx-1">
                <button
                  className={`btn ${
                    !remitos ? "btn-secondary " : "btn-success"
                  } justify-content-center align-items-center 
                  `}
                  onClick={() => RemitosShoppingSwitch()}
                  disabled={localStorage.getItem("remitoPendienteMP")}
                >
                  Remitos <FontAwesomeIcon icon={faTruck} color={""} />
                </button>
              </div>
            </div>
          </div>
        )}

        {/*  BOTONERA REPARTIDOR  */}
        {userType === "Repartidor" && (
          <div className="m-2">
            <div
              id="botoneraPlayero"
              className="botoneraPlayero d-flex justify-content-between "
            >
              <div className="mx-1">
                <button
                  className={`btn ${
                    !ventas ? "btn-secondary " : "btn-dark"
                  } justify-content-center align-items-center 
                  `}
                  type="button"
                  onClick={() => ReciboShoppingSwitch()}
                >
                  Ventas
                </button>
              </div>
              <div className="mx-1">
                <button
                  className={`btn ${
                    !remitos ? "btn-secondary " : "btn-dark"
                  } justify-content-center align-items-center 
                  `}
                  onClick={() => RemitosShoppingSwitch()}
                >
                  Remitos <FontAwesomeIcon icon={faTruck} color={""} />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    ) : (
      //no hay articulos
      <>
        <h3 className="text-center mt-2 pt-5">{"Cargando artículos"}</h3>
        <div className="col d-flex justify-content-center">
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Buscando artículos...</span>
          </div>
        </div>
      </>
    )
  ) : (
    //cargando rack o hay error
    <>
      {mensaje === "Cargando Rack..." && (
        <div className="container bg-white p-5 my-5">
          <div className="row">
            <div className="col d-flex justify-content-center">
              <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Cargando...</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {mensaje !== "Cargando Rack..." && (
        <>
          <h1 className="text-center mt-5 pt-5">
            {mensaje !== "Cargando Rack..." ? ":(" : ""}
          </h1>
          <h3 className="text-center mt-2 pt-5">{mensaje}</h3>
        </>
      )}
    </>
  );
};
