import React from 'react';

import '../scss/slider.scss';

const SliderComponent = () => {
    return <div className="row d-flex justify-content-center">
        <div className="col col-md-9 p-3">
            <div id="carrousel" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="d-block img-fluid" src="https://picsum.photos/500/500" alt="First slide" />
                </div>
                <div className="carousel-item">
                    <img className="d-block img-fluid" src="https://picsum.photos/500/500" alt="Second slide" />
                </div>
                <div className="carousel-item">
                    <img className="d-block img-fluid" src="https://picsum.photos/500/500" alt="Third slide" />
                </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
                </a>
            </div>
        </div> 
    </div>;
};

export default SliderComponent;
