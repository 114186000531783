import React, {useCallback, useEffect, useRef, useState} from 'react';

// apollo
import {useApolloClient} from '@apollo/react-hooks';

// query
import {
    ADD_NEW_TICKET,
    CREATE_CLAIM,
    GET_CLAIM_TIPE,
    GET_HISTORIAL_CLAIM,
    GET_RECLAMO_CCTE, NOTIFICATE_GROUP
} from '../query/claim/claim.query';

import {TIPO_RECLAMOS} from '../constants/constants';

// swal
import Swal from 'sweetalert2';
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {GET_CLIENTID} from "../query/login/login.query";

const ClaimPage = () => {


    const [instancia, setInstancia] = useState("Reclamos");
    const [historial, setHistorial] = useState([]);
    const [reclamo, setReclamo] = useState("");
    const [facturacion, setFacturacion] = useState("");
    const [clientID, setClientID] = useState(null);

    const [btnSendDisabled, setBtnSendDisabled] = useState(false);

    const token = localStorage.getItem('token')
    const claimRef = useRef(null);
    const subject = useRef(null);
    const typeReclamo = TIPO_RECLAMOS;


    // apollo client
    const client = useApolloClient();


    const fetchData = useCallback(async () => {
        try{
            const r = await client.query({
            query: GET_CLAIM_TIPE
        });
            setReclamo(r.data.findPedidoCliente?r.data.findPedidoCliente :[]);

            const rcc = await client.query({
                query: GET_RECLAMO_CCTE
            });
            setFacturacion(rcc.data.findReclamoCuentaCorriente);

            const rcl = await client.query({
                query: GET_CLIENTID,
                variables: {
                    token: token
                }
            });
            setClientID(rcl.data.getClientIdByToken);


        }catch (e) {
            console.log(e);
        }

    }, []);


    const gethistorial = async () => {
        const code = localStorage.getItem('client_code');
        const h = await client.query({
            query: GET_HISTORIAL_CLAIM,
            variables: {
                client: code
            }
        });

        let arr = [];
        h.data.getTicketOnlyPedidos.map(ticket => {
            ticket.notificaciones.map(nt => {
                arr.push({
                    code: ticket.code,
                    ...nt
                });
            })
        });
        console.log(arr);


        // console.log(h.data.getTicketOnlyPedidos);
        setHistorial(arr);
    }

    useEffect(() => {
        fetchData();


    }, [])


    const cancel = () => {
        claimRef.current.value = '';
        subject.current.value = '00';
    };

    const notificaiconFacturacion = async (data) => {

        const dataNotification = {
            client_origin: data.client, //el usuario logeado que genera el aviso
            group_id: data._id,
            accountingComment: data.accountingComment,
            subtitle: `${data.code_ticket} - Nueva gestion en cliente: ${data.name}`,
            text: data.text,
            link: `/contabilidad/list/${data.code_client}`,
        };

        console.log("notif::", dataNotification);

        const result = await client.query({
            query: NOTIFICATE_GROUP,
            variables: dataNotification,
            fetchPolicy: 'no-cache',
        });

        const not = result.data.notificateGroup;
    }

    const send = async (e) => {
        setBtnSendDisabled(true);
        const client_name = localStorage.getItem('client_name');

        if ((subject.current.value === '00' || claimRef.current.value === '')) {
            Swal.fire({
                title: 'Reclamo!', text: 'Seleccione un tipo de contacto y agregue una descripción', icon: 'warning',
            });
            return;
        }
        const claim = claimRef.current.value;
        const code = localStorage.getItem('client_code');
        const type = typeReclamo.find((r) => r.id === subject.current.value);

        const variables = {code, claim, type: type.valor};

        console.log("type:::", type);
        console.log("variables:::", variables)


        //CREATE VISITA PENDING
        let ticket = null;
        let textArr = reclamo.name.split('-');
        let name = (textArr.length > 0) ? textArr[1].trim().toUpperCase() : "PEDIDOS DE CLIENTE";

        if (type.visitable) {
            ticket = {
                _id: null,
                name: name,
                client: code,
                notificacion: {
                    token: token,
                    client_code: code,
                    text: `${type.nombre}: ${claim}`,
                    type: reclamo._id,
                    notifLogis: {
                        fecha_venc: moment().add(1, 'days').utc(),
                        asunto: (type.valor=="FALLA_EQUIPO")? "REPARACION": "PEDIDO",
                        clasificacion: null,
                        prioridad: "MEDIA",
                        estado: "PENDIENTE"
                    }
                }
            }
        } else {
            let type_id = null;
            if(type.valor == "FACTURACION"){
                type_id = facturacion?facturacion._id:null
                name = facturacion?facturacion.name.split('-').pop().trim().toUpperCase():name
            }
            ticket = {
                _id: null,
                name: name,
                client: code,
                client_name: client_name ? client_name : "",
                notificacion: {
                    token: token,
                    client_code: code,
                    text: `${type.nombre}: ${claim}`,
                    type: type_id?type_id:reclamo._id,
                    notifLogis: null
                }
            }
        }

        try {
            //console.log("ticket::", ticket);


            const r = await client.query({
                 query: ADD_NEW_TICKET,
                 variables: {
                     ticket: ticket
                 },
                 fetchPolicy: "no-cache"
             });
            console.log("newTK::", r);

             if (r.data.addTiket) {
                 if(type.valor == "FACTURACION"){
                     //se envia una notificacion a los encargados de facturacion
                     const noti =
                         {
                             _id: ticket.notificacion.type, //group_id
                             client: clientID, //id (mongo) del cliente logeado
                             code_client: code, //codigo del cliente logeado
                             code_ticket: r.data.addTiket.code, // code of ticket
                             accountingComment: r.data.addTiket.notificaciones[0]._id,
                             name: r.data.addTiket.name, //nombre del ticket
                             text: ticket.notificacion.text // texto del msj
                         };

                     console.log(noti)

                      await notificaiconFacturacion(noti);
                 }

                 await Swal.fire({
                     title: 'Reclamo!',
                     text: 'Tu reclamo fue cargado y será resuelto dentro de las 48 horas hábiles. Muchas gracias!',
                     icon: 'success',
                 }).then(() => {
                     setBtnSendDisabled(false)
                 });
             } else {
                 await Swal.fire({
                     title: 'Reclamo!',
                     text: 'Error al procesar su reclamo, contacte al administrador',
                     icon: 'error',
                 }).then(() => {
                     setBtnSendDisabled(false)
                 });
           }

            /* const queryClaim = await client.query({
                 query: CREATE_CLAIM,
                 variables: variables,
                 fetchPolicy: 'no-cache',
             });
             const result = queryClaim ? queryClaim.data.createClaim : null;*/



        } catch (e) {
            console.log(e.message)
        }
        //FIN CREATE VISITA PENDING

    };

    const colums = [
        {
            dataField: '_id',
            text: 'id',
            hidden: true
        },
        {
            dataField: '_id',
            text: 'Asunto',
            formatter: (row, data) => {
                if(data.notifLogis){
                    return data.notifLogis.asunto
                }else{
                    return data.ntType.name.split('-').pop().trim()
                }
            }
        },
        {
            dataField: 'createdAt',
            text: "Iniciado",
            formatter: (row, data) => {
                return moment(data.createdAt).format('DD/MM/YYYY');
            }
        },
        {
            dataField: 'resolver_date',
            text: "Terminado",
            formatter: (row, data) => {
                if (data.resolver_date) {
                    return moment(data.resolver_date).format('DD/MM/YYYY');
                } else {
                    return "";
                }
            }
        },
        {
            dataField: 'text',
            text: "Detalle"
        },
        {
            dataField: 'resolved',
            text: "Estado",
            formatter: (row, data) => {
                return (data.resolved) ? <span>Terminado</span> : <span>En Revision</span>;
            }
        }
    ];

    const rowClasses = (row, rowIndex) => {
        let classes = null;
        if (row.resolved) {
            classes = 'text-muted';
        } else {
            classes = 'text-primary';
        }
        return classes;
    };

    return (<div className='container bg-white my-5 p-3'>

        <div className="btn-group mb-3" role="group" aria-label="Basic example">
            <button type="button" className={`btn btn-lg btn-outline-secondary ${instancia == "Reclamos" ? "active" : ""}`}
                    onClick={() => {
                        setInstancia("Reclamos");
                    }}> Pedidos, Reclamos, Sugerencias
            </button>
            <button type="button" className={`btn btn-lg btn-outline-secondary ${instancia == "Historial" ? "active" : ""}`}
                    onClick={async () => {
                        setInstancia("Historial");
                        await gethistorial();
                    }}> Ver mis Pedidos y Reclamos
            </button>
        </div>

        {instancia === "Reclamos" &&
            <div className='row'>
                <div className='col-8 mt-3'>
                    <strong>Tipo de reclamo:</strong>
                    {typeReclamo && (<select className='form-control' ref={subject}>
                        <option value={'00'}>
                            Seleccione una opción
                        </option>
                        {
                            typeReclamo.map((rec) => {
                                return (<option key={rec.id} value={rec.id}>{rec.nombre} </option>);
                            })
                        }
                    </select>)}
                </div>
                <div className='col-8 my-3'>
                    <strong>Descripción:</strong>
                    <textarea rows={6} ref={claimRef} className='form-control'/>
                </div>
                <div className='col-8 my-3'>
                    <button className='btn btn-primary' disabled={btnSendDisabled} onClick={(e) => {
                        send(e)
                    }}>
                        {btnSendDisabled? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "" }
                        {btnSendDisabled?<span> Enviando</span>: <span> Enviar</span>}
                    </button>
                    <button className='btn btn-secondary ml-3' onClick={cancel}>
                        Cancelar
                    </button>
                </div>
            </div>
        }

        {
            instancia === "Historial" &&
            <div className='row'>
                <div className="col-12">
                    <div className='row'>
                        <div className="col-12">
                            <BootstrapTable
                                keyField='_id'
                                columns={colums}
                                data={historial}
                                rowClasses={rowClasses}
                                pagination={paginationFactory({
                                    sizePerPage: 5,
                                    hideSizePerPage: true
                                })}
                            />
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>);
};

export default ClaimPage;
