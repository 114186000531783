import gql from 'graphql-tag';

export const CREATE_STATISTIC = gql`
  mutation createStatic($type: String!, $client_id: String!) {
    createStatic(data: { type: $type, client_id: $client_id }) {
      _id
      active
      type
      client_id
      created_at
      updated_at
    }
  }
`;
