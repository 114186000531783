import React from 'react';

import SliderComponent from '../components/slider.component';

const PaymentBankPage = () => {
    return <div className="row">
       <div className="col">
            <div className="row mt-3">
                <div className="col">
                    <h3 className="text-center">Informar trasferencias bancarias</h3>
                </div>
            </div>
            <div>   
                <SliderComponent />
            </div>
            <div className="row mt-3">
                <div className="col d-flex justify-content-center">
                    <button type="button" className="btn btn-lg btn-secondary">Subir Foto</button>
                </div>
            </div>
       </div>
    </div>;
};

export default PaymentBankPage;