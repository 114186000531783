import React from 'react';

// apollo
// import { useApolloClient } from '@apollo/react-hooks';

import PaymentMercadoPago from './payment-mercadopago.page';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUniversity } from '@fortawesome/free-solid-svg-icons';

const PagosManagementPage = () => {
  return (
    <div className='row m-3 '>
      <div className='col'>
        <div className='row'>
          <div className='col'>
            <h3 className='text-center'>Pagos</h3>
          </div>
        </div>
        <div className='row mt-5 justify-content-center'>
          {/*
            <div className='col'>
            <a href='/pagos/banco' className='btn btn-primary btn-lg btn-block'>
              <FontAwesomeIcon icon={faUniversity} />
              <span className='ml-3'>Banco</span>
            </a>
          </div>
            */}

          <div className='col-10 col-md-4'>
            <PaymentMercadoPago />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagosManagementPage;
