import { gql } from 'apollo-boost';

export const FIND_CONSUMIPTIONS_BY_CLIENT = gql`
  query findClientConsumptionsInfo($user_code: String!) {
    findClientConsumptionsInfo(user_code: $user_code) {
      nombre
      dias
      abonos
      diponibles
      consumidos
      porcentaje
    }
  }
`;

export const FIND_CLIENT_DATA_BY_CODE = gql`
  query findClientByClientCode($cli_code: String!) {
    findClientByClientCode(cli_code: $cli_code) {
      id
      codigo
      razonSocial
      nombreFantasia
      domicilio
      telefono
      email
      tipo
    }
  }
`;

export const CHANGE_DATA_REQUEST = gql`
  mutation requestClientDataChange(
    $cli_code: String!
    $name: String!
    $email: String!
    $phone: String!
  ) {
    requestClientDataChange(
      cli_code: $cli_code
      name: $name
      email: $email
      phone: $phone
    )
  }
`;
