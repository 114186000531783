import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// redux functions
import { store } from '../redux/duck/duck';
import { USER_CREATE } from '../redux/duck/user-duck';
import { CLIENT_CREATE } from '../redux/duck/client-duck';

// history
import { useHistory } from 'react-router-dom';

// forms
import { useForm } from 'react-hook-form';

// apollo
import { useApolloClient } from '@apollo/react-hooks';

// query
import { LOGIN, GET_USER_CODE } from '../query/login/login.query';

// swal
import Swal from 'sweetalert2';

// React Bootstrap
import { OverlayTrigger, Popover } from 'react-bootstrap';

import '../scss/login.scss';
// import 'bootstrap/dist/css/bootstrap.min.css';

const LoginPage = () => {
  // dispatch
  const dispatch = useDispatch();

  const history = useHistory();

  // apollo client
  const client = useApolloClient();

  // forms
  const { register, handleSubmit, watch, errors, setValue } = useForm();

  const username = watch('username');
  const password = watch('password');

  useEffect(() => {
    const remember = localStorage.getItem('remember') === 'true' ? true : false;
    const userCode = localStorage.getItem('userCode');

    if (remember && userCode) {
      setValue('username', userCode);
      document.getElementById('remember-me').checked = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // login
  const login = async () => {
    const variables = {
      name: username,
      password: password,
    };

    const remember = document.getElementById('remember-me').checked;

    console.log('remember', remember);
    if (remember) {
      localStorage.setItem('remember', true);
      localStorage.setItem('userCode', username);
    }
    else {
      localStorage.setItem('remember', false);
    }

    try {
      const {data} = await client.query({
        query: LOGIN,
        variables: variables,
        fetchPolicy: 'no-cache',
      });

      const login = data ? data.login : null;

      if (login) {
        const queryCode = await client.query({
          query: GET_USER_CODE,
          variables: {
            token: login,
          },
          fetchPolicy: 'no-cache',
        });
        const code = queryCode ? queryCode.data.getClientCodeByToken : null;
        localStorage.setItem('token', login);

        console.log(store.getState());

        if (code) {
          localStorage.setItem('client_code', code);
          dispatch({
            type: CLIENT_CREATE,
            client: code,
          });
        }

        dispatch({
          type: USER_CREATE,
          user: login,
        });
        history.push('/home');
      }
      else {
        await Swal.fire({
          title: 'Login!',
          text: 'Error al iniciar sesión',
          icon: 'error',
        });
      }
    } catch (error) {
      if(error.graphQLErrors[0].message.includes('baja')){
        await Swal.fire({
          title: 'Login!!',
          text: 'Tu cuenta fue dada de baja, para volver a darte de alta y realizar pedidos contactate con nosotros al 0810 444 7272 o vía whatsapp al 5493435031261',
          icon: 'warning',
        });
      }
      else {
        await Swal.fire({
          title: 'Login!!',
          text: 'Error al iniciar sesión, por favor contacte al administrador',
          icon: 'error',
        });
      }
    }
  };

  /*const login = async () => {
    Swal.fire({
          title: 'Web',
          text: 'EN Mantenimiento',
          icon: 'error',
        });
  };*/



  const popoverUser = (
    <Popover className='an-popover'>
      <Popover.Title as='h3'>Usuario</Popover.Title>
      <Popover.Content>Ingrese su número de cliente</Popover.Content>
    </Popover>
  );

  const popoverPassword = (
    <Popover className='an-popover'>
      <Popover.Title as='h3'>Contraseña</Popover.Title>
      <Popover.Content>
        Ingrese su número de cliente o la contraseña que usted eligió si la ha
        cambiado
      </Popover.Content>
    </Popover>
  );

  return (
    <div id='login'>
      <img
        src={require('../assets/images/marca_inicio.png')}
        alt={'logo-inicio'}
        className='logo-inicio'
      />
      <div className='container'>
        <div
          id='login-row'
          className='row justify-content-center align-items-center'
        >
          <div id='login-column' className='col-md-6'>
            <div id='login-box' className='col-md-12'>
              <div class='mt-1 alert alert-info' role='alert'>
                Vea como obtener su numero de cliente y usar nuestro portal{' '}
                <a
                  href='https://www.youtube.com/watch?v=JYxshWxxmEo&t=3s'
                  target='_blank'
                  rel="noopener noreferrer"
                >
                  <strong>aqui</strong>
                </a>
                !
              </div>
              <form
                id='login-form'
                className='form'
                action=''
                method='post'
                onSubmit={handleSubmit(login)}
              >
                <h3 className='text-center text-info'>Inicio de Sesión</h3>
                <div className='form-group'>
                  <label htmlFor='username' className='text-info'>
                    Usuario:
                  </label>
                  <br />
                  <OverlayTrigger
                    trigger={['focus', 'hover']}
                    placement='bottom'
                    overlay={popoverUser}
                  >
                    <input
                      type='text'
                      name='username'
                      id='username'
                      placeholder='Número de cliente'
                      ref={register({ required: true })}
                      className='form-control'
                    />
                  </OverlayTrigger>

                  {errors.username && (
                    <small className='text-danger'>
                      El código de usuario es requerído
                    </small>
                  )}
                </div>
                <div className='form-group'>
                  <label htmlFor='password' className='text-info'>
                    Contraseña:
                  </label>
                  <br />
                  <OverlayTrigger
                    trigger={['focus', 'hover']}
                    placement='bottom'
                    overlay={popoverPassword}
                  >
                    <input
                      type='password'
                      name='password'
                      id='password'
                      placeholder='Número de cliente'
                      ref={register({ required: true })}
                      className='form-control'
                    />
                  </OverlayTrigger>

                  {errors.password && (
                    <small className='text-danger'>
                      La contraseña es requerída
                    </small>
                  )}
                </div>
                <div className='form-group'>
                  <label htmlFor='remember-me' className='text-info'>
                    <span>Recuérdame</span> 
                    <span>
                      <input
                        id='remember-me'
                        name='remember-me'
                        type='checkbox'
                      />
                    </span>
                  </label>
                  <br />
                  <input
                    type='submit'
                    name='submit'
                    className='btn btn-info btn-md an-info'
                    value='Enviar'
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
