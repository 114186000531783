import React from 'react';

import '../scss/payment-action.scss';

const PaymentActionPage = () => {
  return (
    <div className='container'>
      <div className='row'>
        <a href='/pagos' className='col d-flex justify-content-center'>
          <button
            id='btn-payment'
            type='button'
            className='btn btn-info  btn-lg'
          >
            Pagos
          </button>
        </a>
      </div>
    </div>
  );
};

export default PaymentActionPage;
