import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { NavLink } from 'react-router-dom';

// redux functions
import { USER_CREATE } from '../redux/duck/user-duck';
import { SHOPPING_REMOVE_ALL } from '../redux/duck/shopping.duck';

// history
import { useHistory, withRouter } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';

// moment
import moment from 'moment';

// apollo
import { useApolloClient } from '@apollo/react-hooks';

// query
import { FIND_CONSUMIPTIONS_BY_CLIENT } from '../query/clients/client.query';
import { CHANGE_PASSWORD } from '../query/users/user.query';

// swal
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// component
import ChangePassword from '../components/change-password.component';

import '../scss/header.scss';

const HeaderPrivate = () => {
  // dispatch
  const code = useSelector((state) => state.client);
  const shopping = useSelector((state) => state.shopping);

  // apollo client
  const client = useApolloClient();

  // state
  const [consumos, setConsumos] = useState(null);
  const [positionY, setPositionY] = useState(null);

  // dispatch
  const dispatch = useDispatch();

  const history = useHistory();

  const fetchData = useCallback(async () => {
    try {
      console.log('user code', code.client);
      const current_date = moment().format('YYYY-MM-DD');
      console.log('current_date', current_date);
      const resultInfo = await client.query({
        query: FIND_CONSUMIPTIONS_BY_CLIENT,
        variables: {
          user_code: code.client,
        },
        fetchPolicy: 'cache',
      });

      const aux = resultInfo.data.findClientConsumptionsInfo;
      console.log('consumo', aux);
      localStorage.setItem('client_name', aux[0]?.nombre )
      setConsumos(aux);
    } catch (error) {
      console.log(error);
    }

    window.addEventListener('scroll', (event) => {
      setPositionY(window.scrollY);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [code, fetchData]);

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('client_code');
    localStorage.removeItem('client_name');

    dispatch({
      type: USER_CREATE,
      user: null,
    });

    dispatch({
      type: SHOPPING_REMOVE_ALL,
      shopping: null,
    });

    history.push('/');
  };

  const changePassword = () => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      title: <p>Cambiar Contraseña</p>,
      showCancelButton: false,
      showConfirmButton: false,
      html: (
        <ChangePassword object={MySwal} changePassword={changePasswordCall} />
      ),
    }).then(async (response) => {
      console.log('response', response);
    });
  };

  const changePasswordCall = async (oldPassword, password) => {
    const token = localStorage.getItem('token');

    const variables = {
      token: token,
      old_password: oldPassword,
      new_password: password,
    };
    console.log('variables', variables);

    try {
      const resultLogs = await client.query({
        query: CHANGE_PASSWORD,
        variables: variables,
        fetchPolicy: 'no-cache',
      });

      if (resultLogs.data.changeUserPassword) {
        Swal.fire(
          'Cambio de Contraseña',
          'La contraseña se cambio exitosamente, por favor vuelva a loguearse',
          'success'
        );
        setTimeout(() => {
          logout();
        }, 2000);
      } else {
        console.log('canceled');
        Swal.fire(
          'Cambio de Contraseña',
          'La contraseña no se cambio',
          'warning'
        );
      }
    } catch (error) {
      Swal.fire(
        'Cambio de Contraseña',
        'La contraseña no se cambio',
        'warning'
      );
    }
  };

  const goToHome = () => {
    history.push('/home');
  };

  return (
    <div className='with-border-down-blue'>
      {positionY <= 100 && (
        <div className='container  p-3'>
          <div className='row'>
            <div className='col-md-4 order-2 order-md-1'>
              <div className='row'>
                <div className='col-12'>
                  <h5 className='text-center text-md-left text-dark-info'>
                    {consumos &&
                      consumos[0]?.nombre ?
                      `HOLA, ${consumos[0]?.nombre}`
                      : <div className='spinner-grow text-primary' role='status'>
                          <span className='sr-only'>Loading...</span>
                        </div>
                    }
                  </h5>
                </div>
                <div className='col-12'>
                  <div className='text-center text-md-left text-dark-info'>
                    Le damos la bienvenida
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 p-3 order-1 order-md-2'>
              <a href={'/home'}>
                <img
                  src={require('../assets/images/marca_cabezal.png')}
                  alt={'logo-azul'}
                  className='logo-azul'
                />
              </a>
            </div>

            <div className='col-md-4 d-flex justify-content-center justify-content-md-end align-items-center p-3  order-3'>
              {/*
                <div className='dropdown'>
                <img
                  src={require('../assets/images/boton_perfil.png')}
                  alt={'btn-perfil'}
                  className='btn-perfil'
                  id='dropdownMenuButton'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                />
                <div
                  className='dropdown-menu'
                  aria-labelledby='dropdownMenuButton'
                >
                  <span className='dropdown-item' onClick={goToHome}>
                    Inicio
                  </span>
                  <span className='dropdown-item' onClick={changePassword}>
                    Cambiar Constraseña
                  </span>
                  <span className='dropdown-item' onClick={logout}>
                    Cerrar Sesion
                  </span>
                </div>
              </div>
             */}
            </div>
          </div>
        </div>
      )}
      <nav
        id='main-menu'
        className='mt-4 mt-md-0 navbar navbar-expand-lg navbar-light'
      >
        <div className='container'>
          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbarTogglerDemo01'
            aria-controls='navbarTogglerDemo01'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarTogglerDemo01'>
            <ul className='navbar-nav mr-auto mt-2 mt-lg-0'>
              <li
                className='nav-item'
                data-toggle='collapse'
                data-target='.navbar-collapse'
              >
                <NavLink exact className='nav-link' to='home'>
                  <h4>Home</h4>
                </NavLink>
              </li>
              <li
                className='nav-item'
                data-toggle='collapse'
                data-target='.navbar-collapse'
              >
                <NavLink className='nav-link' exact to='productos'>
                  <h4>Productos</h4>
                </NavLink>
              </li>
              <li
                className='nav-item'
                data-toggle='collapse'
                data-target='.navbar-collapse'
              >
                <NavLink className='nav-link' exact to='pedidos'>
                  <div className='row'>
                    <div className='col pr-1'>
                      <h4>Carrito</h4>
                    </div>
                    <div className='col pl-0  pr-1'>
                      {shopping.length > 0 && (
                        <span className='badge badge-primary'>
                          {shopping.length}
                        </span>
                      )}
                      {shopping.length === 0 && ``}
                    </div>
                  </div>
                </NavLink>
              </li>
              <li
                className='nav-item'
                data-toggle='collapse'
                data-target='.navbar-collapse'
              >
                <NavLink className='nav-link' exact to='reclamos'>
                  <h4>Contáctenos</h4>
                </NavLink>
              </li>
              <li
                className='nav-item'
                data-toggle='collapse'
                data-target='.navbar-collapse'
              >
                <NavLink className='nav-link' exact to='perfil'>
                  <h4>Perfil</h4>
                </NavLink>
              </li>
            </ul>
            <form className='form-inline my-2 my-lg-0'>
              <button
                className='btn btn-outline-info my-2 my-sm-0'
                type='button'
                onClick={logout}
              >
                Cerrar sesión
              </button>
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default withRouter(HeaderPrivate);
