import React from 'react';

import { NavLink } from 'react-router-dom';

// react-reveal
import Fade from 'react-reveal/Fade';

// pages
import ConsumptionsPage from './consumptions.page';
import ReceiptPage from './receipt.page';
import PaymentActionsPage from './payment-actions.page';
import ProfileActionPage from './profile-action.page';
import BannerPedido from '../components/banner-pedido.component';
import BannerSugerenciaReclamo from '../components/banner-sugerencia-reclamo.component';
import BannerPagosComponent from '../components/banner-pagos.component';

const HomePage = () => {
  return (
    <>
      <div className='container'>
        <div
          className='mt-3 alert alert-warning alert-dismissible fade show'
          role='alert'
        >
          <strong>Servicio atención al Cliente!</strong> Por favor verifique sus
          datos para recibir su factura por email y una mejor atención.
          <NavLink exact to='perfil' className='ml-1 text-muted'>
            <strong>Acceda haciendo click aquí</strong>
          </NavLink>
          <button
            type='button'
            className='close'
            data-dismiss='alert'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <h2 className='m-4 text-center text-md-left text-dark-info'>
          Mi Cuenta
        </h2>
      </div>
      <section id='banner'>
        <Fade>
          <BannerPagosComponent />
        </Fade>
        <Fade>
          <BannerPedido />
        </Fade>
        <Fade>
          <BannerSugerenciaReclamo />
        </Fade>
      </section>
      <section id='consumptions'>
        <Fade>
          <ConsumptionsPage />
        </Fade>
      </section>
      <section id='receipt'>
        <Fade>
          <ReceiptPage />
        </Fade>
      </section>
      <section id='payment-action'>
        <PaymentActionsPage />
      </section>
      <section id='admin'>
        <Fade>
          <ProfileActionPage />
        </Fade>
      </section>
    </>
  );
};

export default HomePage;
