// clients.js

export const CLIENT_CREATE = "CLIENT_CREATE";
export const CLIENT_DELETE = "CLIENT_DELETE";

export default function client(state = {}, action) {
  //console.log("client", state, action);
  switch (action.type) {
    case CLIENT_CREATE:
      return Object.assign({}, state, {
        client: action.client,
      });
    case CLIENT_DELETE:
      return Object.assign({}, state, {
        client: null,
      });
    default:
      return state;
  }
}
