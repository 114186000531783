import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// redux functions
import { SHOPPING_ADD } from '../redux/duck/shopping.duck';
import { SHOPPING_REMOVE } from '../redux/duck/shopping.duck';

// apollo
import { useApolloClient } from '@apollo/react-hooks';

// Lightbox
import { SRLWrapper } from 'simple-react-lightbox';

// icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

// query
import { GET_ALL_ARTICLES_BY_USER } from '../query/articles/articles.query';

// hook form
import { useForm } from 'react-hook-form';

import '../scss/shopping.scss';

const ShoppingPage = () => {
  // dispatch
  const dispatch = useDispatch();
  const shopping = useSelector((state) => state.shopping);
  const history = useHistory();

  const client = useApolloClient();

  const [articulos, setArticulos] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [tags, setTags] = useState([]);
  const [activeTag, setActiveTag] = useState('TODO');
  const [ariculosInputReferences] = React.useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, watch, reset } = useForm();

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      // invoice
      const code = localStorage.getItem('client_code');
      const resultArticles = await client.query({
        query: GET_ALL_ARTICLES_BY_USER,
        variables: { code: code },
        fetchPolicy: 'cache-first',
      });

      const data = resultArticles.data.listAllArticles.sort((a, b) => {
        if (a.description_alt > b.description_alt) {
          return 1;
        }
        if (a.description_alt < b.description_alt) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });;
      console.log('data', data);

      const duplicates = data.map(ele => ele.clasificacion);
      const uniques = Array.from(new Set(duplicates));

      setArticulos(data);
      setFiltered(data);
      setIsLoading(false);
      setTags(uniques);
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const agregar = (index, code) => {
    const cant = parseInt(ariculosInputReferences[index].value) + 1;
    ariculosInputReferences[index].value = cant;

    const article = articulos.find((ele) => ele.code === code);
    dispatch({
      type: SHOPPING_ADD,
      shopping: {
        code: code,
        cantidad: cant,
        lista: article.lista_id,
        price: article.price.toFixed(2),
        description: article.description,
      },
    });
  };

  const minus = (index, code) => {
    console.log('minus');
    const cant = parseInt(ariculosInputReferences[index].value) - 1;
    if(cant >= 0) {
      ariculosInputReferences[index].value = cant;

      const article = articulos.find((ele) => ele.code === code);
      dispatch({
        type: SHOPPING_REMOVE,
        shopping: {
          code: code,
          lista: article.lista_id,
          cantidad: cant,
          price: article.price.toFixed(2),
          description: article.description,
        },
      });
    }
  };

  const onChange = (index, code) => {
    const cant = parseInt(ariculosInputReferences[index].value);
    console.log('onChange', cant);
    if (cant >= 0) {
      ariculosInputReferences[index].value = cant;

      const article = articulos.find((ele) => ele.code === code);
      dispatch({
        type: SHOPPING_ADD,
        shopping: {
          code: code,
          lista: article.lista_id,
          cantidad: cant,
          price: article.price.toFixed(2),
          description: article.description,
        },
      });
    }
  };

  const setRef = (index, ref) => {
    ariculosInputReferences[index] = ref;
  };

  const validate = (event) => {
    const theEvent = event || window.event;
    let key;

    // Handle paste
    if (theEvent.type === 'paste') {
        key = event.clipboardData.getData('text/plain');
    } else {
    // Handle key press
        key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
    }
    const regex = /[0-9]|\./;
    if( !regex.test(key) ) {
      theEvent.returnValue = false;
      if(theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  const redirectOrders = () => {
    history.push('/pedidos');
  };

  const onSubmit = () => {
    const filter = watch('filter');
    console.log('filter', filter);

    if(filter.length > 3 ) {
      const filtered = articulos.filter((art) => art.description.includes(filter.toUpperCase()));

      setFiltered(filtered);
    } else {
      setFiltered(articulos);
    }
  };

  const borrarFiltro = () => {
    setFiltered(articulos);
    setActiveTag('TODO');
    reset();
  }

  const filterTags = (tag) => {
    const filtered = articulos.filter((art) => art.clasificacion.includes(tag));
     setFiltered(filtered);
     setActiveTag(tag);
  }

  return isLoading || !articulos ? (
    <div className='container bg-white p-5 my-5'>
      <div className='row'>
        <div className='col d-flex justify-content-center'>
          <div className='spinner-grow text-primary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <form
      className='container my-5 p-3 bg-white'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <h3 className='d-block text-muted'>Listado de productos</h3>
      </div>
      <div className='row'>
        <div className='col-12 col-md-6'>
          <input
            type='text'
            className='form-control'
            placeholder='Filtrar por nombre'
            name='filter'
            ref={register({ required: false })}
          />
        </div>
        <div className='col-12 col-md-6 pt-2 pt-md-0'>
          <input type='submit' value='Filtrar' className='btn btn-primary' />
          <input
            type='button'
            value='Borrar Filtro'
            className='ml-3 btn btn-secondary'
            onClick={borrarFiltro}
          />
        </div>
      </div>
      <div className='mt-3'>
        {tags.map((ele, index) => (
          <small
            key={`tag-${index}`}
            className={`${index === 0 ? '' : 'ml-2'} badge ${
              activeTag === ele ? 'badge-secondary' : 'badge-primary'
            }`}
            onClick={() => filterTags(ele)}
            style={{ cursor: 'pointer' }}
          >
            {ele}
          </small>
        ))}
        <span
          className={`ml-2 badge ${
            activeTag === 'TODO' ? 'badge-secondary' : 'badge-primary'
          }`}
          onClick={borrarFiltro}
          style={{ cursor: 'pointer' }}
        >
          TODO
        </span>
      </div>
      <div className='shopping justify-content-center'>
        {filtered.length > 0 &&
          filtered.map((article, index) => {
            console.log('image', typeof article.image);
            return (
              <div
                className='card my-3 mx-1 shopping-card pt-3'
                key={article.code}
              >
                <SRLWrapper>
                  {article.image && (
                    <img
                      className='card-img-top'
                      src={`data:image/png;base64,${article.image}`}
                      alt={article.description}
                    />
                  )}
                </SRLWrapper>
                <div className='card-body'>
                  <h5 className='card-title text-center'>
                    Código: {article.code}
                  </h5>
                  <p className='card-text text-center'>{article.description}</p>
                  <div className='text-primary'>
                    <h3 className='text-center'>{`$ ${article.price.toFixed(
                      2
                    )}`}</h3>
                  </div>
                  <div className='my-3'>
                    <div className='d-flex justify-content-center'>
                      <div>
                        <button
                          className='btn btn-secondary'
                          onClick={() => minus(index, article.code)}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </button>
                      </div>
                      <div className='w-25 mx-1'>
                        <input
                          className='form-control'
                          type='number'
                          step='1'
                          min='0'
                          defaultValue={
                            shopping.find((ele) => ele.code === article.code)
                              ?.cantidad | 0
                          }
                          onKeyPress={(event) => validate(event)}
                          onChange={() => onChange(index, article.code)}
                          ref={(ref) => setRef(index, ref)}
                        />
                      </div>
                      <div>
                        <button
                          className='btn btn-secondary'
                          onClick={() => agregar(index, article.code)}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        <div>
          <button
            type='button'
            className='shopping-fixed btn btn-secondary'
            onClick={redirectOrders}
            data-toggle='tooltip'
            data-placement='top'
            title='Tooltip on top'
          >
            <FontAwesomeIcon icon={faShoppingCart} />
            {shopping.length > 0 && (
              <span className='ml-1 badge badge-primary'>
                {shopping.length}
              </span>
            )}
            {shopping.length === 0 && ``}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ShoppingPage;
