import React, {useEffect, useState} from 'react';
import {useApolloClient} from "@apollo/react-hooks";
import {GET_CPR_DATA_PRINTING, GET_CPR_PIE, GET_REMITOS_RELACIONADOS} from "../query/receipt/receipt.query";
import {zfillString} from "../helpers/formatter/formatter";
import Barcode from 'react-barcode';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPrint, faTimes} from '@fortawesome/free-solid-svg-icons';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import '../scss/page-print.scss';
import moment from "moment";

function ComprobantePage(props) {
    const id_comprobante = parseFloat(props.match.params.id);
    const client = useApolloClient();
    const [status, setStatus] = useState(102);
    const [address, setAddress] = useState(null);
    const [clientCode, setClientCode] = useState(null);
    const [code, setCode] = useState(null);
    const [date, setDate] = useState(null);
    const [fullName, setFullName] = useState(null);
    const [letter, setLetter] = useState(null);
    const [locality, setLocality] = useState(null);
    const [num, setNum] = useState(null);
    const [items, setItems] = useState([]);
    const [pies, setPies] = useState(null);
    const [totalPie, setTotalPie] = useState(0);
    const [cuit, setCuit] = useState(null);
    const [condicion, setCondicion] = useState(null);
    const [tipoPago, setTipoPago] = useState(null);
    const [remitos, setRemitos] = useState(null);
    const [cae, setCae] = useState(null);
    const [caeVenc, setCaeVenc] = useState(null);

    useEffect(() => {
        document.title = "Agua Nuestra: Comprobante " + id_comprobante;
        const getData = async () => {
            try {
                const dataPrint = await client.query({
                    query: GET_CPR_DATA_PRINTING,
                    variables: {
                        id: id_comprobante,
                    },
                    fetchPolicy: 'no-cache',
                });
                const relateds = await client.query({
                    query: GET_REMITOS_RELACIONADOS,
                    variables: {id: id_comprobante.toString()},
                    fetchPolicy: 'no-cache',
                });
                const pie = await client.query({
                    query: GET_CPR_PIE,
                    variables: {
                        id: id_comprobante,
                    },
                    fetchPolicy: 'no-cache',
                });

                //console.log(dataPrint.data.getCPRDataPrinting);

                if(dataPrint.data.getCPRDataPrinting.length === 0){
                    throw {
                        err: true,
                        status: 404,
                        statusText: "Datos no encontrado"
                    }
                }

                let data = dataPrint.data.getCPRDataPrinting;
                const itemsData = [];
                let letter_aux = "";
                for(let i = 0; i < data.length; i++){
                    let row = data[i];
                    if(i === 0){
                        setAddress(row.address);
                        setClientCode(row.clientCode);
                        const aux = new Date(parseInt(row.date));
                        console.log('data aux', row.date);
                        setDate(moment(aux).format('DD-MM-YYYY'));
                        setFullName(row.fullName);
                        letter_aux = row.letter.trim();
                        setLetter(row.letter.trim());
                        setLocality(row.locality);
                        setNum(row.num);
                        setCode(row.code);
                        setCuit(row.cuit);
                        setCondicion(row.condicion);
                        setTipoPago(row.tipo_pago);
                        setCae(row.cae);
                        setCaeVenc(row.cae_vencimiento);
                    }
                    itemsData.push({
                        id: row.codeArt,
                        cant: row.cant,
                        code: row.codeArt,
                        descrition: row.descrition,
                        saldo: row.saldo,
                        tipo: row.tipo.trim(),
                        saldo_total: row.saldo_total,
                        saldo_neto: row.saldo_neto,
                    });
                }

                const dataPie = pie.data.getCPRPir;
                console.log('dataPie', dataPie);
                console.log("letter: ", letter_aux);

                if(letter_aux && (letter_aux === 'B' || letter_aux === 'C')){
                    const aux = dataPie.filter((ele) => !ele.descripcion.includes('IVA'));
                    setPies(aux);

                    let tot = 0;
                    for (const a of aux) {
                        tot += parseFloat(a.total);
                    }
                    setTotalPie(tot);
                }
                else if(letter_aux && letter_aux === 'A'){
                    setPies(dataPie);

                    let tot = 0;
                    for (const a of dataPie) {
                        tot += parseFloat(a.total_neto);
                    }
                    setTotalPie(tot);
                }

                const remitosIds = relateds.data.getRemitosRelacionados;
                //console.log("remitos get: ", remitosIds);

                setItems(itemsData);
                setRemitos(remitosIds);

                setStatus(200);
            } catch (e){
                console.log(e);
                setStatus(400)
            }
        }
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const printDocument = () => {
        const input = document.getElementById('div-to-print');
        html2canvas(input)
            .then((canvas) => {
                let imgWidth = 208;
                let imgHeight = canvas.height * imgWidth / canvas.width;
                const imgData = canvas.toDataURL('img/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                // pdf.output('dataurlnewwindow');
                pdf.save(`Factura-${id_comprobante}.pdf`);
            })
        ;
    }

    //const dataPie = pie.data.getCPRPir;
    if (status === 200) {
        return (
            <div className='container' id='div-to-print'>
            <div className='row page receipt'>
                <div className='col'>
                    <section className='row receipt-header'>
                        <div className='col'>
                            <div className='row d-flex justify-content-center'>
                                <div className='col-12 col-md-10 mt-3'>
                                    <div className='row p-3 p-md-0'>
                                        <div className='col-12'>
                                            <h4 className='text-muted'>
                                                Valentin, Celia Torra 5633, E3100 Paraná, Entre Ríos
                                            </h4>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <small>Tel: 0810-444-7272 </small> |
                                            <small>Whatsapp: +54 9 3435 03-1261 </small> |
                                            <small>Web: https://www.aguanuestra.com.ar/ </small> |
                                            <small>Condición: Resp. Inscripto </small>
                                        </div>
                                        <div className='col-12'>
                                            <small>C.U.I.T: 30-70786951-4 </small> |
                                            <small>CONV. MULT. Nº 908-354561-1 </small> |
                                            <small>Fecha inic. activ.: 01/2002 </small>
                                        </div>
                                    </div>
                                    <div className='row p-3 justify-content-center'>
                                        <div className='col-1  border'>
                                            <h1 className='mb-0 text-center align-middle'>
                                                {letter && letter.toUpperCase()}
                                            </h1>
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className='col-6'>
                                            <h5 className='text-muted'>
                                                {num &&
                                                    letter === 'R' &&
                                                    `Nº de Remito: ${code}-${zfillString(
                                                        String(num),
                                                        8
                                                    )}-${letter}`}
                                                {num &&
                                                    (letter === 'A' || letter === 'B') &&
                                                    `Nº de Factura: ${code}-${zfillString(
                                                        String(num),
                                                        8
                                                    )}-${letter}`}
                                            </h5>
                                        </div>
                                        <div className='col-6'>
                                            <h5 className='text-muted'>
                                                {clientCode && `Cliente: ${clientCode} - ${fullName}`}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-6'>
                                            <h5 className='text-muted'>
                                                {address && `Direccion: ${address}, ${locality}`}
                                            </h5>
                                        </div>
                                        {cuit && (
                                            <div className='col-6'>
                                                <h5 className='text-muted'>
                                                    {cuit && `CUIT: ${cuit}`}
                                                </h5>
                                            </div>
                                        )}
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-6'>
                                            <h5 className='text-muted'>
                                                {address && `Condicion: ${condicion}`}
                                            </h5>
                                        </div>
                                        <div className='col-6'>
                                            <h5 className='text-muted'>
                                                {address && `Tipo pago: ${tipoPago}`}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-12'>
                                            <h5 className='text-muted'>{date && `Fecha: ${date}`}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='receipt-body p-3'>
                        <div className='row'>
                            <div className='col'>
                                <table className='table'>
                                    <thead className='thead-light'>
                                    <tr>
                                        <th scope='col'>Código</th>
                                        <th scope='col'>Descripción</th>
                                        <th scope='col'>Cantidad</th>
                                        {items.length > 0 && items[0].tipo === 'FA' && (
                                            <th scope='col' className='text-right'>
                                                Precio Unitario
                                            </th>
                                        )}
                                        {items.length > 0 && items[0].tipo === 'FA' && (
                                            <th scope='col' className='text-right'>
                                                Monto
                                            </th>
                                        )}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {items.map((ele) => {
                                        return (
                                            <tr key={ele.id}>
                                                <th scope='row'>{ele.code}</th>
                                                <td>{ele.descrition}</td>
                                                <td className='text-right'>
                                                    {ele.cant && ele.cant.toFixed(2)}
                                                </td>
                                                {items.length > 0 && items[0].tipo === 'FA' && (
                                                    <td className='text-right'>{`$ ${
                                                        letter === 'A'
                                                            ? (ele.saldo_neto / ele.cant).toFixed(2)
                                                            : ele.saldo.toFixed(2)
                                                    }`}</td>
                                                )}
                                                {items.length > 0 && items[0].tipo === 'FA' && (
                                                    <td className='text-right'>{`$ ${
                                                        letter === 'A'
                                                            ? ele.saldo_neto.toFixed(2)
                                                            : (ele.saldo * ele.cant).toFixed(2)
                                                    }`}</td>
                                                )}
                                            </tr>
                                        );
                                    })}
                                    {pies &&
                                        pies.map((pie, index) => {
                                            return (
                                                <tr key={`detalle-${index}`}>
                                                    <td colSpan='4' className='text-right'>
                                                        <strong>{pie.descripcion}</strong>
                                                    </td>
                                                    {(letter === 'B' || letter === 'C') && (
                                                        <td className='text-right'>{`$ ${pie.total}`}</td>
                                                    )}
                                                    {letter === 'A' && (
                                                        <td className='text-right'>{`$ ${pie.total_neto}`}</td>
                                                    )}
                                                </tr>
                                            );
                                        })}
                                    {pies && (
                                        <tr>
                                            <td colSpan='4' className='text-right'>
                                                <strong>Total</strong>
                                            </td>
                                            <td className='text-right'>{`$ ${totalPie.toFixed(
                                                2
                                            )}`}</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <section className='remitos-section no-print'>
                            <span>Remitos relacionados: </span>
                            <div className='row remitos-container'>
                                {remitos &&
                                    remitos.map((remito) => {
                                        return (
                                            <div className='col-auto' key={`${remito.id}-${remito.numero}`}>
                                                <a
                                                    href={`../comprobantes/${remito.id}`}
                                                    target='_blank'
                                                    rel="noopener noreferrer"
                                                >
                                                    {`${remito.punto_venta}-${remito.numero}-${remito.letra}`}
                                                </a>
                                                <span>|</span>
                                            </div>
                                        );
                                    })}
                            </div>
                        </section>
                    </section>
                    {cae && (
                        <section>
                            <div className='row d-flex align-items-center mb-3'>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col'>
                                            <Barcode value={cae} style={{
                                                width: "3px"
                                            }} />
                                        </div>
                                    </div>
                                    <div className='row text-center'>
                                        <div className='col'>
                                            <span>{`CAE: ${cae}`}</span>
                                        </div>
                                        <div className='col'>
                                            <span>{`Vencimiento CAE: ${caeVenc} `}</span>
                                        </div>
                                    </div>

                                    <p></p>
                                </div>
                            </div>
                        </section>
                    )}

                    <section className='receipt-footer border p-1'>
                        <div className='row'>
                            <div className='col  d-flex justify-content-center mt-3'>
                                <div className='row'>
                                    <div className='col'>
                                        <div className='row'>
                                            <div className='col'>
                                                <img
                                                    className='fluid-img'
                                                    src={require('../assets/images/ISO9001.png')}
                                                    alt='Card cap'
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <small className='text-left mt-0'>AR-O237022</small>
                                                <small className='text-left mt-0'>
                                                    Elaboración y envasado de agua de mesa en envases
                                                    retornables de 12 y 20 litros
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <img
                                            className='fluid-img logo'
                                            src={require('../assets/images/marca png 1.png')}
                                            alt='Card cap'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div>
                <button
                    className='btn btn-success rounded-circle static-print'
                    onClick={printDocument} >
                    <FontAwesomeIcon icon={faPrint} />
                </button>
            </div>
        </div>);
    }
    else if (status === 400) {
        return (
            <div className="center-screen">
                <h1 style={{color: '#f54c4c9e'}}>
                    <FontAwesomeIcon icon={faTimes} />
                </h1>
                <span>Ocurrio un error en la carga... intentelo mas tarde o contacte al administrador</span>
            </div>
        );
    }
    else {
        return (
            <div className="center-screen">
                <div className="loading_simple"></div>
                <span>Cargando Comprobante...</span>
            </div>
        );
    }


}

export default ComprobantePage;