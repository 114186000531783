import React, { useState, useEffect, useCallback } from 'react';

// component
import PagoMecadoPagoComponent from '../components/pago-mercadopago.component';

// apollo
import { useApolloClient } from '@apollo/react-hooks';

// query
import {
  GET_PREFERENCE_ID,
  GET_SALDO,
} from '../query/mercadopago/mercadopago.query';

// fontawesome
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
 */

// swal
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const PaymentMercadoPago = () => {
  // apollo client
  const client = useApolloClient();
  const [saldo, setSaldo] = useState(null);
  const [value, setValue] = useState(0);
  const [isPayment, setIsPayment] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const saldoQuery = await client.query({
        query: GET_SALDO,
        variables: { token: token },
        fetchPolicy: 'no-cache',
      });

      const data = saldoQuery.data.findClientSaldo;

      console.log('data saldo', data);
      if (data && !isNaN(data)) {
        if (data > 0) {
          setSaldo(data);
        } else {
          setSaldo(0);
        }
      } else {
        setSaldo(0);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const payment = useCallback(async (value) => {
    console.log('payment', value);
    try {
      const cli_code = localStorage.getItem('client_code');
      const preferenceQuery = await client.query({
        query: GET_PREFERENCE_ID,
        variables: {
          amount: parseFloat(value),
          cli_code: cli_code,
        },
        fetchPolicy: 'no-cache',
      });

      const data = preferenceQuery.data.getPreference;

      console.log('data', data);
      if (data) {
        window.location.href = data;
      } else {
        console.log('data', data, preferenceQuery);
        Swal.fire({
          title: 'Pago!',
          text: 'Error al generar el link de Pago',
          icon: 'error',
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Pago!',
        text: 'Error al generar el link de Pago',
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    console.log('value changed padre', value, isPayment);
    if (isPayment) {
      payment(value);
      setIsPayment(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isPayment]);

  const pagoMercadoPago = async () => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      title: <p>Pagar Monto</p>,
      showCancelButton: false,
      showConfirmButton: false,
      html: (
        <PagoMecadoPagoComponent
          object={MySwal}
          saldo={saldo ? saldo.toFixed(2) : 0.0}
          setValue={setValue}
        />
      ),
    })
      .then((result) => {
        console.log('result', result);
        console.log('value result', value);

        //TODO: VALIDACIONES!!!

        if (result.isDenied) {
          setIsPayment(false);
        } else if (result.isConfirmed) {
          setIsPayment(true);
        } else {
          setIsPayment(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <span onClick={pagoMercadoPago}>
        <img
          src={require('../assets/images/banner__MP_960.jpg')}
          alt={'banner'}
          className='banner d-none d-xl-block'
        />
        <img
          src={require('../assets/images/banner_MP_720.jpg')}
          alt={'banner'}
          className='banner d-none d-md-block d-xl-none'
        />
        <img
          src={require('../assets/images/banner_MP_420.jpg')}
          alt={'banner'}
          className='banner d-block d-md-none'
        />
      </span>
      {/*
        <button
          href='/pagos/mercadopago'
          className='btn btn-primary btn-lg btn-block'
          onClick={pagoMercadoPago}
        >
          <FontAwesomeIcon icon={faShoppingCart} />
          <span className='ml-3'>MercadoPago</span>
        </button>
        */}
    </>
  );
};

export default PaymentMercadoPago;
