import { gql } from 'apollo-boost';

export const GET_ALL_ARTICLES_BY_USER = gql`
  query listAllArticles($code: String!) {
    listAllArticles(code: $code) {
      _id
      code
      lista_id
      description
      description_alt
      clasificacion
      price
      image
    }
  }
`;
export const CREAR_NOTA_PEDIDO = gql`
  mutation crearNotaPedido($cli_code: String!, $articulos: [ArticulosInput!]!, $obs: String!) {
    crearNotaPedido(cli_code: $cli_code, articulos: $articulos, obs: $obs)
  }
`;
