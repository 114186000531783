import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// redux functions
import { USER_CREATE } from '../redux/duck/user-duck';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// history
import { useHistory } from 'react-router-dom';

// component
import ChangePassword from '../components/change-password.component';

// query
import { CHANGE_PASSWORD } from '../query/users/user.query';

// apollo
import { useApolloClient } from '@apollo/react-hooks';

import ProfilePage from './profile.page';
import ProfileBilling from './profile-billing.page';
import ProfileShipping from './profile-shipping.page';

const ProfileManagerPage = () => {
  const [active, setActive] = useState('profile'); // profile, shipping, billing

  // dispatch
  const dispatch = useDispatch();

  // apollo client
  const client = useApolloClient();

  const history = useHistory();

  const changePassword = () => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      title: <p>Cambiar Contraseña</p>,
      showCancelButton: false,
      showConfirmButton: false,
      html: (
        <ChangePassword object={MySwal} changePassword={changePasswordCall} />
      ),
    }).then(async (response) => {
      console.log('response', response);
    });
  };

  const changePasswordCall = async (oldPassword, password) => {
    const token = localStorage.getItem('token');

    const variables = {
      token: token,
      old_password: oldPassword,
      new_password: password,
    };
    console.log('variables', variables);

    try {
      const resultLogs = await client.query({
        query: CHANGE_PASSWORD,
        variables: variables,
        fetchPolicy: 'no-cache',
      });

      if (resultLogs.data.changeUserPassword) {
        Swal.fire(
          'Cambio de Contraseña',
          'La contraseña se cambio exitosamente, por favor vuelva a loguearse',
          'success'
        );
        setTimeout(() => {
          logout();
        }, 2000);
      } else {
        console.log('canceled');
        Swal.fire(
          'Cambio de Contraseña',
          'La contraseña no se cambio',
          'warning'
        );
      }
    } catch (error) {
      Swal.fire(
        'Cambio de Contraseña',
        'La contraseña no se cambio',
        'warning'
      );
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('client_code');

    dispatch({
      type: USER_CREATE,
      user: null,
    });

    history.push('/');
  };

  return (
    <div className='row mt-3'>
      <div className='col'>
        {
          <div className='row mt-3'>
            <div className='col d-flex justify-content-center'>
              <button
                type='button'
                className='btn btn-secondary btn-lg btn-profile'
                onClick={changePassword}
              >
                <FontAwesomeIcon icon={faLock} />
                <span className='ml-3'>Cambiar Contraseña</span>
              </button>
            </div>
          </div>
        }
        <div className='row mt-3'>
          <div className='col'>
            <h3 className='text-center text-info'>
              Sus datos en nuestros registros
            </h3>
          </div>
        </div>
        <div
          className='btn-group mx-5 d-flex justify-content-center'
          role='group'
          aria-label='Basic example'
        >
          <button
            type='button'
            className={`btn btn-primary ${
              active === 'profile' ? 'active' : ''
            }`}
            onClick={() => setActive('profile')}
          >
            Datos Personales
          </button>
          <button
            type='button'
            className={`btn btn-primary ${
              active === 'billing' ? 'active' : ''
            }`}
            onClick={() => setActive('billing')}
          >
            Datos de Facturación
          </button>
          <button
            type='button'
            className={`btn btn-primary ${
              active === 'shipping' ? 'active' : ''
            }`}
            onClick={() => setActive('shipping')}
          >
            Direccion de Envío
          </button>
        </div>
        <section className='row mt-3 bg-white p-3 d-flex justify-content-center'>
          {active && active === 'profile' && <ProfilePage />}
          {active && active === 'billing' && <ProfileBilling />}
          {active && active === 'shipping' && <ProfileShipping />}
        </section>
      </div>
    </div>
  );
};

export default ProfileManagerPage;
