import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// router-dom
import { useHistory } from "react-router-dom";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faPlus,
  faMinus,
  faCashRegister,
} from "@fortawesome/free-solid-svg-icons";

// redux functions
import { TK_SHOPPING_ADD } from "../../redux/duck/tk.shopping.duck";
import { TK_SHOPPING_REMOVE } from "../../redux/duck/tk.shopping.duck";

// react-reveal
import Fade from "react-reveal/Fade";

import "../../scss/takeaway/shopping.scss";

function ShoppingTakeaway({ arts }) {
  const dispatch = useDispatch();
  const shopping = useSelector((state) => state.tkShopping);
  const tkRack = useSelector((state) => state.tkRack);
  const tkUser = useSelector((state) => state.tkUser);
  const history = useHistory();

  const [articulos, setArticulos] = useState([""]);
  //const [filtered, setFiltered] = useState([]);
  //const [tags, setTags] = useState([]);
  //const [activeTag, setActiveTag] = useState("TODO");
  const [articulosInputReferences] = React.useState([]);
  const [userType, setUserType] = useState("");
  const [rackID, setRackID] = useState("");
  const [userLoading, setUserLoading] = useState("");
  const [stockEnvaVacios, setStockEnvaVacios] = useState(0);
  const [stockBidones, setStockBidones] = useState(0);
  const [envAguaIndex, setEnvAguaIndex] = useState(-1);
  const [envVacios, setEnvVacios] = useState(0);
  //const { register, handleSubmit, watch, reset } = useForm();

  /*   const calcStockEnvases = () => {
    let stockEnvases = {};
    stockEnvases.bidones = stockBidones;
    stockEnvases.vacios = stockEnvaVacios;
    return stockEnvases;
  };

  const fetchData = () => {
    setArticulos(arts);
    console.log("envases", envases);
    //setFiltered(data);
    //setTags(uniques);
  };
 */
  useEffect(() => {
    if (arts) {
      setArticulos(arts);
      const artEnvase = arts.find((x) => x.code === "010008") || 0;
      const b20 = arts.find((x) => x.code === "010001")?.stock;
      const b12 = arts.find((x) => x.code === "010002")?.stock;
      const enva = artEnvase?.stock;
      const bidones = b12 + b20;
      setStockBidones(bidones);
      //setStockEnvaVacios(enva);
      localStorage.setItem("stockBidones", bidones);
      localStorage.setItem("stockEnvaVacios", enva);
      //storeo informacion del articulo "010008" para dps usarlo en Orders
      if (artEnvase) {
        const artEnvaAgua = {
          id: artEnvase.id,
          code: artEnvase.code,
          descripcion: artEnvase.description,
          stock: artEnvase.stock,
        };
        localStorage.setItem("artEnvaAgua", JSON.stringify(artEnvaAgua));
      }
    }
    if (tkUser.isLoading || !tkUser.isLoading) setUserLoading(tkUser.isLoading);
    if (tkUser.tipo) {
      setUserType(tkUser.tipo);
    } else setUserType("");
    if (tkRack?.id) setRackID(tkRack.id);
    calcEnvacesVacios();
  }, [arts]);

  const calcEnvacesVacios = () => {
    const artEnvase = arts.find((x) => x.code === "010008") || 0;
    const enva = artEnvase?.stock;
    setStockEnvaVacios(enva);
    const calcBidones = calcCant("010001") + calcCant("010002");
    const calcEnva = calcCant("010008");
    let res = enva;
    if (!envaseDebe())
      if (calcBidones) res = enva - calcEnva + calcBidones;
      else res = enva - calcEnva;
    setEnvVacios(res);
  };

  useEffect(() => {
    calcEnvacesVacios();
  }, [shopping]);

  const isBidones = (code, cumulo) => {
    if (code === "010001" || code === "010002") {
      const findEnvAguaIndex = (enva) => {
        if (envAguaIndex < 0) {
          const indexEnvases = articulosInputReferences.findIndex(
            (x) => x.id === enva
          );
          setEnvAguaIndex(indexEnvases);
          return indexEnvases;
        } else return envAguaIndex;
      };
      const envaCode = "010008";
      const envaIndex = findEnvAguaIndex(envaCode);
      cargaShopping(envaIndex, envaCode, cumulo);
    }
  };

  const cargaShopping = (index, code, cumulo) => {
    const cant = parseInt(articulosInputReferences[index].value) + cumulo;
    if (cant >= 0) {
      articulosInputReferences[index].value = cant;
      const article = articulos.find((ele) => ele.code === code);
      dispatch({
        type: cumulo < 0 ? TK_SHOPPING_REMOVE : TK_SHOPPING_ADD,
        shopping: {
          artId: article.id,
          code: code,
          cantidad: cant,
          lista: article.lista_id,
          price: article.price.toFixed(2),
          price_cost: article.price_cost.toFixed(2),
          description: article.description,
          stock: article.stock,
          stock_min: article.stock_min,
          image: article.image,
        },
      });
    }
  };

  const add = (index, code) => {
    cargaShopping(index, code, 1);
  };

  const minus = (index, code) => {
    cargaShopping(index, code, -1);
    if (envVacios <= 0) isBidones(code, -1);
  };

  const onChange = (index, code) => {
    const cant = parseInt(articulosInputReferences[index].value);
    if (cant >= 0) {
      articulosInputReferences[index].value = cant;
      const article = articulos.find((ele) => ele.code === code);
      dispatch({
        type: TK_SHOPPING_ADD,
        shopping: {
          artId: article.id,
          code: code,
          lista: article.lista_id,
          cantidad: cant,
          price: article.price.toFixed(2),
          price_cost: article.price_cost.toFixed(2),
          description: article.description,
          stock: article.stock,
          stock_min: article.stock_min,
          image: article.image,
        },
      });
    }
  };

  const setRef = (index, ref) => {
    articulosInputReferences[index] = ref;
  };

  const validate = (event) => {
    const theEvent = event || window.event;
    let key;

    // Handle paste
    if (theEvent.type === "paste") {
      key = event.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const redirectOrders = (id) => {
    localStorage.setItem("userFromOrder", rackID);
    history.push(`/takeaway/${id}/pedidos`);
  };

  /*   const onSubmit = () => {
    const filter = watch("filter");

    if (filter.length > 3) {
      const filtered = articulos.filter((art) =>
        art.description.includes(filter.toUpperCase())
      );

      setFiltered(filtered);
    } else {
      setFiltered(articulos);
    }
  };
 */
  /*   const borrarFiltro = () => {
    setFiltered(articulos);
    setActiveTag("TODO");
    reset();
  };
 */
  /*   const filterTags = (tag) => {
    const filtered = articulos.filter((art) => art.clasificacion.includes(tag));
    setFiltered(filtered);
    setActiveTag(tag);
  };
 */
  const totalArticles = () => {
    let ta = 0;
    for (const order of shopping) {
      ta += order.cantidad;
    }
    return ta;
  };

  const calcCant = (code) => {
    return shopping.find((ele) => ele.code === code)?.cantidad | 0;
  };

  const calcEnvVacios = () => {
    const calcBidones = calcCant("010001") + calcCant("010002");
    const calcEnva = calcCant("010008");
    let res = stockEnvaVacios;
    if (!envaseDebe())
      if (calcBidones) res = stockEnvaVacios - calcEnva + calcBidones;
      else res = stockEnvaVacios - calcEnva;
    return res;
  };

  const calcEnvBidones = () => {
    if (envaseDebe() > 0)
      return stockBidones - calcCant("010008"); //si debe, resta de los bidones
    else return stockBidones;
  };

  const envaseDebe = () => {
    //calcula cantidades desde el redux
    const code = "010008";
    const enva = calcCant(code);
    const c20 = calcCant("010001");
    const c12 = calcCant("010002");
    const envaDebe = c20 + c12 - enva;
    //ojo, no puedo vender mas envases que : calcEnvaStockFisico + envaDebe
    return envaDebe < 0 ? 0 : envaDebe;
  };

  const envaDebe = () => {
    const calcBidones = calcCant("010001") + calcCant("010002");
    const ret = calcBidones - stockEnvaVacios;
    return ret < 0 ? 0 : ret;
  };

  return !articulos || userLoading ? (
    <div className="container bg-white p-5 my-5">
      <div className="row">
        <div className="col d-flex justify-content-center">
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Buscando artículos...</span>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      id="shopping_consumidor_playero"
      className="d-flex flex-grow-1 justify-content-center"
    >
      <div className="products frex-column justify-content-center align-content-center my-1">
        <div className="d-flex flex-wrap justify-content-center">
          {/*      PRODUCT CONTAINER    */}
          {articulos.map((product, index) => (
            <div
              key={index}
              className={`product ${
                calcCant(product.code) ||
                (product.code === "010008" && envaseDebe())
                  ? "active"
                  : "inactive"
              } m-1 p-1 text-center border rounded mt-1`}
            >
              {/*     S T I C K E R S      */}
              {/*       STOCK ENVASES    */}
              {/*         PLAYERO         */}
              {userType === "Playero" &&
                product.code === "010008" &&
                !envaseDebe() && (
                  <div
                    className={` ${
                      envVacios < 0
                        ? "debeSticker debeIzq"
                        : !envVacios
                        ? "stockSticker stockMin"
                        : "stockSticker "
                    }`}
                  >
                    <div>{envVacios < 0 ? "Debes" : "Hay"}</div>
                    <div>
                      {envVacios < 0 ? -envVacios : envVacios}
                    </div>
                  </div>
                )}
              {/*         CONSUMIDOR     */}
              {userType !== "Playero" &&
                product.code === "010008" &&
                !envaseDebe() &&
                stockEnvaVacios > 0 && (
                  <div className="stockSticker">
                    <div>Hay</div>
                    <div>{stockEnvaVacios > 0 ? envVacios : "0"}</div>
                  </div>
                )}

              {
                /*  STICKER DEBE ENVASE */
                (product.code === "010008" && envaseDebe() > 0 && (
                  <Fade right>
                    <div className={`debeSticker debe`}>
                      <div>Debe</div>
                      <div>
                        <b>{envaseDebe()}</b>{" "}
                        <div>envase{envaseDebe() > 1 ? "s" : " "}</div>
                      </div>
                    </div>
                  </Fade>
                )) ||
                  /* STICKERS RESTO DE PRODUCTOS  */
                  (product.code !== "010008" && (
                    <div
                      className={`stockSticker ${
                        !(product.stock - calcCant(product.code))
                          ? "agotado"
                          : product.stock - calcCant(product.code) <=
                            product.stock_min
                          ? "stockMin"
                          : ""
                      }`}
                    >
                      <div>Hay</div>
                      <div>
                        {product.stock
                          ? product.stock - calcCant(product.code)
                          : "0"}
                      </div>{" "}
                    </div>
                  ))
              }

              {/*     PRODUCT IMG    */}

              <div className="imgContainer">
                <img
                  className={`card-img-top ${
                    product.code === "010008" ? "envase" : ""
                  }`}
                  src={`data:image/png;base64,${product.image}`}
                  alt={product.description}
                />
              </div>

              {/*     PRODUCT DESCRIPTION Y PRICE    */}

              <div className="description">{product.description}</div>
              <span className="price">$ {product.price} </span>

              {/*     B O T O N E R A   */}

              <div
                className={`botonera ${
                  calcCant(product.code) ? "activa" : "inactiva"
                } d-inline-flex flex-wrap justify-content-center mx-3`}
              >
                <div>
                  <button
                    className={`minusBtn ${
                      !calcCant(product.code) ? "inactivo" : ""
                    }`}
                    onClick={() => minus(index, product.code)}
                    disabled={calcCant(product.code) === 0}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </button>
                </div>
                <div className="contentCant mx-1">
                  <input
                    id={product.code}
                    className="form-control inputCantidad"
                    type="number"
                    step="1"
                    min="0"
                    value={calcCant(product.code)}
                    onKeyPress={(event) => validate(event)}
                    onChange={() => onChange(index, product.code)}
                    ref={(ref) => setRef(index, ref)}
                    readOnly
                    style={
                      calcCant(product.code) > 0
                        ? {
                            color: "blue",
                            backgroundColor: "azure",
                            fontWeight: "bold",
                          }
                        : { color: "grey " }
                    }
                  />
                </div>
                <div>
                  {(product.code !== "010008" && (
                    <button
                      className={`addBtn ${
                        product.stock - calcCant(product.code) <= 0
                          ? "disabled"
                          : ""
                      } `}
                      onClick={() => add(index, product.code)}
                      disabled={product.stock - calcCant(product.code) <= 0}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  )) || (
                    /*  BOTON ADD ENVASE  */
                    <button
                      className={`addBtn ${
                        (envVacios <= 0 || calcEnvBidones() <= 0) &&
                        !envaseDebe()
                          ? "disabled"
                          : ""
                      } `}
                      onClick={() => add(index, product.code)}
                      disabled={
                        (envVacios <= 0 || calcEnvBidones() <= 0) &&
                        envaseDebe() <= 0
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="footer d-flex flex-column flex-grow-1 mt-2 justify-self-center">
          {userType !== "Playero" && (
            <div className="text-secondary text-center">
              TakeAway de: <b>{ (tkRack.responsables)?tkRack.responsables.join(" / "): "" }
            </b>
            </div>
          )}
          <div className="m-3">
            {(shopping.length > 0 && (
              <Fade left>
                <button
                  type="button"
                  className={`btn ${
                    userType === "Playero" ? "btn-success" : "btn-primary"
                  }  btn-block justify-content-center align-items-center`}
                  onClick={() => redirectOrders(rackID)}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Tooltip on top"
                  disabled={totalArticles() === 0}
                >
                  SIGUIENTE{" "}
                  {(userType === "Playero" && (
                    <FontAwesomeIcon icon={faCashRegister} color={"#007bff"} />
                  )) || (
                    <FontAwesomeIcon icon={faShoppingCart} color={"#28a745"} />
                  )}
                  {shopping.length > 0 && (
                    <span className="totalArticles ml-2 badge badge-light">
                      {totalArticles()}
                    </span>
                  )}
                  {shopping.length === 0 && ``}
                </button>
              </Fade>
            )) || (
              <button
                type="button"
                className={`btn ${
                  userType === "Playero" ? "btn-success" : "btn-primary"
                }  btn-block justify-content-center align-items-center`}
                onClick={() => redirectOrders(rackID)}
                data-toggle="tooltip"
                data-placement="top"
                title="Tooltip on top"
                disabled={totalArticles() === 0}
              >
                SIGUIENTE{" "}
                {(userType === "Playero" && (
                  <FontAwesomeIcon icon={faCashRegister} color={"#007bff"} />
                )) || (
                  <FontAwesomeIcon icon={faShoppingCart} color={"#007bff"} />
                )}
                {shopping.length > 0 && (
                  <span className="totalArticles ml-2 badge badge-light">
                    {totalArticles()}
                  </span>
                )}
                {shopping.length === 0 && ``}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShoppingTakeaway;
