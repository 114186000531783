import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import agua from "../../assets/images/marca_inicio.png";
import take from "../../assets/images/takeaway3.png";
import { TK_USER_CREATE, TK_USER_DELETE } from "../../redux/duck/tk.user.duck ";
import { TK_SHOPPING_REMOVE_ALL } from "../../redux/duck/tk.shopping.duck";
import Fade from "react-reveal/Fade";
import { useApolloClient } from "@apollo/react-hooks";
import { GET_USER_BY_PASS } from "../../query/takeaway/racks.tk.query";
import Swal from "sweetalert2";
import moment from "moment";

function Header({ takeID }) {
  const [password, setPassword] = useState({ password: "" });
  const [userType, setUserType] = useState("");
  const [title, setTitle] = useState("Llevalo ahora! 😉");
  const [userIsLoading, setUserIsLoading] = useState(false);
  const tkUser = useSelector((state) => state.tkUser);
  const tkRack = useSelector((state) => state.tkRack);
  const client = useApolloClient();

  const dispatch = useDispatch();

  useEffect(() => {
    if (tkUser?.tipo) {
      setUserType(tkUser.tipo);
      const name =
        tkUser.nombre.toUpperCase() + ", " + tkUser.apellido.toUpperCase();
      const icono = tkUser.tipo === "Repartidor" ? " 🚚" : " 💰";
      setTitle(`${name} ${icono}`);
    } else {
      setUserType("");
    }
  }, []);

  const fetchData = useCallback(async (p) => {
    try {
      setUserIsLoading(true);
      const resultUser = await client.query({
        query: GET_USER_BY_PASS,
        variables: {
          pass: p,
          id_rack: takeID,
        },
        fetchPolicy: "no-cache",
      });

      if (resultUser.data.getUserTKByPass) {
        iniciarTakeaway(resultUser.data.getUserTKByPass);
      } else {
        setPassword({ password: "" });
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Usuario no encontrado!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      setUserIsLoading(false);
    } catch (error) {
      console.log("Error al traer el usuario: ", error);
      setUserIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (password.length === 5) fetchData(password);
  }, [password]);

  const iniciarTakeaway = (userDB) => {
    //cargo tk_user
    setUserType(userDB.tipo);
    dispatch({
      type: TK_USER_CREATE,
      tkUser: {
        id: userDB._id,
        tipo: userDB.tipo,
        nombre: userDB.nombre,
        apellido: userDB.apellido,
      },
    });
    //limpio tk_shopping
    dispatch({
      type: TK_SHOPPING_REMOVE_ALL,
      shopping: null,
    });
    //seteo titulo
    const name =
      userDB.nombre.toUpperCase() + ", " + userDB.apellido.toUpperCase();
    const icono = userDB.tipo === "Repartidor" ? " 🚚" : " 💰";
    setTitle(`${name} ${icono}`);
    localStorage.setItem("cargarArts", true);
  };

  useEffect(() => {
    if (userIsLoading) {
      dispatch({
        type: TK_USER_CREATE,
        tkUser: {
          userIsLoading: true,
        },
      });
    } else {
      dispatch({
        type: TK_USER_CREATE,
        tkUser: {
          userIsLoading: false,
        },
      });
    }
  }, [userIsLoading]);

  const handleChange = (e) => {
    setPassword(e.target.value);
    if (password.length === 4) e.target.value = "";
  };

  const exit = () => {
    dispatch({
      type: TK_USER_DELETE,
      tkUser: null,
    });
    dispatch({
      type: TK_SHOPPING_REMOVE_ALL,
      shopping: null,
    });

    setTitle("Llevalo ahora! 😉");
    setUserType("");
  };

  const mensajeInfoTA = () => {
    //const version = "v3.190123.00 (beta)"
    //const version = "v4.080223.0922 (beta)";
    //const version = "v5.130223.1158 (beta)";
    //const version = "v6.160223.0249 (beta)";
    //const version = "v7.030323.2237 (estable)";
    const version = "v8.210323.1349";
    const tkUserId = tkUser.id ? tkUser.id : " consumidor";
    Swal.fire({
      icon: "info",
      title: "<h3>TakeAway de<br> Agua Nuestra S.A.</h3>",
      html: `<div>
      <p>Consesionario: <span class='text-info'>${tkRack.negocio}<span/></p>
      <p>Dirección: <span class='text-info'>${tkRack.direccion}<span/></p>
      <p>Rack_Id: <span class='text-info'>${tkRack.id}<span/></p>
      <p>User_Id: <span class='text-info'>${tkUserId}<span/></p>
      <p>Fecha Hora: <span class='text-info'>${moment().format(
        "DD/MM/YY HH:m"
      )} Hs<span/></p>
      <p>Software_version: <span class='text-info'>${version} (ElemeSoft)<span/></p>
      </div>`,
      padding: "5px",
    });
  };

  return (
    <div
      className={`header ${
        userType === "Repartidor"
          ? "repartidorProfile"
          : userType === "Playero"
          ? "playeroProfile"
          : ""
      } d-flex justify-content-center flex-column`}
    >
      <div className="logos d-flex px-4 pb-1 pt-3 justify-content-center  ">
        <div className="logo_agua">
          <img src={agua} alt="logo_agua" />
        </div>
        <div className="flex-grow-1"></div>
        <div className="logo_take">
          <img src={take} alt="logo_take" onClick={mensajeInfoTA} />
        </div>
      </div>
      <div
        className={`titleContainer ${
          userType === "Repartidor"
            ? "repartidorProfile"
            : userType === "Playero"
            ? "playeroProfile"
            : ""
        } flex-fill text-center mt-2`}
      >
        <div className="d-flex align-items-center">
          <div className="w-100"></div>
          <div className="title w-100">
            <Fade left>{title}</Fade>
          </div>
          <div className="w-100 text-right pr-2 ">
            {userIsLoading && (
              <div className="position-absolute top-50 start-50 translate-middle">
                <div className="spinner-grow text-primary" role="status"></div>
              </div>
            )}
            {(!userType && (
              <input
                id="pass"
                className="admin text-right pr-2 "
                placeholder="ADMIN"
                type="password"
                maxLength="5"
                onChange={handleChange}
                autoComplete="nope"
                disabled={userIsLoading}
              />
            )) ||
              (userType && (
                <button
                  className="salir btn btn-link text-right pr-2 "
                  onClick={exit}
                >
                  Salir
                </button>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
