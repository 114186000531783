import gql from 'graphql-tag';

export const CHANGE_PASSWORD = gql`
  mutation changeUserPassword(
    $token: String!
    $old_password: String!
    $new_password: String!
  ) {
    changeUserPassword(
      token: $token
      old_password: $old_password
      new_password: $new_password
    ) {
      _id
    }
  }
`;
