import React, { useEffect, useState } from 'react';

// apollo
// import { useApolloClient } from '@apollo/react-hooks';

// query
// import { GET_PREFERENCE_ID } from '../query/mercadopago/mercadopago.query';

// swal
// import Swal from 'sweetalert2';

const PagoMecadoPagoComponent = ({ object, saldo, setValue }) => {
  // apollo client
  // const client = useApolloClient();

  const [saldoEnviar, setSaldoEnviar] = useState(saldo);

  useEffect(() => {
    console.log('saldo', saldo);

    if (saldo) {
      setSaldoEnviar(parseFloat(saldo));
    } else {
      setSaldoEnviar(parseFloat(0));
    }
  }, [saldo]);

  const save = () => {
    const amount = document.getElementById('value').value;
    setValue(parseFloat(amount));
    object.clickConfirm();
  };

  const onChange = async () => {
    const amount = parseFloat(document.getElementById('value').value);
    const aux = !isNaN(amount) ? amount : 0;
    setValue(aux);
    setSaldoEnviar(parseFloat(aux));
  };

  const cancel = () => {
    object.clickCancel();
  };

  return (
    <div className='row'>
      <div className='col-md-12'>
        <label>Monto:</label>
        <input
          type='number'
          className='form-control'
          value={saldoEnviar ? saldoEnviar : 0.0}
          onChange={onChange}
          id='value'
        />
      </div>
      <div className='col-md-12 mt-3'>
        <button type='button' className='btn btn-primary' onClick={save}>
          Pagar
        </button>
        <button
          type='button'
          className='btn btn-secondary ml-3'
          onClick={cancel}
        >
          Cancelar
        </button>
      </div>
    </div>
  );
};

export default PagoMecadoPagoComponent;
