import React, { useState, useCallback, useEffect } from 'react';

// apollo
import { useApolloClient } from '@apollo/react-hooks';

// redux functions
import { SHOPPING_ADD } from '../redux/duck/shopping.duck';
import { SHOPPING_REMOVE } from '../redux/duck/shopping.duck';

// query
import { CREAR_NOTA_PEDIDO } from '../query/articles/articles.query';
import { FIND_CONSUMIPTIONS_BY_CLIENT } from '../query/clients/client.query';

// redux
import { useDispatch, useSelector } from 'react-redux';

// redux actions
import { SHOPPING_REMOVE_ALL } from '../redux/duck/shopping.duck';
import { DISPONIBLE_CREATE } from '../redux/duck/disponible-duck';

// moment
import moment from 'moment';

// react-reveal
import Fade from 'react-reveal/Fade';

// swal
import Swal from 'sweetalert2';

// icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import {ADD_NEW_TICKET, GET_CLAIM_TIPE} from "../query/claim/claim.query";

const OrdersPage = () => {
  // dispatch
  const dispatch = useDispatch();
  const shopping = useSelector((state) => state.shopping);
  const disponible = useSelector((state) => state.disponible);
  // dispatch
  const code = useSelector((state) => state.client);

  const [reclamo, setReclamo] = useState();
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // apollo client
  const client = useApolloClient();

  const getReclamo = async () => {
    const r = await client.query({
      query: GET_CLAIM_TIPE
    });
    setReclamo(r.data.findPedidoCliente);
  }
  const fetchData = useCallback(async () => {
    await getReclamo();
    if (shopping && shopping.length > 0) {
      try {
        setIsLoading(true);
        console.log('user code', code.client);
        const current_date = moment().format('YYYY-MM-DD');
        console.log('current_date', current_date);
        const resultInfo = await client.query({
          query: FIND_CONSUMIPTIONS_BY_CLIENT,
          variables: {
            user_code: code.client,
          },
          fetchPolicy: 'cache',
        });

        const aux = resultInfo.data.findClientConsumptionsInfo;
        guardarConsumidosRedux(aux);
        console.log('consumo', aux);
        setIsLoading(false);
      }
      catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  }, []);


  const setPedidoCliente = async (text) => {
    const token = localStorage.getItem('token');
    const client_name = localStorage.getItem('client_name');
    let textArr = reclamo.name.split('-');
    let name = (textArr.length > 0)?textArr[1].trim().toUpperCase() : "PEDIDOS DE CLIENTE";
    let ticket = {
      _id: null,
      name: name,
      client: code.client,
      client_name: (client_name)?client_name:"",
      notificacion: {
        token: token,
        client_code: code.client,
        text: text,
        type: reclamo._id,
        notifLogis: {
          fecha_venc: moment().add(1, 'days').utc(),
          asunto: "PEDIDO",
          clasificacion: null, //dejar en null para que lo busque el bkend
          prioridad: "MEDIA",
          estado: "PENDIENTE"
        }
      }
    }

    //console.log(ticket);

    const r = await client.query({
      query: ADD_NEW_TICKET,
      variables: {
        ticket: ticket
      },
      fetchPolicy: "no-cache"
    });
    console.log(r);
  }


  const guardarConsumidosRedux = (bidones) => {
    const data = {
      '010001':
        bidones[0].diponibles > 0
          ? bidones[0].diponibles - bidones[0].consumidos
          : 0, // X20
      '010002':
        bidones[1].diponibles > 0
          ? bidones[1].diponibles - bidones[1].consumidos
          : 0, // X12
    };

    dispatch({
      type: DISPONIBLE_CREATE,
      disponible: data,
    });
  };

   useEffect(() => {
     fetchData();
   }, [code, fetchData]);

  const vaciarLista = () => {
    console.log('vaciar lista');
    dispatch({
      type: SHOPPING_REMOVE_ALL,
      shopping: null,
    });
  };

  const finalizar = async () => {
    if (shopping.length === 0) {
      return;
    }

    setDisabled(true);
    const userCode = localStorage.getItem('client_code');


    const articulos = [];
    let textData = "";

    for (const element of shopping) {
      const total = await calcTotal(element);
      articulos.push({
        code: element.code,
        cantidad: element.cantidad,
        lista_id: element.lista,
        precio: total,
      });

      textData += `${element.code.trim()} - ${element.description.trim()}: ${element.cantidad} unid/s - \$${total} \n`;

    }

    console.log(textData);

    const variables = {
      cli_code: userCode,
      articulos: articulos,
      obs: "",
    };
    console.log('variables', variables);

    try {
      await setPedidoCliente(textData);

      const notaDePedido = await client.query({
        query: CREAR_NOTA_PEDIDO,
        variables: variables,
        fetchPolicy: 'no-cache',
      });
      console.log('resultado', notaDePedido);
      if (notaDePedido.data.crearNotaPedido) {
        vaciarLista();
        Swal.fire(
          'Pedido',
          'Su pedido fue procesado correctamente. En unos minutos confirmaremos su pedido a su correo electrónico',
          'success'
        );
      }
      else {
        Swal.fire(
          'Pedido',
          'Ocurrio un error al procesar su pedido, intente nuevamente mas tarde o comuniquese con el administrador',
          'error'
        );
      }
      setDisabled(true);
    } catch (error) {
      setDisabled(false);
      Swal.fire(
        'Pedido',
        'Ocurrio un error al procesar su pedido, intente nuevamente mas tarde o comuniquese con el administrador',
        'error'
      );
    }
  };

  const minus = (cant, cod) => {
    console.log('minus', cant, cod);
    const aux = parseInt(cant) - 1;

    const art = shopping.find((ele) => ele.code === cod);
    console.log(aux, art);

    dispatch({
      type: SHOPPING_REMOVE,
      shopping: {
        code: cod,
        lista: art.lista_id,
        cantidad: aux,
        price: art.price,
        description: art.description,
      },
    });
    //fetchData();
  };
  const agregar = (cant, cod) => {
    console.log('agregar', cant, cod);
    const aux = parseInt(cant) + 1;

    const art = shopping.find( ele => ele.code === cod);
    console.log(aux, art);

    dispatch({
      type: SHOPPING_ADD,
      shopping: {
        code: cod,
        cantidad: aux,
        lista: art.lista_id,
        price: art.price,
        description: art.description,
      },
    });
    // fetchData();
  };

  const rows = () => {
    return shopping.map((element) => {
      const total = calcTotal(element);

      return (
        <tr key={element.code}>
          <td> {element.code}</td>
          <td>
            {element.description}
            {disponible[element.code]
              ? `( Disponibles de abono ${disponible[element.code]})`
              : ''}
          </td>
          <td>
            <button
              className='btn btn-secondary btn-sm mb-3 mb-md-0'
              onClick={() => minus(element.cantidad, element.code)}
            >
              <FontAwesomeIcon icon={faMinus} />
            </button>
            <span className='m-3'>{element.cantidad}</span>
            <button
              className='btn btn-secondary  btn-sm mt-3 mt-md-0'
              onClick={() => agregar(element.cantidad, element.code)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </td>
          <td>{`$ ${total.toFixed(2)}`}</td>
        </tr>
      );
    });
  };

  const calcTotal = (element) => {
    let cantidadDisponible = disponible[element.code]
      ? disponible[element.code] - element.cantidad
      : element.cantidad;

    let total = 0;
    if (disponible[element.code]) {
      if (disponible[element.code] > element.cantidad) {
        total += 0;
      } else if (disponible[element.code] < element.cantidad) {
        total += cantidadDisponible * parseFloat(element.price) * -1;
      } else {
        total = 0;
      }
    } else {
      total = element.cantidad * parseFloat(element.price);
    }

    return total;
  };

  const total = () => {
    let total = 0;
    for (const order of shopping) {
      console.log('row', order);
      total += calcTotal(order);
    }

    return (
      <tr>
        <td colSpan='3'>
          <strong>Total</strong>
        </td>
        <td>{`$ ${total.toFixed(2)}`}</td>
      </tr>
    );
  };

  return isLoading ? (
    <div className='container bg-white my-5 p-3'>
      <div className='row'>
        <div className='col d-flex justify-content-center'>
          <div className='spinner-grow text-primary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Fade>
      <div className='container bg-white my-5 p-3'>
        <div className='row'>
          {shopping && shopping.length > 0 && (
            <div className='col'>
              <h3 className='text-secondary'>Carrito de Compra</h3>
              <table className='table my-3'>
                <thead className='thead-dark'>
                  <tr>
                    <th scope='col'>Código</th>
                    <th scope='col'>Descripción</th>
                    <th scope='col'>Cantidad</th>
                    <th scope='col'>Precio Unitario</th>
                  </tr>
                </thead>
                <tbody>
                  {rows()}
                  {total()}
                </tbody>
              </table>
              <div className='mt-3 text-muted'>
                <strong>
                  * Los envíos se procesan dentro del horario de atención de
                  Lunes a Viernes de 8 a 18 y Sábado de 8 a 13
                </strong>
              </div>
              <div className='mb-3 text-muted'>
                <strong>
                  * En caso de hacer un pedido fuera del horario de atención sera procesado el próximo día habil
                </strong>
              </div>
            </div>
          )}
          {shopping.length === 0 && (
            <div className='col'>
              <h4 className='text-muted my-3'>
                No hay productos en el carrito de compras
              </h4>
            </div>
          )}
        </div>
        {shopping.length !== 0 && (
          <div className='row'>
            <div className='col'>
              <button
                type='button'
                className='btn btn-primary'
                onClick={finalizar}
                disabled={disabled}
              >
                Finalizar Pedido
              </button>
              <button
                type='button'
                className='ml-3 btn btn-secondary'
                onClick={vaciarLista}
                disabled={disabled}
              >
                Vaciar la Lista
              </button>
            </div>
          </div>
        )}
      </div>
    </Fade>
  );
};

export default OrdersPage;
