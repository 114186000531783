import { combineReducers, createStore } from "redux";

import { persistStore, persistReducer } from 'redux-persist' // imports from redux-persist
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

// reducers
import user from "./user-duck";
import client from "./client-duck";
import shopping from './shopping.duck';
import disponible from './disponible-duck';
import tkShopping from "./tk.shopping.duck";
import tkUser from "./tk.user.duck ";
import tkRack from "./tk.rack.duck ";

// call user
import { USER_CREATE } from "./user-duck";
import { CLIENT_CREATE } from "./client-duck";

const persistConfig = {
  // configuration object for redux-persist
  key: 'root',
  storage, // define which storage to use
};

const rootReducer = combineReducers({
  user,
  client,
  shopping,
  disponible,
  tkShopping,
  tkUser,
  tkRack,
});

const persistedReducer = persistReducer(persistConfig, rootReducer) // create a persisted reducer

const token = localStorage.getItem("token");
const code = localStorage.getItem("client_code");

const rStore = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

rStore.dispatch({
  type: USER_CREATE,
  user: token,
});

rStore.dispatch({
  type: CLIENT_CREATE,
  client: code,
});


const persistor = persistStore(rStore);

const store = rStore;

export { store, persistor };
