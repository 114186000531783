import React from 'react';
import {Link} from "react-router-dom";

export const types_visitas = {
    A1: "25 a 30 días",
    B1: "25 b 30 días",
    A2: "13 a 15 días",
    B2: "13 a 15 días",
    A3: "semanales",
    B3: "semanales",
    OTHER: "-"
}

export function getStringByType(type) {
    switch (type) {
        case "A1": case "B1": {
            return (
            <span className='text-muted'>Nota: Su categoría posee visitas cada {types_visitas.A1}. Puede adelantar la misma
                solicitando el/los producto/s directamente desde la pestaña <Link to='productos'>Productos</Link></span>)
        } break;
        case "A2": case "B2": {
            return (
                <span className='text-muted'>Nota: Su categoría posee visitas cada {types_visitas.A2}. Puede adelantar la misma
                    solicitando el/los producto/s directamente desde la pestaña <Link to='productos'>Productos</Link></span>)
        } break;
        case "A3": case "B3": {
            return <span className='text-muted'>Nota: Su categoría posee visitas {types_visitas.A3}. Puede adelantar la misma
                    solicitando el/los producto/s directamente desde la pestaña <Link to='productos'>Productos</Link></span>;
        } break;
        default: { return types_visitas.OTHER; }
    }
}