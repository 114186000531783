import React from "react";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

// redux
import { useSelector } from "react-redux";

import LoginPage from "./login.page";
import HomePage from "./home.page";
import NotfoundPage from "./notfound.page";
import PagosManagementPage from "./pagos.management.page";
import PaymentBankPage from "./payment-bank.page";
import PaymentMercadoPago from "./payment-mercadopago.page";
import ProfileManagerPage from "./profile-manager.page";
import HeaderPrivate from "../components/header-private.component";
import FooterPrivate from "../components/footer-private.component";
import ShoppingPage from "./shopping.page";
import OrdersPage from "./orders.page";
import ClaimPage from "./claim.page";
import ComprobantePage from "./comprobante.page";
import AutoloadPage from "./autoload.page";
import { TakeawayIndex } from "./takeaway/index";
import OrdersPageTakeaway from "./takeaway/orders.page.takeaway";


const MainPage = () => {
  const user = useSelector((state) => state?.user);
  //console.log("user main page", user.user);
  return (
    <>
      <BrowserRouter>
        {user.user && <HeaderPrivate />}
        <div style={{
            marginTop: user.user !== null ? "50px" : "0px",
            paddingTop: user.user !== null ? "220px" : "0px",
            paddingBottom: "75px"
          }}>
          <Switch>
            <Route exact path="/">
              {user.user && <Redirect to="/home" />}{" "}
              {!user.user && <LoginPage />}
            </Route>
            <Route exact path="/cliente/:id">
              <AutoloadPage />
            </Route>
            <Route exact path="/home">
              <HomePage />
            </Route>
            <Route exact path="/pagos">
              <PagosManagementPage />
            </Route>
            <Route exact path="/pagos/banco">
              <PaymentBankPage />
            </Route>
            <Route exact path="/pagos/mercadopago">
              <PaymentMercadoPago />
            </Route>
            <Route exact path="/perfil">
              <ProfileManagerPage />
            </Route>
            <Route exact path="/productos">
              <ShoppingPage />
            </Route>
            <Route exact path="/pedidos">
              <OrdersPage />
            </Route>
            <Route exact path="/reclamos">
              <ClaimPage />
            </Route>
            <Route
              path="/comprobantes/:id"
              render={(props) => <ComprobantePage {...props} />}
            />

            {/* Takeaway */}
            <Route exact path="/takeaway/:id/pedidos">
              <OrdersPageTakeaway />
            </Route>
            <Route exact path="/takeaway/:id/paysuccess/rec/">
              {/* <TakeawayPaySuccess /> */}
              <TakeawayIndex />
            </Route>
            <Route path="/takeaway/:id">
              <TakeawayIndex />
            </Route>

            <Route component={NotfoundPage} />
          </Switch>
        </div>
        {user.user && <FooterPrivate />}
      </BrowserRouter>
    </>
  );
};

export default MainPage;
