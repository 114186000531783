import React, {useState, useEffect, useCallback} from 'react';

// forms
import {useForm} from 'react-hook-form';

import Swal from 'sweetalert2';

// apollo
import {useApolloClient} from '@apollo/react-hooks';

// query
import {
    FIND_CLIENT_DATA_BY_CODE,
    CHANGE_DATA_REQUEST,
} from '../query/clients/client.query';


import '../scss/profile.scss';
import { getStringByType } from "../helpers/users.helper";

const ProfilePage = () => {
    // forms
    const {register, handleSubmit, watch, errors} = useForm();

    const [oName, setOName] = useState('');
    const [oAddress, setOAddress] = useState('');
    const [oEmail, setOEmail] = useState('');
    const [oPhone, setOPhone] = useState('');
    const [tipoClient, setTipoClient] = useState('');

    const fullName = watch('fullName');
    const email = watch('email');
    const phone = watch('phone');

    // apollo client
    const client = useApolloClient();

    const fetchData = useCallback(async () => {
        const cli_code = localStorage.getItem('client_code');

        try {
            const resultUser = await client.query({
                query: FIND_CLIENT_DATA_BY_CODE,
                variables: {cli_code: cli_code},
                fetchPolicy: 'no-cache',
            });
            console.log('resultUser', resultUser);

            const data = resultUser.data.findClientByClientCode;

            setOName(data.razonSocial);
            setOAddress(data.domicilio);
            setOEmail(data.email);
            setOPhone(data.telefono);
            setTipoClient(data.tipo);
        } catch (error) {
            console.log('error', error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const guardar = async () => {
        console.log(fullName, email, phone);

        const cli_code = localStorage.getItem('client_code');

        const variables = {
            cli_code: cli_code,
            name: fullName,
            email: email,
            phone: phone,
        };

        try {
            const resultUser = await client.query({
                query: CHANGE_DATA_REQUEST,
                variables: variables,
                fetchPolicy: 'no-cache',
            });
            console.log('resultUser', resultUser);

            const data = resultUser.data.requestClientDataChange;

            if (data) {
                Swal.fire(
                    'Cambio de Datos',
                    'Fue solicitado el cambio de datos, se verá reflejado en el sistema en algunas horas',
                    'success'
                );
            } else {
                Swal.fire(
                    'Cambio de Datos',
                    'Error al notificar el cambio de datos, por favor consulte a administración',
                    'error'
                );
            }
        } catch (error) {
            console.log('error', error);
        }
    };
    return (

            <div className='col col-md-6'>
                <form className='container' onSubmit={handleSubmit(guardar)}>
                  <div className='row'>
                    <div className='col-12'>
                      <div>
                        <span className='text-muted'> </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className='form-group'>
                        <label htmlFor='exampleInputEmail1'>Nombre Completo</label>
                        <input
                            type='text'
                            className='form-control'
                            id='fullName'
                            name='fullName'
                            aria-describedby='emailHelp'
                            placeholder='Nombre Completo'
                            defaultValue={oName}
                            ref={register({required: true})}
                        />
                        {errors.fullName && (
                            <span className='mt-2 text-danger'>
              Nombre completo es requerido
            </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className='form-group'>
                        <label htmlFor='exampleInputEmail1'>Email</label>
                        <input
                            type='email'
                            className='form-control'
                            id='email'
                            name='email'
                            ref={register}
                            aria-describedby='emailHelp'
                            defaultValue={oEmail}
                            placeholder='Email'
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <div className='form-group'>
                        <label htmlFor='exampleInputPassword1'>Teléfono Contacto</label>
                        <input
                            type='text'
                            className='form-control'
                            id='phone'
                            name='phone'
                            ref={register}
                            defaultValue={oPhone}
                            placeholder='Teléfono'
                        />
                      </div>
                    </div>
                    <div className='col-8'>
                      <div className='form-group'>
                        <label htmlFor='catInput'>Categoría</label>
                        <input
                            type='text'
                            readOnly={true}
                            disabled={true}
                            className='form-control'
                            id='catInput'
                            defaultValue={tipoClient}
                            name='catInput' />

                          { getStringByType(tipoClient) }

                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <button type='submit' className='btn btn-primary'>
                        Guardar
                      </button>
                      <button type='botton' className='ml-3 btn btn-secondary'>
                        Cancelar
                      </button>
                    </div>
                  </div>





                </form>
            </div>

    );
};

export default ProfilePage;
