import React from 'react';

import '../scss/footer.scss';

const FooterPrivate = () => {
  return (
    <div className='row fixed-bottom mt-5 with-border-up-blue p-3'>
      <div className='col justify-content-center'>
        <img
          src={require('../assets/images/marca_cabezal.png')}
          alt={'logo-azul'}
          className='logo-azul'
        />
      </div>
    </div>
  );
};

export default FooterPrivate;
