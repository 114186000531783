import React from "react";
import ShoppingTakeaway from "./tk.Shopping.component";
import RepartidorTakeaway from "./tk.Repartidor.component";
import { useSelector } from "react-redux";

function ProfileSwitcher({arts}) {

  const tkUser = useSelector((state) => state.tkUser);

  
  return arts? (
    <> 
      {tkUser.tipo && tkUser.tipo === "Repartidor" && <RepartidorTakeaway arts={arts}/>} 
      {tkUser.tipo && tkUser.tipo === "Playero" && <ShoppingTakeaway arts={arts}/>} 
      {!tkUser.tipo && <ShoppingTakeaway arts={arts}/>}
    </>
  ):<>No hay artículos</>
}

export default ProfileSwitcher;
