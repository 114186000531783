// users.js

export const TK_USER_CREATE = "TK_USER_CREATE";
export const TK_USER_DELETE = "TK_USER_DELETE";

export default function tkUser(state = {}, action) {
  switch (action.type) {

    case TK_USER_CREATE:
      return Object.assign({}, state, action.tkUser);

    case TK_USER_DELETE:
      return Object.assign({}, {}, {});


    default:
      return state;
  }
}
