import React from "react";

import { useHistory } from "react-router-dom";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

// react-reveal
import Fade from "react-reveal/Fade";

const BannerSugerenciaReclamo = () => {
  const history = useHistory();

  const redirectReclamos = () => {
    history.push("/reclamos");
  };

  return (
    <div className="bg-white">
      <div className="container ">
        <div className="row p-3">
          <div className="col-6 d-flex align-item-center justify-content-end">
            <div onClick={redirectReclamos} >
              <FontAwesomeIcon
                className={"shopping-car"}
                icon={faEnvelope}
                size={"6x"}
                color="#4c97c5"
              />
            </div>
          </div>
          <div className="col-6 d-flex align-item-center justify-content-left text-primary">
            <Fade>
              <h3 className="mr-5 banner-pedido" onClick={redirectReclamos}>
                Reclamos
              </h3>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerSugerenciaReclamo;
