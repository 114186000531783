import React from 'react';

import { useForm } from 'react-hook-form';

const ChangePassword = ({ object, changePassword }) => {
  const { register, handleSubmit, watch, getValues, errors } = useForm();

  const onSubmit = () => {
    const old_password = watch('old_password');
    const new_password = watch('new_password');
    const repeat_new_password = watch('repeat_new_password');

    console.log('passwords', old_password, new_password, repeat_new_password);

    changePassword(old_password, new_password);
    object.clickConfirm();
  };

  const cancel = () => {
    object.clickCancel();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='form-group'>
        <label htmlFor='exampleInputEmail1'>Contraseña Anterior</label>
        <input
          type='password'
          className='form-control'
          id='old_password'
          name='old_password'
          aria-describedby='emailHelp'
          placeholder='Contraseña Anterior'
          ref={register({ required: true })}
        />
        {errors.old_password && (
          <small id='erca' className='form-text text-danger'>
            Contraseña anterior es requerida
          </small>
        )}
      </div>
      <div className='form-group'>
        <label htmlFor='exampleInputPassword1'>Nueva Contraseña</label>
        <input
          type='password'
          className='form-control'
          id='new_password'
          name='new_password'
          placeholder='Nueva Contraseña'
          ref={register({ required: true })}
        />
        {errors.new_password && (
          <small id='ern' className='form-text text-danger'>
            Nueva contraseña es requerida
          </small>
        )}
      </div>
      <div className='form-group'>
        <label htmlFor='exampleInputPassword1'>Repita Nueva Contraseña</label>
        <input
          type='password'
          className='form-control'
          id='repeat_new_password'
          name='repeat_new_password'
          placeholder='Repita Nueva Contraseña'
          ref={register({
            required: true,
            validate: {
              passwordEqual: (value) => value === getValues().new_password,
            },
          })}
        />
        {errors.repeat_new_password && (
          <small id='errn' className='form-text text-danger'>
            Repetir contraseña distinta a nueva contraseña o es requerida y no
            fue cargada
          </small>
        )}
      </div>
      <button type='submit' className='btn btn-success'>
        Aceptar
      </button>
      <button type='button' className='btn btn-danger ml-3' onClick={cancel}>
        Cancelar
      </button>
    </form>
  );
};

export default ChangePassword;
