import React, { useEffect, useCallback, useState } from 'react';

// react-reveal
import Fade from 'react-reveal/Fade';

// redux
import { useSelector } from 'react-redux';

// apollo
import { useApolloClient } from '@apollo/react-hooks';

// query
import { GET_INVOCE_LAST_THREE_MONTH } from '../query/receipt/receipt.query';
import { GET_SALDO } from '../query/mercadopago/mercadopago.query';
import { CREATE_STATISTIC } from '../query/statistics/statistics.query';

// moment
import moment from 'moment';

import '../scss/receipt.scss';

const InvoicePage = () => {
  // dispatch
  const code = useSelector((state) => state.client);

  // apollo client
  const client = useApolloClient();

  // state
  const [facturas, setFacturas] = useState([]);
  const [saldo, setSaldo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      // invoice
      const resultFacturas = await client.query({
        query: GET_INVOCE_LAST_THREE_MONTH,
        variables: {
          user_code: code.client,
        },
        fetchPolicy: 'no-cache',
      });

      const invoices = resultFacturas.data.getLastThreeMonth;
      console.log('invoices', invoices);
      setFacturas(invoices);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }

    try {
      const token = localStorage.getItem('token');
      const saldoQuery = await client.query({
        query: GET_SALDO,
        variables: { token: token },
        fetchPolicy: 'no-cache',
      });

      const data = saldoQuery.data.findClientSaldo;

      console.log('data saldo', data);
      if (data && !isNaN(data)) {
        if (data > 0) {
          setSaldo(data);
        } else {
          setSaldo(0);
        }
      } else {
        setSaldo(0);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const getReceipt = async (id) => {
    const win = window.open(
      'https://micuenta.aguanuestra.com.ar/comprobantes/' + id,
      '_blank'
    );
    win.focus();
    try {
      const cli_code = localStorage.getItem('client_code');
      const variables = {
        type: 'invoice',
        client_id: cli_code,
      };

      const queryStatistics = await client.query({
        query: CREATE_STATISTIC,
        variables: variables,
        fetchPolicy: 'no-cache',
      });
      const result = queryStatistics ? queryStatistics.data.createStatic : null;

      if (result) {
        console.log('success');
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <Fade>
      {isLoading ? (
        <div className='container'>
          <div className='row'>
            <div className='col d-flex justify-content-center'>
              <div className='spinner-grow text-primary' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='container'>
          <div>
            <h4 className='text-info'>Resumen de Cuenta Corriente</h4>
          </div>
          <div className='table-responsive'>
            <table className='table'>
              <caption>Facturas y Recibos</caption>
              <thead>
                <tr>
                  <th scope='col'>Fecha</th>
                  <th scope='col'>Detalle</th>
                  <th scope='col'>Monto</th>
                  <th scope='col'>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {facturas &&
                  facturas.map((obj, index) => {
                    return (
                      <tr key={index}>
                        <td>{moment(obj.date).format('DD-MM-YYYY')}</td>
                        <td>{obj.description}</td>
                        <td className='text-right'>{`$ ${obj.amount.toFixed(
                          2
                        )}`}</td>
                        <td>
                          {!!obj.amount && obj.amount > 0 && (
                            <button
                              type='button'
                              className='btn btn-primary btn-sm'
                              onClick={() => getReceipt(obj.id)}
                            >
                              Ver
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {saldo ? (
              <h4 className='text-info'>
                Saldo: {saldo && `$ ${saldo.toFixed(2)}`}{' '}
              </h4>
            ) : (
              <h4 className='text-info'>Saldo: {!saldo && ' - '}</h4>
            )}
          </div>
        </div>
      )}
    </Fade>
  );
};

export default InvoicePage;
