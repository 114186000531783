import React from 'react';

// component
import InvoicePage from './invoice.page';

import '../scss/receipt.scss';

const ReceiptPage = () => {
  return (
    <div className='py-3  bg-white'>
      <InvoicePage />
    </div>
  );
};

export default ReceiptPage;
