import { gql } from "apollo-boost";

export const GET_RACK_BY_ID = gql`
  query ($id: String!) {
    getRackById(id: $id) {
      _id
      direccion
      negocio_name
      activo
      users {
        nombre
        apellido
      }
      articulos {
        _id
        stock
        stock_min
        Article {
          code
          lista_id
          description
          description_alt
          clasificacion
          price
          price_cost
          image
        }
      }
    }
  }
`;

export const CHANGE_STOCK = gql`
  subscription ChangeStock($idRack: String!) {
    changeStock(id_rack: $idRack) {
      _id
      direccion
      negocio_name
      activo
      users {
        nombre
        apellido
      }
      articulos {
        _id
        stock
        stock_min
        Article {
          code
          lista_id
          description
          description_alt
          clasificacion
          price
          price_cost
          image
        }
      }
    }
  }
`;

export const CHANGE_REMITOS = gql`
  subscription ChangeRemitos($idRack: String!) {
    changeRemitos(id_rack: $idRack) {
      _id
      createdAt
      id_repartidor {
        _id
        nombre
        apellido
      }
      detalle {
        code
        cantidad
        description
        price_cost
        subTotalCost
        modifierStock
      }
      costo_venta
      sinSaldar
      estado
      pagadoMp
    }
  }
`;

export const CHANGE_RECIBOS = gql`
  subscription ChangeRecibos($idRack: String!) {
    changeRecibos(id_rack: $idRack) {
      _id
      createdAt
      id_user {
        nombre
        apellido
      }
      monto_venta
      metodo
      estado
      sinAfectar
      detalle {
        code
        cantidad
        description
        price
        price_cost
        subTotalPrice
        modifierStock
      }
    }
  }
`;

export const CHANGE_SALDOS = gql`
  subscription ChangeSaldos($idRack: String!) {
    changeSaldos(id_rack: $idRack) {
      id_rack
      saldo_ef
      saldo_mp
    }
  }
`;

export const GET_USER_BY_PASS = gql`
  query ($pass: String!, $id_rack: String!) {
    getUserTKByPass(pass: $pass, id_rack: $id_rack) {
      nombre
      apellido
      tipo
      _id
    }
  }
`;

export const GET_RECIBOS_BY_RACK = gql`
  query getRecibosByRack($rackId: String!) {
    getRecibosByRack(rackId: $rackId) {
      _id
      createdAt
      id_user {
        nombre
        apellido
      }
      monto_venta
      metodo
      estado
      sinAfectar
      detalle {
        code
        cantidad
        description
        price
        price_cost
        subTotalPrice
        modifierStock
      }
    }
  }
`;

export const GET_REMITOS_BY_RACK = gql`
  query getRemitosByRack($rack: String!) {
    getRemitosByRack(id_rack: $rack) {
      _id
      createdAt
      id_repartidor {
        _id
        nombre
        apellido
      }
      detalle {
        code
        cantidad
        description
        price_cost
        subTotalCost
        modifierStock
      }
      costo_venta
      sinSaldar
      estado
      metodo
      pagadoMp
    }
  }
`;

export const GET_REMITO_BY_ID = gql`
  query GetRemitoById($idRemito: String!) {
    getRemitoById(id_remito: $idRemito) {
      estado
      costo_venta
      detalle {
        code
        cantidad
        price_cost
        description
        subTotalCost
      }
      createdAt
      fecha_pago
      id_repartidor {
        apellido
        nombre
      }
      metodo
      sinSaldar
      pagadoMp
    }
  }
`;

export const UPDATE_ACEPTA_REMITO = gql`
  mutation updateTkRemito($remito: tk_remitosInput!) {
    updateTkRemito(remito: $remito) {
      _id
    }
  }
`;

export const GET_RECIBO_BY_ID = gql`
  query GetReciboById($getReciboByIdId: String!) {
    getReciboById(id: $getReciboByIdId) {
      _id
      createdAt
      fecha_pago
      estado
      id_pago
      metodo
      monto_venta
      id_user {
        apellido
        nombre
      }
      detalle {
        cantidad
        code
        description
        subTotalPrice
        price
      }
    }
  }
`;

export const GET_SALDO_MP_BY_RACK = gql`
  query GetSaldoFavorRack($rack: String!) {
    getSaldoFavorRack(id_rack: $rack)
  }
`;

export const CLEAR_RECIBO = gql`
  mutation clearReciboByID($idRecibo: String!) {
    clearReciboByID(id_recibo: $idRecibo) {
      _id
    }
  }
`;

export const GET_SALDO_RACK = gql`
  query GetSaldosOfRack($rackId: String!) {
    getSaldosOfRack(rackId: $rackId) {
      saldo_ef
      saldo_mp
    }
  }
`;

export const PAGAR_REMITO_MP = gql`
  mutation PagarRemitoMP($idRemito: String!) {
    pagarRemitoMP(id_remito: $idRemito) {
      id
      url
    }
  }
`;
