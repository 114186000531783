// duck.js

export const SHOPPING_ADD = 'SHOPPING_ADD';
export const SHOPPING_REMOVE = 'SHOPPING_REMOVE';
export const SHOPPING_REMOVE_ALL = 'SHOPPING_REMOVE_ALL';

export default function shopping(state = [], action) {
  //console.log('shopping', state, action);
  switch (action.type) {
    case SHOPPING_ADD:
      const dataAdd = action.shopping;
      const findedAdd = state.find((ele) => ele.code === dataAdd.code);
      if (findedAdd) {
        const element = state.find((obj) => obj.code === dataAdd.code);
        element.cantidad = dataAdd.cantidad;
      } else {
        //console.log('add new', dataAdd);
        state.push(dataAdd);
      }
      return Object.assign([], state, {
        shopping: state && state.length > 0 ? state : [],
      });
    case SHOPPING_REMOVE:
      //console.log('remove', action, state);
      const dataRemove = action.shopping;
      //console.log('element', dataRemove);
      if (dataRemove.cantidad === 0) {
        //console.log('code', dataRemove.code);
        //console.log('state', state);
        const finded = state.shopping.filter(
          (ele) => ele.code !== dataRemove.code
        );
        //console.log('finded', finded);
        state = finded;
      } else {
        //console.log('state length', state.length);
        for (let i = 0; i < state.length; i++) {
          //console.log(`state: ${state[i].code} | action: ${dataRemove.code}`);
          if (state[i].code === dataRemove.code) {
            state[i] = dataRemove;
          }
        }
      }
      return Object.assign([], state, {
        shopping: state && state.length > 0 ? state : [],
      });
    case SHOPPING_REMOVE_ALL:
      return Object.assign([], [], {
        shopping: [],
      });
    default:
      return state;
  }
}
