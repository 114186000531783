// duck.js

//types
export const TK_SHOPPING_ADD = "TK_SHOPPING_ADD";
export const TK_SHOPPING_REMOVE = "TK_SHOPPING_REMOVE";
export const TK_SHOPPING_REMOVE_ALL = "TK_SHOPPING_REMOVE_ALL";

export default function tkShopping(state = [], action) {

  switch (action.type) {
    case TK_SHOPPING_ADD:
      //console.log('action', action)
      //console.log('state', state)
      const dataAdd = action.shopping;
      const findedAdd = state.find((ele) => ele.code === dataAdd.code);
      if (findedAdd) {
        const element = state.find((obj) => obj.code === dataAdd.code);
        element.cantidad = dataAdd.cantidad;
      } else {
        state.push(dataAdd);
      }
      return Object.assign([], state, state);

    case TK_SHOPPING_REMOVE:
      const dataRemove = action.shopping;
      if (dataRemove.cantidad === 0) {
        const finded = state.filter((ele) => ele.code !== dataRemove.code);
        state = finded;
      } else {
        for (let i = 0; i < state.length; i++) {
          //console.log(`state: ${state[i].code} | action: ${dataRemove.code}`);
          if (state[i].code === dataRemove.code) {
            state[i] = dataRemove;
          }
        }
      }
      return Object.assign([], state, state);

    case TK_SHOPPING_REMOVE_ALL:
      return new Array();

    default:
      return state;
  }
}
