import React, {useEffect, useState, useCallback} from 'react';

// redux
import {useDispatch} from 'react-redux';
import {DISPONIBLE_CREATE} from '../redux/duck/disponible-duck';

// react-reveal
import Fade from 'react-reveal/Fade';

// redux
import {useSelector} from 'react-redux';

// moment
import moment from 'moment';

// apollo
import {useApolloClient} from '@apollo/react-hooks';

// query
import {FIND_CLIENT_DATA_BY_CODE, FIND_CONSUMIPTIONS_BY_CLIENT} from '../query/clients/client.query';

// chart consumition
import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// component
import ExtraConsumptionsPage from './extra-consumptions.page';

import '../scss/consumption.scss';
import {getStringByType} from "../helpers/users.helper";

const ConsumptionsPage = () => {
    // dispatch
    const dispatch = useDispatch();

    // dispatch
    const code = useSelector((state) => state.client);

    // apollo client
    const client = useApolloClient();

    // state
    const [consumos, setConsumos] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [tipo, setTipo] = useState("");

    const getDataUser = async () => {
        const resultUser = await client.query({
            query: FIND_CLIENT_DATA_BY_CODE,
            variables: {cli_code: code.client},
            fetchPolicy: 'no-cache',
        });
        //console.log('resultUser', resultUser);
        const data = resultUser.data.findClientByClientCode;
        setTipo(data?.tipo);
    }

    const fetchData = useCallback(async () => {


        try {
            setIsLoading(true);
            console.log('user code', code.client);
            const current_date = moment().format('YYYY-MM-DD');
            console.log('current_date', current_date);
            const resultInfo = await client.query({
                query: FIND_CONSUMIPTIONS_BY_CLIENT,
                variables: {
                    user_code: code.client,
                },
                fetchPolicy: 'no-cache',
            });

            const aux = resultInfo.data.findClientConsumptionsInfo;
            guardarConsumidosRedux(aux);
            console.log('consumo', aux);
            setConsumos(aux);
            setIsLoading(false);

            await getDataUser();

        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }, []);

    const guardarConsumidosRedux = (bidones) => {
        const data = {
            '010001':
                bidones[0].diponibles > 0
                    ? bidones[0].diponibles - bidones[0].consumidos
                    : 0, // X20
            '010002':
                bidones[1].diponibles > 0 ? bidones[1].diponibles - bidones[1].consumidos : 0, // X12
        };

        dispatch({
            type: DISPONIBLE_CREATE,
            disponible: data,
        });
    };

    useEffect(() => {
        fetchData();
    }, [code, fetchData]);

    return isLoading || !consumos ? (
        <div className='container'>
            <div className='row'>
                <div className='col d-flex justify-content-center'>
                    <div className='spinner-grow text-primary' role='status'>
                        <span className='sr-only'>Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <Fade>
            {!isLoading && (
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            <h3 className='text-center'>Sus consumos del mes</h3>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <h4 className='text-center'>
                                Abonos: {consumos ? `${consumos[0]?.abonos}` : ''}
                            </h4>
                        </div>
                    </div>

                    {consumos[0]?.abonos === '-' ? (<div className='row'>
                            <div className='col text-center'>
                                <h4> Categoría: { tipo } </h4>
                                <span>{ getStringByType(tipo) }</span>
                            </div>
                        </div>)
                        : ""}


                    {consumos.map((obj, index) => {
                        return obj.diponibles > 0 ? (
                            <div className='row mt-3' key={index}>
                                <div className='col-12 col-md-3'>
                                    {index === 0 && <h3>Bidones X20</h3>}
                                    {index === 1 && <h3>Bidones X12</h3>}
                                    <div id='circular-char'>
                                        <CircularProgressbar
                                            value={obj.porcentaje ? obj.porcentaje : 0}
                                            text={`${obj.porcentaje ? obj.porcentaje : 0}%`}
                                        />
                                    </div>
                                </div>
                                <div id='consumption-resume' className='mt-5 mt-md-0 col-md-9'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <strong>Bidones Consumidos:</strong>
                                            {obj.consumidos &&
                                                ` ${obj.consumidos} de ${obj.diponibles}`}
                                            {!obj.consumidos && `-`}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <strong>Día de visitas:</strong>
                                            {` ${obj.dias}`}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <strong>Tipo: </strong>
                                            {tipo} - {getStringByType(tipo)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        );
                    })}

                    <div className='row mt-5'>
                        <div className='col'>
                            <ExtraConsumptionsPage/>
                        </div>
                    </div>
                </div>
            )}
        </Fade>
    );
};

export default ConsumptionsPage;
