import React from 'react';

// apollo
import { useApolloClient } from '@apollo/react-hooks';

// query
import { CREATE_STATISTIC } from '../query/statistics/statistics.query';

import '../scss/profile.scss';

const ProfileActionPage = () => {
  // apollo client
  const client = useApolloClient();

  const openUrl = async () => {
    const win = window.open(
      'https://api.whatsapp.com/send?phone=5493435031261',
      '_blank'
    );
    win.focus();

    try {
      const cli_code = localStorage.getItem('client_code');
      const variables = {
        type: 'wh_asesor',
        client_id: cli_code,
      };

      const queryStatistics = await client.query({
        query: CREATE_STATISTIC,
        variables: variables,
        fetchPolicy: 'no-cache',
      });
      const result = queryStatistics ? queryStatistics.data.createStatic : null;

      if (result) {
        console.log('success');
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div className='container'>
      <div className='row mt-5'>
        <div className='col col-md-12'>
          <p onClick={openUrl}>
            <img
              src={require('../assets/images/banner_960.jpg')}
              alt={'banner'}
              className='banner d-none d-xl-block'
            />
            <img
              src={require('../assets/images/banner_720.jpg')}
              alt={'banner'}
              className='banner d-none d-md-block d-xl-none'
            />
            <img
              src={require('../assets/images/banner_420.jpg')}
              alt={'banner'}
              className='banner d-block d-md-none'
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfileActionPage;
