const ENV = {
  // TODO: crear archivo separado para configurar el enviroment local
  development: {
    apiUrl: 'http://localhost:7000/graphql',
    wsUrl: 'ws://localhost:7000/graphql',
  },
  staging: {
    apiUrl: 'http://192.168.0.241:4000/graphql',
    wsUrl: 'wss://192.168.0.241:4000/graphql',
  },
  production: {
    apiUrl: 'https://micuenta.aguanuestra.com.ar:4000/graphql',
    wsUrl: 'wss://micuenta.aguanuestra.com.ar:4000/graphql',
  },
};

function getEnvVars(env = '') {
  if (env === null || env === undefined || env === '') return ENV.development;
  if (env.indexOf('development') !== -1) return ENV.development;
  if (env.indexOf('staging') !== -1) return ENV.staging;
  if (env.indexOf('production') !== -1) return ENV.production;
  if (env.indexOf('default') !== -1) return ENV.development;

  return env.dev;
}

const env = process.env.NODE_ENV;
console.log(env);
console.log('node_env', env);

export default getEnvVars('production');
