// users.js

export const USER_CREATE = "USER_CREATE";
export const USER_DELETE = "USER_DELETE";

export default function user(state = {}, action) {
  //console.log("user", state, action);
  switch (action.type) {
    case USER_CREATE:
      return Object.assign({}, state, {
        user: action.user,
      });
    case USER_DELETE:
      return Object.assign({}, state, {
        user: null,
      });
    default:
      return state;
  }
}
