
export function zfill(number, width) {
    const numberOutput = Math.abs(number); /* Valor absoluto del número */
    const length = number.length; /* Largo del número */
    const zero = '0'; /* String de cero */

    if (width <= length) {
        if (number < 0) {
             return ('-' + numberOutput);
        } else {
             return numberOutput;
        }
    } else {
        if (number < 0) {
            return ('-' + (zero.repeat(width - length)) + numberOutput);
        } else {
            return ((zero.repeat(width - length)) + numberOutput);
        }
    }
}

export function zfillString(str, width) {
    const numberOutput = str; /* Valor absoluto del número */
    const length = str.length; /* Largo del número */
    const zero = '0'; /* String de cero */

    if (width <= length) {
        if (!str) {
             return ('-' + numberOutput);
        } else {
             return numberOutput;
        }
    } else {
        if (!str) {
            return ('-' + (zero.repeat(width - length)) + numberOutput);
        } else {
            return ((zero.repeat(width - length)) + numberOutput);
        }
    }
}

export function emptyFill(value, width) {
    const length = value.length; /* Largo del número */
    const zero = ' '; /* String de cero */

    if (width <= length) {
        if (length < 0) {
             return ('-' + value);
        } else {
             return value;
        }
    } else {
        if (length < 0) {
            return ('-' + (zero.repeat(width - length)) + value);
        } else {
            return ((zero.repeat(width - length)) + value);
        }
    }
}
