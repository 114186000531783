import gql from "graphql-tag";

export const LOGIN = gql`
  mutation login($name: String!, $password: String!) {
    login(name: $name, password: $password)
  }
`;

export const FLOGIN =  gql`
    mutation Forcelogin($name: String!, $password: String!) {
        Forcelogin(name: $name, password: $password)
    }
`;

export const GET_USER_CODE = gql`
  query getClientCodeByToken($token: String!) {
    getClientCodeByToken(token: $token)
  }
`;

export const GET_CLIENTID =gql`
    query GetClientIdByToken($token: String!) {
        getClientIdByToken(token: $token)
  }
`;
