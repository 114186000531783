import React, { useState, useEffect } from "react";

// apollo
import { useApolloClient } from "@apollo/react-hooks";

// redux functions
import { TK_SHOPPING_ADD } from "../../redux/duck/tk.shopping.duck";
//import { TK_SHOPPING_REMOVE } from "../../redux/duck/tk.shopping.duck";

// redux actions
import { TK_SHOPPING_REMOVE_ALL } from "../../redux/duck/tk.shopping.duck";
//import { DISPONIBLE_CREATE } from "../../redux/duck/disponible-duck";

// query
import {
  ADD_RECIBO,
  ADD_REMITO,
  ADD_RECIBO_MP,
  ADD_REP_RECIBO,
} from "../../query/takeaway/articles.tk.query";
import {
  CLEAR_RECIBO,
  GET_SALDO_MP_BY_RACK,
} from "../../query/takeaway/racks.tk.query";
//import { FIND_CONSUMIPTIONS_BY_CLIENT } from '../../query/clients/client.query';

// redux
import { useDispatch, useSelector } from "react-redux";

// react-reveal
import Fade from "react-reveal/Fade";

// swal
import Swal from "sweetalert2";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faShoppingCart,
  faTrash,
  faTruck,
  faCashRegister,
} from "@fortawesome/free-solid-svg-icons";

// react-rounter-dom
import { useHistory } from "react-router-dom";

import moment from "moment";
import mercadopago from "mercadopago";

// components

const OrdersPageTakeaway = () => {
  // dispatch
  const dispatch = useDispatch();
  const shopping = useSelector((state) => state.tkShopping);
  //const disponible = useSelector((state) => state.disponible);
  const tkUser = useSelector((state) => state.tkUser);
  const tkRack = useSelector((state) => state.tkRack);
  // dispatch
  //const code = useSelector((state) => state.client);
  const [disabled, setDisabled] = useState(false);
  //const [isLoading, setIsLoading] = useState(false);
  const [userType, setUserType] = useState("");
  const [stockEnvaVacios, setStockEnvaVacios] = useState(0);
  const [stockBidones, setStockBidones] = useState(0);
  const [captionBtnFinCompra, setCaptionBtnFinCompra] =
    useState("FINALIZAR COMPRA");
  const [error, setError] = useState("");
  const [saldoMP, setSaldoMP] = useState(0);
  const [metodoPago, setMetodoPago] = useState("ef");
  const [totals, setTotals] = useState({
    totPrice: 0,
    cost: 0,
    saldoMP: 0,
    toPay: 0,
    cant: 0,
  });
  const [envVacios, setEnvVacios] = useState(0);
  const [envaAguaShopping, setEnvaAguaShopping] = useState(0);
  const [bidones, setBidones] = useState(0);
  const [envaDebe, setEnvaDebe] = useState(0);

  let envAguaRotos = 0;
  const client = useApolloClient();
  const history = useHistory();

  /*   const guardarConsumidosRedux = (bidones) => {
    const data = {
      '010001':
        bidones[0].diponibles > 0
          ? bidones[0].diponibles - bidones[0].consumidos
          : 0, // X20
      '010002':
        bidones[1].diponibles > 0
          ? bidones[1].diponibles - bidones[1].consumidos
          : 0, // X12
    };

    dispatch({
      type: DISPONIBLE_CREATE,
      disponible: data,
    });
  };
 */
  useEffect(() => {
    if (tkUser?.tipo) setUserType(tkUser.tipo);
    else setUserType("");
    const vacios = localStorage.getItem("stockEnvaVacios");
    const bidones = localStorage.getItem("stockBidones");
    setStockEnvaVacios(vacios);
    setStockBidones(bidones);
    const recID = localStorage.getItem("reciboPendienteMP");
    if (recID) {
      borrarRecibo(recID);
      localStorage.setItem("reciboPendienteMP", "");
    }
    if (tkUser?.tipo === "Repartidor")
      calcSaldoMP().then((res) => {
        setSaldoMP(res);
        setupTotals(res);
      });
  }, []);

  useEffect(() => {
    if (shopping.length > 0) setupTotals(saldoMP);
    else history.goBack();

    //CALCULA ENVASES DEL SHOPPING
    const envaStk = localStorage.getItem("stockEnvaVacios");
    setStockEnvaVacios(envaStk);
    const calcBidones = calcCant("010001") + calcCant("010002");
    setBidones(calcBidones);
    const calcEnva = calcCant("010008");
    setEnvaAguaShopping(calcEnva);
    const envaDeb = envaseDebe();
    setEnvaDebe(envaDeb);
    let res = envaStk;
    if (!envaDeb)
      if (calcBidones)
        //si todo normal
        res = envaStk - calcEnva + calcBidones;
      //se resta a los envaStk los envases vendidos + los que debe entregar el cliente por comprar bidones
      else res = envaStk - calcEnva; //si debe envases porque compra bidones
    setEnvVacios(res);
    //FIN CALCULA ENVACES SHOPPING
  }, [shopping]);

  const setupTotals = (saldoMP) => {
    const tm = totalMoney();
    if (tkUser?.tipo === "Repartidor") {
      setTotals({
        cost: tm.totalCost,
        saldoMP: saldoMP,
        toPay: tm.totalCost - saldoMP,
        cant: tm.cantidad,
      });
    } else {
      setTotals({
        price: tm.totalPrice,
        cant: tm.cantidad,
      });
    }
  };

  useEffect(() => {
    if (totals.toPay <= 0) setMetodoPago("ef");
  }, [totals.toPay]);

  const calcSaldoMP = async () => {
    const resultSaldoMP = await client.query({
      query: GET_SALDO_MP_BY_RACK,
      variables: { rack: tkRack?.id },
      fetchPolicy: "no-cache",
    });
    const s = resultSaldoMP.data.getSaldoFavorRack;
    if (s) return s;
    else return 0;
  };

  const vaciarLista = (trash) => {
    dispatch({
      type: TK_SHOPPING_REMOVE_ALL,
      shopping: null,
    });
    if (trash)
      Swal.fire({
        //position: 'top-end',
        icon: "success",
        title: "Se vació la lista de productos!",
        showConfirmButton: false,
        timer: 1500,
      });
  };

  const armarMensaje = (articu) => {
    let msj = {};
    let elLos = totals.cant > 1 ? "los" : "el";
    let s = totals.cant > 1 ? "s" : "";
    msj.titulo =
      userType === "Playero" ? "Venta realizada!" : "Muchas gracias!";
    let verboEnvase = userType === "Playero" ? "pedí al" : "entregá al";
    let recibirEntregar = userType === "Playero" ? "entregar" : "recibir";
    let verboProductos = userType === "Playero" ? "entregá al" : "pedí al";
    let sujeto =
      userType === "Playero"
        ? "cliente"
        : `responsable del TakeAway: <b>${tkRack.responsables}</b><p></p>`;
    let predicado = userType === "Playero" ? "" : ", con este ticket";
    //armo descripcion del mensaje
    if (envaDebe) {
      msj.descripcion = `<p> Por favor ${verboEnvase} ${sujeto} <b class='text-danger'> ${envaDebe} envase${s}</b>${predicado}`;
      msj.descripcion += ` </br></br> para ${recibirEntregar} ${elLos} producto${s}: </p>`;
    } else
      msj.descripcion = `<p>Por favor ${verboProductos} ${sujeto}${predicado}: </p>`;
    msj.descripcion += "<div class='remito aceptado'>";
    articu.map((x) => {
      if (x.cantidad && x.price)
        msj.descripcion += `<p class="filaSwal"><b>${x.cantidad}</b> ${x.description}</p>`;
    });
    msj.descripcion += "<div>";

    return msj;
  };

  const borrarRecibo = async (rec) => {
    //const ra = localStorage.getItem("recargarArts");
    try {
      const result = await client.query({
        query: CLEAR_RECIBO,
        variables: { idRecibo: rec },
        fetchPolicy: "no-cache",
      });
      const id = result.data.clearReciboByID;
      if (id) {
        return true;
      }
    } catch (err) {
      setError("¡ Recibo no encontrado !");
      console.log(error, err);
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const finalizarVenta = async () => {
    //deberia contrlarse que haya stock antes de finalizar la venta
    //porque el carrito podría tener productos de otro dia
    setCaptionBtnFinCompra("COMPRA EN PROCESO ...");

    if (shopping.length === 0) return;
    setDisabled(true);
    const articu = [];
    for (const element of shopping) {
      const total = subTotalMoney(element);
      let mod = modifierStock(element.code, element.cantidad);
      if (element.code === "010008" && mod > stockEnvaVacios)
        mod = stockEnvaVacios;
      articu.push({
        artId: element.artId,
        code: element.code,
        description: element.description,
        cantidad: element.cantidad,
        modifierStock: -mod,
        price: element.price,
        price_cost: element.price_cost,
        subTotalPrice: total.price,
        subTotalCost: total.cost,
      });
    }
    //si no hay envases en el redux y debe envases, lo pusheo en articu
    //articu va a la capsula usada para guardar en recibos
    const hayEnv = shopping.find((ele) => ele.code === "010008");
    if (!hayEnv && envaDebe > 0) {
      const artEnvaAgua = JSON.parse(localStorage.getItem("artEnvaAgua"));
      articu.push({
        artId: artEnvaAgua.id,
        code: artEnvaAgua.code,
        description: artEnvaAgua.descripcion,
        cantidad: envaDebe,
        modifierStock: envaDebe,
        price: "",
        price_cost: "",
        subTotalPrice: 0,
        subTotalCost: 0,
      });
    }
    const obj = {
      id_rack: tkRack.id,
      id_user: tkUser.id,
      monto_venta: totals.price,
      costo_venta: totals.cost,
      metodo: userType === "Playero" ? "EFECTIVO" : "MP",
      detalle: articu,
    };
    try {
      const recibo = await client.query({
        query: userType === "Playero" ? ADD_RECIBO : ADD_RECIBO_MP,
        variables: { obj: obj },
        fetchPolicy: "no-cache",
      });

      if (recibo.data.addTkRecibo || recibo.data.addTkReciboMp) {
        if (recibo.data.addTkReciboMp)
          localStorage.setItem(
            "reciboPendienteMP",
            recibo.data.addTkReciboMp.id
          );
        //localStorage.setItem("recargarArts", true); //venta efectiva, hay que recargar arts
        //localStorage.setItem("fpol", "no-cache"); //venta efectiva, hay que recargar arts
        if (userType === "Playero") {
          const msj = armarMensaje(articu);
          Swal.fire({
            title: msj.titulo,
            html: msj.descripcion,
            icon: "success",
            padding: "5px",
          });
          vaciarLista(false);
        } else window.location.href = recibo.data.addTkReciboMp.url;
      } else {
        Swal.fire(
          "Error de registro!",
          "Query realizada pero su devolución es vacía.",
          "error"
        );
      }
      setDisabled(true);
    } catch (error) {
      setDisabled(false);
      console.log("error", error);
      Swal.fire(
        "Error de Query!",
        "Reintentar más tarde o comunicarse con la empresa.",
        "error"
      );
    }
  };

  /*   const calcTotPagar = (total) => {
    const resta = total - saldoMP;
    return resta >= 0 ? resta : 0;
  };
 */
  const mostrarDetalleRemito = (remito, saldoAPagar) => {
    let line = "";
    let estado = "";
    let tituPagado = "";
    let tituTotal = "";
    let msj = "";
    switch (remito.estado) {
      case "PAGADO":
        estado = "pagado";
        tituTotal = "Total pagado:";
        msj = `<br/>Solicitá <span class="text-danger font-weight-bold">${calcBidonesShopping()} envases</span> antes de entregar la mercadería a:<br><br> Responsables: <br><b>
        ${tkRack.responsables}</b>`;
        break;
      case "PENDIENTE":
        estado = "pendiente";
        tituPagado = saldoMP
          ? `<hr><h5 class="text-right mr-1">Total $ ${remito.costo_venta} <hr></h5><h6 class="text-right mr-1">Pagado c/ saldo MP $ ${saldoMP} </h6>`
          : "";
        tituTotal = "Total a pagar c/ MP:";
        break;
      case "CANCELADO":
        estado = "cancelado";
        tituTotal = "Total";
        break;
      default:
        break;
    }
    const fechaRemito = moment(remito.createdAt).format("DD-MM-YYYY HH:mm");
    const head = `<div class='comprobante ${estado}'>`;
    let output = "<h4>Remito</h4><h5>" + fechaRemito + " hs</h5><hr>";
    const sticker = `<div class='comprobanteSticker ${estado}'>${remito.estado}</div>`;
    remito.detalle.map((x) => {
      if (x.cantidad !== 0) {
        line =
          `<b>${x.cantidad}</b> ${x.description} [$ ${x.price_cost}]` +
          `<p class="subTotal">$ ${x.subTotalCost}</p>`;
        return (output += `<p class="filaSwal">${line}</p>`);
      }
    });
    output += `${tituPagado}<hr><h5 class="text-right mr-1">${tituTotal} $ ${saldoAPagar}</h5> ${msj}</div>`;
    Swal.fire({
      html: sticker + head + output,
      padding: "25px 0 0 0",
    });
  };

  const finalizarEntrega = async () => {
    if (shopping.length === 0) return;
    setDisabled(true);
    let saldoAPagar = totals.cost;
    let estado = "PENDIENTE";

    if (metodoPago === "mp") saldoAPagar = totals.cost - saldoMP;
    const articu = [];
    for (const element of shopping) {
      const total = subTotalMoney(element);
      const mod = modifierStockRepo(element.code, element.cantidad);
      articu.push({
        artId: element.artId,
        code: element.code,
        description: element.description,
        cantidad: element.cantidad,
        modifierStock: mod,
        price_cost: element.price_cost,
        subTotalCost: total.cost,
      });
    }
    const obj = {
      id_rack: tkRack.id,
      id_repartidor: tkUser.id,
      costo_venta: totals.cost,
      sinSaldar: totals.cost,
      estado: estado,
      detalle: articu,
    };
    try {
      const remito = await client.query({
        query: ADD_REMITO,
        variables: { obj: obj },
        fetchPolicy: "no-cache",
      });
      const remi = remito.data.addTkRemito;
      if (remi) {
        if (metodoPago !== "mp") {
          let montoEfectivo = "";
          if (metodoPago !== "mp")
            montoEfectivo = saldoMP >= totals.cost ? 0 : totals.cost - saldoMP;
          else montoEfectivo = 0;

          const cap = {
            id_rack: tkRack.id,
            remitos: [remi._id],
            saldoMP_aFavor: saldoMP,
            monto_efectivo: montoEfectivo,
          };
          const remiAfectado = await client.query({
            query: ADD_REP_RECIBO,
            variables: { repRecibo: cap },
            fetchPolicy: "no-cache",
          });
          if (remiAfectado.data.addRepRecibo)
            remi.estado = metodoPago !== "mp" ? "PAGADO" : remi.estado;
        }
        vaciarLista(false);
        mostrarDetalleRemito(remi, saldoAPagar);
        localStorage.setItem("graboRemito", true);
      } else {
        Swal.fire(
          "Error de registro!",
          "Query sin error. Devolución vacía. Por favor reintentar más tarde o comunicarse con la empresa.",
          "error"
        );
      }
      setDisabled(true);
    } catch (error) {
      setDisabled(false);
      console.log("error", error);
      Swal.fire(
        "Error de Query!",
        "Reintentar más tarde o comunicarse con la empresa.",
        "error"
      );
    }
  };

  const sinServicio = () => {
    const word = "TESTING";
    const negocio = tkRack.negocio.toUpperCase();
    const isTesting = negocio.includes(word) ? true : false;
    if (isTesting) finalizarVenta();
    else
      Swal.fire(
        "Oops!",
        "<h5>Fuera de servicio</h5> Por ahora debés coordinar tu compra con el playero! Muchas gracias!",
        "error"
      );
  };

  const calcBidonesShopping = () => {
    const codeB12 = "010002";
    const codeB20 = "010001";
    const b12 = shopping.find((ele) => ele.code === codeB12)?.cantidad | 0;
    const b20 = shopping.find((ele) => ele.code === codeB20)?.cantidad | 0;
    return b12 + b20;
  };

  const envaDebeSt = () => {
    const ret = calcBidonesShopping() - stockEnvaVacios;
    return ret < 0 ? 0 : ret;
  };

  const isBidones = (code, cumulo) => {
    if (code === "010001" || code === "010002") {
      if (userType === "Repartidor" && envaDebeSt())
        cargaShopping(envaAguaShopping, "010008", cumulo);
      //aumenta o disminuye envases
      else if (userType !== "Repartidor" && envVacios <= 0)
        cargaShopping(envaAguaShopping, "010008", -1); //aumenta o disminuye envases
    }
  };

  const minus = (cant, code) => {
    isBidones(code, -1); // si carga Bidones, agrega Envases
    cargaShopping(cant, code, -1);
  };
  const add = (cant, code) => {
    cargaShopping(cant, code, 1);
    isBidones(code, 1); // si carga Bidones, agrega Envases
  };
  const cargaShopping = (cant, code, cumulo) => {
    const aux = parseInt(cant) + cumulo;
    const article = shopping.find((ele) => ele.code === code);
    dispatch({
      type: TK_SHOPPING_ADD,
      shopping: {
        artId: article.id,
        code: code,
        cantidad: aux,
        lista: article.lista_id,
        price: article.price,
        price_cost: article.price_cost,
        description: article.description,
        stock: article.stock,
        stock_min: article.stock_min,
        image: article.image,
      },
    });
  };

  const isDecimal = (n) => {
    const nd = n.toFixed(2);
    const na = Math.abs(nd);
    const ni = Math.trunc(na);
    return na - ni ? nd : n;
  };

  const rows = () => {
    return shopping.map((element) => {
      const STMoney = subTotalMoney(element);
      const total = userType === "Repartidor" ? STMoney.cost : STMoney.price;
      return (
        <tr key={element.code}>
          <td className="p-0 align-middle">
            <img
              className={`card-img-top2 ${
                element.code === "010008" ? "envase" : ""
              }`}
              src={`data:image/png;base64,${element.image}`}
            />
          </td>
          <td className="productDetail p-0 align-middle">
            {element.description} ...
            <span className="price">
              $ {userType === "Repartidor" ? element.price_cost : element.price}
            </span>
            {userType !== "Repartidor" && element.code !== "010008" && (
              <span
                className={`stock ${
                  !(element.stock - element.cantidad)
                    ? "agotado"
                    : element.stock - element.cantidad <= element.stock_min
                    ? "minimo"
                    : "disponible"
                } `}
              >
                {" "}
                [Hay {element.stock - element.cantidad}]
              </span>
            )}
            {/*  ENVASES  (playero o consumidor) */}
            {userType !== "Repartidor" && element.code === "010008" && (
              <span
                className={`stock ${
                  !calcEnvVacios()
                    ? "agotado"
                    : calcEnvVacios() <= element.stock_min
                    ? "minimo"
                    : "disponible"
                } `}
              >
                {" "}
                [Hay {stockEnvaVacios > 0 ? calcEnvVacios() : "0"}]
              </span>
            )}
          </td>
          <td className="botoneraTd align-middle">
            <div className="botonera inactiva d-inline-flex flex-wrap justify-content-center">
              {/*   BTN MINUS (todos menos envases)          */}
              {element.code !== "010008" && (
                <button
                  className={`btnMinusAdd ${
                    element.cantidad === 1
                      ? /* "del" */ ""
                      : element.cantidad === 0
                      ? "disabled"
                      : ""
                  }`}
                  disabled={element.cantidad === 0}
                  onClick={() => minus(element.cantidad, element.code)}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </button>
              )}

              {/*   BTN MINUS (todos menos envases)          */}
              {element.code === "010008" && (
                <button
                  className={`btnMinusAdd ${
                    element.cantidad === 1
                      ? /* "del" */ ""
                      : element.cantidad === 0 ||
                        (userType === "Repartidor" &&
                          element.cantidad <= envaDebeSt())
                      ? "disabled"
                      : ""
                  }`}
                  disabled={
                    element.cantidad === 0 ||
                    (userType === "Repartidor" &&
                      element.cantidad <= envaDebeSt())
                  }
                  onClick={() => minus(element.cantidad, element.code)}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </button>
              )}

              {/*  CANTIDAD  */}
              <span className={`cantidad ${userType} mx-2 align-self-center`}>
                {element.cantidad}
              </span>
              {userType !== "Repartidor" && element.code !== "010008" && (
                <button
                  className={`btnMinusAdd ${
                    element.cantidad >= element.stock ? "disabled" : ""
                  }`}
                  disabled={element.cantidad >= element.stock}
                  onClick={() => add(element.cantidad, element.code)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              )}

              {/* BTN ADD  (playero o consumidor) */}
              {userType !== "Repartidor" && element.code === "010008" && (
                <button
                  className={`btnMinusAdd ${
                    (calcEnvVacios() <= 0 || calcEnvBidones() <= 0) && !envaDebe
                      ? "disabled"
                      : ""
                  }`}
                  disabled={
                    (calcEnvVacios() <= 0 || calcEnvBidones() <= 0) &&
                    envaDebe <= 0
                  }
                  onClick={() => add(element.cantidad, element.code)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              )}

              {userType === "Repartidor" && (
                <button
                  className="btnMinusAdd"
                  onClick={() => add(element.cantidad, element.code)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              )}
            </div>
          </td>
          <td className="subtotalTd align-middle">{`$ ${total}`}</td>
        </tr>
      );
    });
  };

  const subTotalMoney = (element) => {
    // if (disponible[element.code]) {
    //   if (disponible[element.code] > element.cantidad) {
    //     total += 0;
    //   } else if (disponible[element.code] < element.cantidad) {
    //     total += cantidadDisponible * parseFloat(element.price) * -1;
    //   } else {
    //     total = 0;
    //   }
    // } else {

    return {
      price: element.cantidad * parseFloat(element.price),
      cost: element.cantidad * parseFloat(element.price_cost),
    };
  };

  const totalMoney = () => {
    let ta = 0;
    let tp = 0;
    let tc = 0;
    for (const order of shopping) {
      const subTotal = subTotalMoney(order);
      tp += subTotal.price;
      tc += subTotal.cost;
      ta += order.cantidad;
    }
    return {
      cantidad: ta,
      totalPrice: isDecimal(tp),
      totalCost: isDecimal(tc),
    };
  };

  const calcCant = (code) => {
    return shopping.find((ele) => ele.code === code)?.cantidad | 0;
  };

  const envaseDebe = () => {
    const ret = calcCant("010001") + calcCant("010002") - calcCant("010008");
    return ret > 0 ? ret : 0;
  };

  const calcEnvVacios = () => {
    if (!envaDebe)
      if (bidones) return stockEnvaVacios - envaAguaShopping + bidones;
      else return stockEnvaVacios - envaAguaShopping;
    else return stockEnvaVacios;
  };

  const calcEnvBidones = () => {
    if (envaDebe > 0)
      return stockBidones - envaAguaShopping; //si debe, resta de los bidones
    else return stockBidones;
  };

  const calcEnvaAguaEntrega = () => {
    return bidones > stockEnvaVacios ? stockEnvaVacios : bidones;
  };

  const envaRotos = (codEnv) => {
    const envCarrito = calcCant(codEnv);
    const debe = envaDebeSt();
    let rotos = 0;
    if (envCarrito > debe) rotos = envCarrito - debe;
    envAguaRotos = rotos;
    return rotos;
  };

  const modifierStock = (code, cant) => {
    if (code === "010008") {
      const envaRest = bidones - cant;
      return -envaRest;
    } else return cant;
  };

  const modifierStockRepo = (code, cant) => {
    if (code === "010008") {
      const envaRest = stockEnvaVacios - bidones;
      return envaRest < 0 ? -stockEnvaVacios : -bidones;
    } else return cant;
  };

  const cambiarMetodoPago = (e) => {
    if (e.target.value === "mp" && e.target.checked) setMetodoPago("mp");
    else setMetodoPago("ef");
  };

  return (
    <Fade>
      <div id="carritoDetail" className="container bg-white my-5 pr-3">
        <div className="header1 row">
          {shopping && shopping.length > 0 && (
            <div className="header2 col">
              {/* <h3 className="text-secondary">Carrito de Compra</h3> */}
              <Fade>
                <div
                  id="headerCarritoDetail"
                  className="col mx-0 d-flex justify-content-between"
                >
                  <div className="title flex-fill align-items-end">
                    <span>
                      {`${
                        userType === "Repartidor"
                          ? "A ENTREGAR"
                          : userType === "Playero"
                          ? "Productos"
                          : "Carrito"
                      }`}{" "}
                      ({totals.cant})
                    </span>
                  </div>
                  <div className="flex-fill d-flex justify-content-end">
                    <div>
                      {userType === "Repartidor" && (
                        <FontAwesomeIcon
                          className={"shopping-car"}
                          icon={faTruck}
                          size={"2x"}
                          color="#000000"
                        />
                      )}
                      {userType === "Playero" && (
                        <FontAwesomeIcon
                          className={"shopping-car"}
                          icon={faCashRegister}
                          size={"2x"}
                          color="#04aa0c"
                        />
                      )}
                      {!userType && (
                        <FontAwesomeIcon
                          className={"shopping-car"}
                          icon={faShoppingCart}
                          size={"2x"}
                          color="#007bff"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Fade>

              <table className="table my-3">
                <tbody>
                  {rows()}
                  {/* CONSUMIDOR DEBE ENVASES*/}
                  {envaDebe > 0 &&
                    userType !== "Playero" &&
                    userType !== "Repartidor" && (
                      <Fade left>
                        <tr>
                          <td className="debeEnvases" colSpan="4">
                            DEBÉS{" "}
                            <b>
                              {envaDebe} ENVASE
                              {envaDebe > 1 ? "S" : ""}
                            </b>
                          </td>
                        </tr>
                      </Fade>
                    )}
                  {/* PLAYERO/VENDEDOR DEBE ENVASES*/}
                  {envaDebe > 0 && userType === "Playero" && (
                    <Fade left>
                      <tr>
                        <td className="debeEnvases" colSpan="4">
                          RECIBÍS{" "}
                          <b>
                            {envaDebe} ENVASE
                            {envaDebe > 1 ? "S" : ""}
                          </b>
                        </td>
                      </tr>
                    </Fade>
                  )}

                  {/*             REPOSITOR           */}
                  {/* (REPOSITOR) PLAYERO DEBE ENVASES*/}
                  {calcBidonesShopping() > 0 && userType === "Repartidor" && (
                    <Fade left>
                      <tr>
                        <td className="debeEnvases" colSpan="4">
                          DEBE ENTREGAR{" "}
                          <b>
                            {calcEnvaAguaEntrega()} ENVASE
                            {calcEnvaAguaEntrega() > 1 ? "S" : ""}
                          </b>
                        </td>
                      </tr>
                    </Fade>
                  )}
                  {/*          PLAYERO ABONA DIFERENCIA DE ENVASES, MAS ROTOS APARTE      */}
                  {userType === "Repartidor" &&
                    (envaDebeSt() > 0 || envaRotos("010008") > 0) &&
                    calcBidonesShopping() > 0 && (
                      <Fade left>
                        <tr>
                          <td className="debeEnvases" colSpan="4">
                            <span>Y ABONAR </span>
                            {envaDebeSt() > 0 && (
                              <span>
                                <b>
                                  {envaDebeSt()} FALTANTE
                                  {envaDebeSt() > 1 ? "S" : ""}
                                </b>
                              </span>
                            )}{" "}
                            {envaDebeSt() > 0 && envaRotos("010008") > 0 && (
                              <span> Y </span>
                            )}
                            {envaRotos("010008") > 0 && (
                              <span className="debeEnvases" colSpan="4">
                                <b>
                                  {envAguaRotos} PERDIDO
                                  {envAguaRotos > 1 ? "S" : ""}
                                </b>
                              </span>
                            )}
                          </td>
                        </tr>
                      </Fade>
                    )}

                  {userType === "Repartidor" && saldoMP > 0 && (
                    <>
                      <tr className="total text-secondary">
                        <td className="px-3" colSpan="2">
                          TOTAL
                        </td>
                        <td className="totalArticles font-weight-bold">
                          {totals.cant}
                        </td>
                        <td className="p-0 align-middle">
                          <strong>$ {totals.cost}</strong>
                        </td>
                      </tr>

                      <tr className="total text-success">
                        <td className="px-3" colSpan="2">
                          SALDO MERCADO PAGO
                        </td>
                        <td className="totalArticles font-weight-bold">{""}</td>
                        <td className="p-0 align-middle">
                          <strong>$ {totals.saldoMP}</strong>
                        </td>
                      </tr>
                    </>
                  )}

                  <tr className="total">
                    <td className="px-3" colSpan="2">
                      {userType === "Repartidor" && saldoMP > 0
                        ? "SALDO A COBRAR EFECTIVO"
                        : "TOTAL"}
                    </td>
                    <td className="totalArticles font-weight-bold">
                      {userType === "Playero" ||
                      (userType === "Repartidor" && saldoMP <= 0)
                        ? totals.cant
                        : ""}
                    </td>
                    <td className="p-0 align-middle">
                      <strong>
                        ${" "}
                        {userType === "Repartidor"
                          ? totals.toPay < 0
                            ? 0
                            : totals.toPay
                          : totals.price}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              {!userType && (
                <div className="ml-3 mb-3 text-muted">
                  * Finalizada tu Compra, mostrá el ticket-pago al Encargado
                  para recibir la mercadería
                </div>
              )}

              {userType === "Repartidor" && (
                <>
                  <div className="ml-3 mb-3 text-muted">
                    * Se generará un pago. Asegurate de recibir el dinero antes
                    de presionar el siguiente boton.
                  </div>
                </>
              )}

              {userType === "Playero" && (
                <div className="ml-3 mb-3 text-muted">
                  <span>* Productos a entregar al cliente</span>
                </div>
              )}
            </div>
          )}
          {/*           {shopping.length === 0 && (
            <div className="col">
              <h4 className="text-muted my-3">
                Se vació la lista de productos!
              </h4>
              <button
                onClick={() => history.goBack()}
                className="btn btn-primary"
              >
                Volver
              </button>
            </div>
          )}
 */}{" "}
        </div>
        {shopping.length !== 0 && (
          <>
            <div className="row mt-1 justify-content-left">
              <div className="col-auto">
                {userType === "Repartidor" && (
                  <>
                    {totals.toPay > 0 && (
                      <>
                        <div
                          className={` ml-2 ${
                            metodoPago === "mp" ? "bg-secondary text-white" : ""
                          }`}
                        >
                          <div className="ml-1">
                            <input
                              type="checkbox"
                              name="mp"
                              value="mp"
                              checked={metodoPago === "mp" ? true : false}
                              onChange={(e) => cambiarMetodoPago(e)}
                            />{" "}
                            {"  "}
                            Cobrar con Mercado Pago
                          </div>
                        </div>
                        <div className="mb-4 mt-2 text-muted">
                          {metodoPago === "mp"
                            ? "* El comerciante deberá ir al Remito y abonar!"
                            : ""}
                        </div>
                      </>
                    )}
                    <button
                      type="button"
                      className="botonFinalizar repartidor btn mr-2"
                      onClick={finalizarEntrega}
                      disabled={disabled || !totals.cost}
                    >
                      {metodoPago === "mp"
                        ? "GENERAR PENDIENTE"
                        : "COBRAR Y ENTREGAR"}
                    </button>
                  </>
                )}
                {userType === "Playero" && (
                  <button
                    type="button"
                    className="botonFinalizar playero btn mr-2"
                    onClick={finalizarVenta}
                    disabled={disabled || !totals.price}
                  >
                    FINALIZAR VENTA
                  </button>
                )}
                {!userType && (
                  <button
                    type="button"
                    className="botonFinalizar btn mr-2"
                    //onClick={finalizarVenta} //descomentar cuando el shopping del consumidor esté en produccion
                    onClick={sinServicio}
                    disabled={disabled || !totals.price}
                  >
                    {captionBtnFinCompra}
                  </button>
                )}

                <button
                  type="button"
                  className="btn btn-outline-secondary mr-2"
                  onClick={history.goBack}
                  disabled={disabled}
                >
                  Volver
                </button>

                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => vaciarLista(true)}
                  disabled={disabled}
                >
                  <FontAwesomeIcon
                    className={""}
                    icon={faTrash}
                    size={"1x"}
                    color="#grey"
                    transform="shrink-2"
                  />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </Fade>
  );
};

export default OrdersPageTakeaway;
