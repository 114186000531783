import React, { useState, useCallback, useEffect } from "react";
import {
  GET_REMITOS_BY_RACK,
  UPDATE_ACEPTA_REMITO,
  GET_SALDO_MP_BY_RACK,
  PAGAR_REMITO_MP,
  CHANGE_REMITOS,
} from "../../query/takeaway/racks.tk.query";
import { useSelector } from "react-redux";
import { useApolloClient } from "@apollo/react-hooks";
import moment from "moment";
import Swal from "sweetalert2";
import { CANCEL_REMITO } from "../../query/takeaway/articles.tk.query";

function TkRemitos() {
  const tkRack = useSelector((state) => state.tkRack);
  const tkUser = useSelector((state) => state.tkUser);

  const client = useApolloClient();

  const [remitos, setRemitos] = useState([]);
  const [saldoMP, setSaldoMP] = useState(0);
  //const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [pagoMPEnProceso, setPagoMPEnProceso] = useState(false);

  useEffect(() => {
    let sus = null;
    if (remitos.length <= 0) fetchData().then((rems) => (sus = suscri(rems)));
    return () => sus?.unsubscribe();
  }, []);

  const suscri = (remits) => {
    return client
      .subscribe({
        query: CHANGE_REMITOS,
        variables: { idRack: tkRack.id },
        fetchPolicy: "no-cache",
      })
      .subscribe((result) => {
        const remi = result.data.changeRemitos;

        const rid = remits
          .map((r) => {
            return r.id;
          })
          .indexOf(remi._id);
        if (rid < 0) {
          //agregamos
          const r = [
            {
              id: remi._id,
              fecha: remi.createdAt,
              monto: remi.costo_venta,
              sinSaldar: remi.sinSaldar,
              estado: remi.estado,
              repartidor:
                remi.id_repartidor?.nombre + " " + remi.id_repartidor?.apellido,
              detalle: remi.detalle,
              pagadoMp: remi.pagadoMp,
            },
            ...remits,
          ];
          setRemitos(r);
          remits = r;
        } //cambiar
        else {
          const r = remits.map((re) => {
            if (re.id === remi._id) {
              return {
                id: remi._id,
                fecha: remi.createdAt,
                monto: remi.costo_venta,
                sinSaldar: remi.sinSaldar,
                estado: remi.estado,
                repartidor:
                  remi.id_repartidor?.nombre +
                  " " +
                  remi.id_repartidor?.apellido,
                detalle: remi.detalle,
                pagadoMp: remi.pagadoMp,
              };
            } else return re;
          });
          remits = r;
          setRemitos(r);
        }
      });
  };

  const fetchData = useCallback(async () => {
    try {
      //setIsLoading(true);
      const resultRemitos = await client.query({
        query: GET_REMITOS_BY_RACK,
        variables: { rack: tkRack.id },
        fetchPolicy: "no-cache",
      });
      const remiDB = resultRemitos.data.getRemitosByRack;
      if (remiDB) {
        const remiArray = remiDB.map((r) => ({
          id: r._id,
          fecha: r.createdAt,
          monto: r.costo_venta,
          sinSaldar: r.sinSaldar,
          estado: r.estado,
          repartidor: r.id_repartidor?.nombre + " " + r.id_repartidor?.apellido,
          detalle: r.detalle,
          pagadoMp: r.pagadoMp,
          metodo:r.metodo,
        }));
        setRemitos(remiArray);
        //setIsLoading(false);

        //obtengo saldos de MP

        const resultSaldoMP = await client.query({
          query: GET_SALDO_MP_BY_RACK,
          variables: { rack: tkRack.id },
          fetchPolicy: "no-cache",
        });
        if (resultSaldoMP.data.getSaldoFavorRack) {
          setSaldoMP(resultSaldoMP.data.getSaldoFavorRack);
        }
        return remiArray;
      }
    } catch (err) {
      setError("¡ Rack no encontrado !");
      console.log(error, err);
      //setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* const grabarAceptaRemito = useCallback(async (obj) => {
    try {
      console.log("obj", obj);
      const resultRemito = await client.query({
        query: UPDATE_ACEPTA_REMITO,
        variables: { remito: obj },
        fetchPolicy: "no-cache",
      });

      if (resultRemito.data.updateTkRemito) return true;
      else {
        Swal.fire(
          "Error!",
          "Por favor reintentar más tarde o comunicarse con la empresa.",
          "error"
        );
        return false;
      }
    } catch (error) {
      Swal.fire(
        "Error de Query!",
        "Por favor reintentar más tarde o comunicarse con la empresa.",
        "error"
      );
      return false;
    }
  }, []); */

  const calcTotPagar = (total) => {
    const resta = total - saldoMP;
    return resta >= 0 ? resta : 0;
  };

  const mostrarDetalleRemito = (remito) => {
    let estadoEstilo = "";
    let estado = "";
    //let saldoMPMsj = `<p class="text-right mr-1 text-success">Saldo a favor (MP): $ ${saldoMP}</p>`;
    let saldo = `<h5 class="text-right mr-1">Total a pagar: $ ${calcTotPagar(
      remito.monto
    )}`;
    let msj = "";
    switch (remito.estado) {
      case "PAGADO":
        estadoEstilo = "pagado";
        estado = "PAGADO";
        //aca se podria mostrar el componente de MP del total pagado
        break;
      case "PENDIENTE":
        estadoEstilo = "pendiente";
        estado = "PENDIENTE";
        saldo = "";
        break;
      case "CANCELADO":
        estadoEstilo = "cancelado";
        estado = "CANCELADO";
        saldo = "";
        break;
      case "STOCK INICIAL":
        estadoEstilo = "pagado";
        estado = "PAGADO";
        break;
      case "RESET STOCK":
        estadoEstilo = "pagado";
        estado = "PAGADO";
        break;
      default:
        break;
    }
    const fechaRemito = moment(remito.fecha).format("DD-MM-YYYY HH:mm");
    const head = `<div class='comprobante ${estadoEstilo}'>`;
    let output = "<h4>Remito</h4><h5>" + fechaRemito + " hs</h5><hr>";
    const sticker = `<div
        class='comprobanteSticker ${estadoEstilo}'>
        ${estado}
        </div>`;
    remito.detalle.map((x) => {
      if (x.cantidad) {
        const line =
          `<b>${x.cantidad}</b> ${x.description} [$ ${x.price_cost}]` +
          `<p class="subTotal">$ ${x.subTotalCost}</p>`;
        return (output += `<p class="filaSwal">${line}</p>`);
      }
    });
    if (estado === "PAGADO") {
      //PAGADO
      const mp = remito.pagadoMp
        ? `<h5 class="text-right mr-1">Pagado MP: $ ${remito.pagadoMp}</h5>`
        : "";
      output += `<hr><p class="text-right mr-1">Total: $ ${remito.monto}</p>${mp}</div>`;
      Swal.fire({
        html: sticker + head + output,
        buttonsStyling: true,
        padding: "40px 0 20px 0",
        showConfirmButton: true,
        confirmButtonText: "CERRAR",
        showDenyButton: tkUser.tipo === "Repartidor" && !remito.pagadoMp,
        denyButtonText: "ANULAR REMITO",
        denyButtonColor: "#d33",
        allowEscapeKey: false,
      }).then(async (res) => {
        if (res.isDenied) {
          const envases =
            remito.detalle.find((r) => r.code === "010008")?.modifierStock *
              -1 || 0;
          const msjEnvases = envases ? `y <b>${envases} envase${
            envases > 1 ? "s" : ""
          }</b> repuestos` : ``;
          Swal.fire({
            html: `<span>El remito será anulado; la mercadería restada del stock ${msjEnvases}...</span>`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "ACEPTAR",
            confirmButtonColor: "#d33",
            cancelButtonText: "CANCELAR",
            focusConfirm: false,
          }).then(async (res) => {
            if (res.isConfirmed) {
              const data = await client.query({
                query: CANCEL_REMITO,
                variables: { idRemito: remito.id },
                fetchPolicy: "no-cache",
              });
              const aux = remitos.map((r) => {
                if (r.id === remito.id) {
                  remito.estado = "CANCELADO";
                  return remito;
                } else return r;
              });
              setRemitos(aux);
            }
          });
        }
      });
    } else {
      //PENDIENTE O CANCELADO
      //a pagar con MP
      if (remito.estado.toLowerCase() === "cancelado")
        output += `<hr><p class="text-right mr-1">Total: $ ${remito.monto}</p>${saldo}</h5></div>`;
      else
        output += `<hr><p class="text-right mr-1">Total a pagar: $ ${remito.sinSaldar}</p>${saldo}</h5></div>`;

      if (tkUser.tipo === "Playero") {
        // PLAYERO
        msj = `</br><p>Pagá y mostrá este comprobante al repositor para que te baje la mercadería!</p>`;
        Swal.fire({
          html: sticker + head + output + msj,
          padding: "40px 0 20px 0",
          showDenyButton: estado === "PENDIENTE",
          showConfirmButton: estado === "PENDIENTE",
          showCancelButton: estado === "CANCELADO",
          showCloseButton: estado === "PENDIENTE",
          cancelButtonText: "CERRAR",
          cancelButtonColor: "#2778c4",
          closeButtonHtml:
            '<span class="m-0 h2" aria-hidden="true">&times;</span>',
          confirmButtonText: "PAGAR c/ MercadoPago",
          denyButtonText: `ANULAR`,
          allowEscapeKey: false,
        }).then(async (result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            try {
              setPagoMPEnProceso(true);
              const remitoMP = await client.query({
                query: PAGAR_REMITO_MP,
                variables: { idRemito: remito.id },
                fetchPolicy: "no-cache",
              });

              if (remitoMP.data.pagarRemitoMP) {
                localStorage.setItem(
                  "remitoPendienteMP",
                  remitoMP.data.pagarRemitoMP.id
                );
                localStorage.setItem("recargarArts", true); //pago realizado, hay que recargar arts
                localStorage.setItem("fpol", "no-cache"); //pago realizado, hay que recargar arts
                window.location.href = remitoMP.data.pagarRemitoMP.url;
              } else {
                Swal.fire(
                  "Error!",
                  "Query realizada pero su devolución es vacía.",
                  "error"
                );
              }
              //setDisabled(true);
            } catch (error) {
              //setDisabled(false);
              console.log("error", error);
              Swal.fire(
                "Error de Query!",
                "Reintentar más tarde o comunicarse con la empresa.",
                "error"
              );
            }

            //Swal.fire('Pagado!', '', 'success')
          }
          if (result.isDenied)
            Swal.fire({
              html: "<span>El remito será anulado...</span>",
              showConfirmButton: true,
              showCancelButton: true,
              confirmButtonText: "ACEPTAR",
              confirmButtonColor: "#d33",
              cancelButtonText: "CANCELAR",
              focusConfirm: false,
            }).then(async (res) => {
              if (res.isConfirmed) {
                const data = await client.query({
                  query: CANCEL_REMITO,
                  variables: { idRemito: remito.id },
                  fetchPolicy: "no-cache",
                });
                const aux = remitos.map((r) => {
                  if (r.id === remito.id) {
                    remito.estado = "CANCELADO";
                    return remito;
                  } else return r;
                });
                setRemitos(aux);
              }
            });
        });
      } else {
        // REPARTIDOR
        Swal.fire({
          html: sticker + head + output,
          buttonsStyling: true,
          padding: "40px 0 20px 0",
          showConfirmButton: true,
          confirmButtonText: "CERRAR",
          showDenyButton: estado === "PENDIENTE",
          denyButtonText: "ANULAR REMITO",
          denyButtonColor: "#d33",
          allowEscapeKey: false,
        }).then(async (res) => {
          if (res.isDenied) {
            Swal.fire({
              html: "<span>El remito será anulado...</span>",
              showConfirmButton: true,
              showCancelButton: true,
              confirmButtonText: "ACEPTAR",
              confirmButtonColor: "#d33",
              cancelButtonText: "CANCELAR",
              focusConfirm: false,
            }).then(async (res) => {
              if (res.isConfirmed) {
                const data = await client.query({
                  query: CANCEL_REMITO,
                  variables: { idRemito: remito.id },
                  fetchPolicy: "no-cache",
                });
                const aux = remitos.map((r) => {
                  if (r.id === remito.id) {
                    remito.estado = "CANCELADO";
                    return remito;
                  } else return r;
                });
                setRemitos(aux);
              }
            });
          }
        });
      }
      //codigo en el cayo que haya que aceptar remitos, no borrar
      const inputOk = async () => {
        /*const inputValue = "";
                 const { value: valor } = await Swal.fire({
          html: sticker + head + output + msj,
          padding: "25px 0 0 0",
          input: "text",
          inputLabel: `Luego ingresar OK para aceptar y registrar el nuevo Stock`,
          inputValue: inputValue,
          showCancelButton: true,
          inputAttributes: { autocomplete: "off" },
          inputValidator: (value) => {
            if (value.toLowerCase() !== "ok") {
              return "Debes escribir OK para aceptar y registrar el nuevo Stock";
            }
          },
          
 */
        /*         if (valor?.toLowerCase() === "ok") {
          output += ` `;
          const remiGrabar = {
            _id: remito.id,
            id_rack: tkRack.id,
            estado: "ACEPTADO",
          };
          if (grabarAceptaRemito(remiGrabar)) {
            const head = `<div class='comprobante aceptado'>`;
            const sticker = `<div class='comprobanteSticker aceptado'>Aceptado</div>`;
            const msj = `</br><p>El stock de la app fue actualizado con exito!</p><p>Por favor abonalo a <b>${remito.repartidor} para que te baje la mercadería</b></p>`;
            Swal.fire({
              icon: "success",
              html: sticker + head + output + msj,
              padding: "0",
            });
            fetchData(tkRack.id);
            localStorage.setItem("recargarArts", true);
          }
        }
 */
      };
      //inputOk();
    }
  };

  return !remitos || pagoMPEnProceso ? (
    <>
      <div className="container bg-white p-5 my-5">
        <h5 className="text-center text-muted m-3">
          {pagoMPEnProceso
            ? "Redirigiendo a Mercado Pago..."
            : "Cargando remitos..."}
        </h5>

        <div className="row">
          <div className="col d-flex justify-content-center">
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div id="tkRemitos" className="listados">
      <div className="divScrolleable m-3">
        <table className="recibosTable table table-striped table-sm">
          <thead className="recibosTitle">
            <tr className="text-center">
              <th>Fecha</th>
              <th>Estado</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {remitos.map((remito) => (
              <tr
                key={remito.id}
                onClick={() => mostrarDetalleRemito(remito)}
                className={`filaRecibo ${
                  remito.estado.toLowerCase() === "pendiente"
                    ? "text-danger"
                    : remito.estado.toLowerCase() === "cancelado"
                    ? "anulado"
                    : ""
                }`}
              >
                <td className="px-3 text-nowrap">
                  {moment(remito.fecha).format("DD/MM/YYYY HH:mm")}
                </td>
                <td className="px-3 text-center">{remito.estado==="CANCELADO"?"ANULADO":remito.estado} {remito.metodo==="Mercado Pago"?"(MP)":""}</td>
                <td className="px-3 text-right">$ {remito.monto}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TkRemitos;
