import gql from 'graphql-tag';

export const GET_RESUME_FROM_DATE = gql`
  query getResumeFromDate($user_code: String!, $date: String!) {
    getResumeFromDate(user_code: $user_code, date: $date) {
      date
      remito
      address
      cant
      description
    }
  }
`;

export const GET_INVOCE_LAST_THREE_MONTH = gql`
  query getLastThreeMonth($user_code: String!, $type: String) {
    getLastThreeMonth(user_code: $user_code, type: $type) {
      id
      amount
      description
      date
    }
  }
`;

//comprobante
export const GET_CPR_PIE = gql`
  query getCPRPir($id: Float!) {
    getCPRPir(id: $id) {
      id
      cpr_id
      descripcion
      total
      total_neto
      porcentaje
    }
  }
`;

export const GET_CPR_DATA_PRINTING = gql`
  query getCPRDataPrinting($id: Float!) {
    getCPRDataPrinting(id: $id) {
      address
      cant
      clientCode
      code
      date
      descrition
      codeArt
      fullName
      id
      letter
      locality
      num
      saldo
      tipo
      saldo_total
      saldo_neto
      cuit
      condicion
      tipo_pago
      cae
      cae_vencimiento
    }
  }
`;

export const GET_REMITOS_RELACIONADOS = gql`
  query getRemitosRelacionados($id: String!) {
    getRemitosRelacionados(id: $id) {
      id
      punto_venta
      letra
      numero
    }
  }
`;
