// const type = localStorage.getItem("type");

export const TOKENS = "token";
export const USER_LOGOUT = "USER_LOGOUT";

export const ALERT_TYPE = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  SUCCESS: "success",
  DANGER: "danger",
  WARNING: "warning",
  INFO: "info",
  LIGHT: "light",
  DARK: "dark",
};

export const FACTURACION = {
  GENERAL: "FACTURACION GENERAL",
  DIA_20: "FACTURACION DIA 20",
  DIA_UNO: "FACTURACION 1º DE MES",
  ESPECIAL: "FACTURACION ESPECIAL",
};

export const DIAS_SEMANA = {
  LUNES: "LUNES",
  MARTES: "MARTES",
  MIERCOLES: "MIERCOLES",
  JUEVES: "JUEVES",
  VIERNES: "VIERNES",
  SABADO: "SABADO",
  DOMINGO: "DOMINGO",
};

export const TIPO_CONTACTOS = {
  PINCHADO: "Bidon pinchado",
  FALLA_EQUIPO: "Falla en equipo",
  SIN_ATENCION: "Sin atención",
  NO_CONFORME: "Producto no conforme",
  SUGERENCIA: "Sugerencia",
  OTRO: "Otros"
}

/**
 * Listado de tipos de reclamos [... { id, nombre, valor, visitable  } ...]
 * */
export const TIPO_RECLAMOS = [
  { id:"r1", nombre: "Bidon pinchado", valor: "PINCHADO", visitable: true },
  { id:"r2", nombre: "Falla en equipo", valor: "FALLA_EQUIPO", visitable: true },
  { id:"r7", nombre: "Facturación / Cta. cte", valor: "FACTURACION", visitable: false},
  { id:"r3", nombre: "Sin atención", valor: "SIN_ATENCION", visitable: false },
  { id:"r4", nombre: "Producto no conforme", valor: "NO_CONFORME", visitable: false },
  { id:"r5", nombre: "Sugerencia", valor: "SUGERENCIA", visitable: false },
  { id:"r6", nombre: "Otros", valor: "OTRO", visitable: false }
]
