import React, {useCallback, useEffect} from 'react';
import {useParams} from "react-router";
import {FLOGIN, GET_USER_CODE} from "../query/login/login.query";
import {store} from "../redux/duck/duck";
import {CLIENT_CREATE} from "../redux/duck/client-duck";
import {USER_CREATE} from "../redux/duck/user-duck";
import Swal from "sweetalert2";
import {useApolloClient} from "@apollo/react-hooks";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import token from "react-bootstrap-typeahead/lib/components/Token";

const AutoloadPage = () => {
    const {id} = useParams();
    const client = useApolloClient();
    const dispatch = useDispatch();
    const history = useHistory();

    const dat = useCallback(async () => {
        const vars = {
            name: id,
            password: "",
        };
        try {
            const queryLogin = await client.query({
                query: FLOGIN,
                variables: vars,
                fetchPolicy: 'no-cache',
            })
                .catch(error => {
                    console.log("error queryL", error);
                });
            const login = queryLogin ? queryLogin.data.Forcelogin : null;

            if (login) {
                console.log("entro?");
                const queryCode = await client.query({
                    query: GET_USER_CODE,
                    variables: {
                        token: login,
                    },
                    fetchPolicy: 'no-cache',
                });
                console.log(queryCode);

                const code = queryCode ? queryCode.data.getClientCodeByToken : null;
                localStorage.setItem('token', login);

                console.log("storage::", store.getState());

                if (code) {
                    localStorage.setItem('client_code', code);
                    dispatch({
                        type: CLIENT_CREATE,
                        client: code,
                    });
                }
                dispatch({
                    type: USER_CREATE,
                    user: login,
                });
                history.push('/');
            } else {
                Swal.fire({
                    title: 'Login!',
                    text: 'Error al iniciar sesión',
                    icon: 'error',
                }).then(resp =>{
                    history.push('/');
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Login!!',
                text: 'Error al iniciar sesión, por favor contacte al administrador',
                icon: 'error',
            })
                .then(resp =>{
                history.push('/');
            });
        }

    });

    useEffect(() => {
        dat();
    }, []);


    return (<>
        <div className='container bg-white p-5 my-5'>
            <div className='row'>
                <div className='col d-flex justify-content-center'>
                    <div className='spinner-grow text-primary' role='status'>
                        <span className='sr-only'>Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default AutoloadPage;