import React from 'react';
import './App.scss';
import MainPage from './pages/main.page';

import { ApolloProvider as  ApolloProviderHooks} from '@apollo/react-hooks';

import client from './helpers/client/client.helper';

// Lightbox
import SimpleReactLightbox from 'simple-react-lightbox';

// redux
import { Provider } from 'react-redux';
import { store, persistor } from './redux/duck/duck';
import { PersistGate } from 'redux-persist/integration/react';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import 'react-bootstrap-typeahead/css/Typeahead.css';

import 'jquery';
import 'popper.js';
import 'bootstrap';

import './App.scss';

// component

function App() {
  return (
    <ApolloProviderHooks client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SimpleReactLightbox>
            <MainPage />
          </SimpleReactLightbox>
        </PersistGate>
      </Provider>
    </ApolloProviderHooks>
  );
}

export default App;
