import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// router-dom
import { useHistory } from "react-router-dom";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faTruckLoading,
} from "@fortawesome/free-solid-svg-icons";

// redux functions
import { TK_SHOPPING_ADD } from "../../redux/duck/tk.shopping.duck";

// query

// hook form
//import { useForm } from "react-hook-form";

// react-reveal
import Fade from "react-reveal/Fade";

import "../../scss/takeaway/shopping.scss";

function RepartidorTakeaway({ arts }) {
  const dispatch = useDispatch();
  const shopping = useSelector((state) => state.tkShopping);
  const tkRack = useSelector((state) => state.tkRack);
  const tkUser = useSelector((state) => state.tkUser);
  const history = useHistory();

  //const client = useApolloClient();

  const [articulos, setArticulos] = useState([]);
  // const [filtered, setFiltered] = useState([]);
  // const [tags, setTags] = useState([]);
  // const [activeTag, setActiveTag] = useState("TODO");
  const [articulosInputReferences] = React.useState([]);
  //const [userType, setUserType] = useState("");
  const [rackID, setRackID] = useState("");
  //const [error, setError] = useState("");
  const [userLoading, setUserLoading] = useState("");
  const [envAguaIndex, setEnvAguaIndex] = useState(-1);
  const [stockEnvAgua, setStockEnvAgua] = useState(0);
  const [envaTendra, setEnvaTendra] = useState(0);

  //const { register, handleSubmit, watch, reset } = useForm();

  /*       const data = dat.sort((a, b) => {
        if (a.description_alt > b.description_alt) {
          return 1;
        }
        if (a.description_alt < b.description_alt) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
 */
  useEffect(() => {
    if (arts) {
      setArticulos(arts);
      const enva = arts.find((x) => x.code === "010008")?.stock;
      setStockEnvAgua(enva);
      localStorage.setItem("stockEnvaVacios", enva);
      setEnvaTendra(enva - calcBidones());
    }
  }, [arts]);

  const fetchData = () => {
    setArticulos(arts);
  };

  useEffect(() => {
    fetchData();
    if (tkUser.isLoading || !tkUser.isLoading) setUserLoading(tkUser.isLoading);
    if (tkRack?.id) setRackID(tkRack.id);
  }, [tkUser?.tipo || tkRack?.id]);

  const isBidones = (code, cumulo) => {
    if (code === "010001" || code === "010002") {
      const findEnvAguaIndex = (enva) => {
        if (envAguaIndex < 0) {
          const indexEnvases = articulosInputReferences.findIndex(
            (x) => x.id === enva
          );
          setEnvAguaIndex(indexEnvases);
          return indexEnvases;
        } else return envAguaIndex;
      };
      const envaCode = "010008";
      const envAguaInd = findEnvAguaIndex(envaCode);
      if (envaDebe()) {
        cargaShopping(envAguaInd, envaCode, cumulo);
      } else {
        cargaShopping(envAguaInd, envaCode, 0);
      }
    }
  };

  const add = (index, code) => {
    cargaShopping(index, code, 1);
    isBidones(code, 1); // si carga Bidones, actualiza Envases
    calcBidonesTendra(code);
  };

  const minus = (index, code) => {
    isBidones(code, -1); // si carga Bidones, actualiza Envases
    cargaShopping(index, code, -1);
    calcBidonesTendra(code);
  };

  const cargaShopping = (index, code, cumulo) => {
    const cant = parseInt(articulosInputReferences[index].value) + cumulo;
    if (cant >= 0) {
      articulosInputReferences[index].value = cant;
      const article = articulos.find((ele) => ele.code === code);
      dispatch({
        type: TK_SHOPPING_ADD,
        shopping: {
          artId: article.id,
          code: code,
          cantidad: cant,
          lista: article.lista_id,
          price_cost: article.price_cost.toFixed(2),
          description: article.description,
          stock: article.stock,
          stock_min: article.stock_min,
          image: article.image,
        },
      });
    }
  };

  const onChange = (index, code) => {
    const cant = parseInt(articulosInputReferences[index].value);
    if (cant >= 0) {
      articulosInputReferences[index].value = cant;
      const article = articulos.find((ele) => ele.code === code);
      dispatch({
        type: TK_SHOPPING_ADD,
        shopping: {
          artId: article.id,
          code: code,
          lista: article.lista_id,
          cantidad: cant,
          price_cost: article.price_cost.toFixed(2),
          description: article.description,
          stock: article.stock,
          stock_min: article.stock_min,
          image: article.image,
        },
      });
    }
  };

  const setRef = (index, ref) => {
    articulosInputReferences[index] = ref;
  };

  const validate = (event) => {
    const theEvent = event || window.event;
    let key;

    // Handle paste
    if (theEvent.type === "paste") {
      key = event.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const redirectOrders = (id) => {
    localStorage.setItem("userFromOrder", rackID);
    history.push(`/takeaway/${id}/pedidos`);
  };

  const totalArticles = () => {
    let ta = 0;
    for (const order of shopping) {
      ta += order.cantidad;
    }
    return ta;
  };

  const calcCant = (code) => {
    return shopping.find((ele) => ele.code === code)?.cantidad | 0;
  };

  const calcBidones = () => {
    const codeB12 = "010002";
    const codeB20 = "010001";
    const b12 = shopping.find((ele) => ele.code === codeB12)?.cantidad | 0;
    const b20 = shopping.find((ele) => ele.code === codeB20)?.cantidad | 0;
    return b12 + b20;
  };

  const calcBidonesTendra = (code) => {
    if (code === "010001" || code === "010002") {
      const tendra = stockEnvAgua - calcBidones();
      setEnvaTendra(tendra);
    }
  };

  const envaDebe = () => {
    const ret = calcBidones() - stockEnvAgua;
    return ret < 0 ? 0 : ret;
  };

  return !articulos || userLoading ? (
    <div className="container bg-white p-5 my-5">
      <div className="row">
        <div className="col d-flex justify-content-center">
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Buscando artículos...</span>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      id="shopping_repartidor"
      className="d-flex flex-grow-1 justify-content-center"
    >
      <div className="products frex-column justify-content-center align-content-center my-1">
        <div className="d-flex flex-wrap justify-content-center">
          {articulos.map((product, index) => (
            <div
              key={index}
              className={`product ${
                calcCant(product.code) ? "active" : "inactive"
              } m-1 p-1 text-center border rounded mt-2`}
            >
              <div
                className={`stockSticker ${
                  product.code !== "010008" &&
                  product.stock <= product.stock_min
                    ? "enRojo"
                    : ""
                }`}
              >
                <div>Tiene</div>
                <div>
                  {product.stock}
                </div>{" "}
              </div>
              {calcCant(product.code) > 0 &&
                product.code !== "010008" &&
                calcCant(product.code) + product.stock <= product.stock_min && (
                  <div className={`stockStickerPlus enRojo`}>
                    <div>Tendrá</div>
                    <div>{calcCant(product.code) + product.stock}</div>{" "}
                  </div>
                )}

              {calcCant(product.code) > 0 &&
                product.code !== "010008" &&
                calcCant(product.code) + product.stock > product.stock_min && (
                  <div className={`stockStickerPlus`}>
                    <div>Tendrá</div>
                    <div>{calcCant(product.code) + product.stock}</div>{" "}
                  </div>
                )}

              {/*             S T I C K E R S                  */}
              {/*              E N V A S E S                   */}

              {/*              TENDRÁ ENVASES                  */}

              {product.code === "010008" && envaTendra < stockEnvAgua && (
                <div
                  className={`stockStickerPlus ${
                    envaTendra < 1 ? "enRojo" : ""
                  }`}
                >
                  <div>Tendrá</div>
                  <div>{envaTendra > 0 ? envaTendra : "0"}</div>{" "}
                </div>
              )}
              {/*                DEBE ENVASES                 */}
              {product.code === "010008" && envaTendra < 0 && (
                <Fade right>
                  <div className={`debeSticker debe`}>
                    <div>Debe</div>
                    <div>
                      <b>{-envaTendra}</b>{" "}
                      <div>envase{envaTendra < -1 ? "s" : " "}</div>
                    </div>
                  </div>
                </Fade>
              )}

              <div className="imgContainer">
                <img
                  className={`card-img-top ${product.code==="010008"?"envase":""}`}
                  src={`data:image/png;base64,${product.image}`}
                  alt={product.description}
                />
              </div>
              <div className="description">{product.description}</div>
              {/* <div className="price">$ {product.cost}</div> */}
              <span className="price">$ {product.price_cost} </span>

              <div
                className={`botonera ${
                  calcCant(product.code) ? "activa" : "inactiva"
                } d-inline-flex flex-wrap justify-content-center mx-2`}
              >
                <div>
                  <button
                    className={`minusBtn ${
                      calcCant(product.code) === 0 ||
                      (product.code === "010008" &&
                        calcCant(product.code) <= envaDebe())
                        ? "inactivo"
                        : ""
                    }`}
                    onClick={() => minus(index, product.code)}
                    disabled={
                      calcCant(product.code) === 0 ||
                      (product.code === "010008" &&
                        calcCant(product.code) <= envaDebe())
                    }
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </button>
                </div>
                <div className="contentCant mx-1">
                  <input
                    id={product.code}
                    className="form-control"
                    type="number"
                    step="1"
                    min="0"
                    value={calcCant(product.code)}
                    onKeyPress={(event) => validate(event)}
                    onChange={() => onChange(index, product.code)}
                    ref={(ref) => setRef(index, ref)}
                    readOnly
                    style={
                      calcCant(product.code) > 0
                        ? {
                            color: "blue",
                            backgroundColor: "azure",
                            fontWeight: "bold",
                          }
                        : { color: "grey " }
                    }
                  />
                </div>
                <div>
                  <button
                    className={`addBtn`}
                    onClick={() => add(index, product.code)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="footer d-flex flex-column flex-grow-1 mt-3 justify-self-center">
          <div className="text-secondary text-center bd-highlight">
            TakeAway de: <b>{ (tkRack.responsables)?tkRack.responsables.join(" / "): "" }</b>
          </div>
          <div className="m-3">
            {(shopping.length > 0 && (
              <Fade left>
                <button
                  type="button"
                  className="btn btn-dark btn-block justify-content-center align-items-center"
                  onClick={() => redirectOrders(rackID)}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Tooltip on top"
                  disabled={totalArticles() === 0}
                >
                  SIGUIENTE{" "}
                  <FontAwesomeIcon icon={faTruckLoading} color={"#007bff"} />
                  {shopping.length > 0 && (
                    <span className="totalArticles ml-2 badge badge-light">
                      {totalArticles()}
                    </span>
                  )}
                  {shopping.length === 0 && ``}
                </button>
              </Fade>
            )) || (
              <button
                type="button"
                className="btn btn-dark btn-block justify-content-center align-items-center"
                onClick={() => redirectOrders(rackID)}
                data-toggle="tooltip"
                data-placement="top"
                title="Tooltip on top"
                disabled={totalArticles() === 0}
              >
                CARGA{" "}
                <FontAwesomeIcon icon={faTruckLoading} color={"#007bff"} />
                {shopping.length > 0 && (
                  <span className="totalArticles ml-2 badge badge-light">
                    {totalArticles()}
                  </span>
                )}
                {shopping.length === 0 && ``}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RepartidorTakeaway;
