// users.js

export const DISPONIBLE_CREATE = 'DISPONIBLE_CREATE';
export const DISPONIBLE_DELETE = 'DISPONIBLE_DELETE';

export default function user(state = {}, action) {
  //console.log('user', state, action);
  switch (action.type) {
    case DISPONIBLE_CREATE:
      console.log('disponible', action.disponible);
      return Object.assign({}, state, action.disponible);
    case DISPONIBLE_DELETE:
      return Object.assign({}, state, null);
    default:
      return state;
  }
}
