import React, { useEffect, useCallback, useState } from 'react';

// redux
import { useSelector } from 'react-redux';

// apollo
import { useApolloClient } from '@apollo/react-hooks';

// query
import { GET_RESUME_FROM_DATE } from '../query/receipt/receipt.query';

// moment
import moment from 'moment';

const ExtraConsumptionsPage = () => {
  // dispatch
  const code = useSelector((state) => state.client);

  // apollo client
  const client = useApolloClient();

  // state
  const [productos, setProductos] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const current_date = moment().format('YYYY-MM-DD');
      console.log('current_date', current_date);
      const resultLogs = await client.query({
        query: GET_RESUME_FROM_DATE,
        variables: {
          user_code: code.client,
          date: current_date,
        },
        fetchPolicy: 'no-cache',
      });

      const resume = resultLogs.data.getResumeFromDate;
      console.log('resume', resume);
      setProductos(resume);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='row mt-5'>
      <div className='col'>
        <div>
          <h4 className='text-info'>Consumos del mes</h4>
        </div>
        <div className='table-responsive'>
          <table className='table'>
            <caption>Consumos del mes</caption>
            <thead>
              <tr>
                <th scope='col'>Fecha</th>
                <th scope='col'>Remito</th>
                <th scope='col'>Direccion</th>
                <th scope='col'>Producto</th>
                <th scope='col'>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {productos.length > 0 &&
                productos.map((producto, index) => {
                  return (
                    <tr key={index}>
                      <td>{producto.date}</td>
                      <td>{producto.remito}</td>
                      <td>{producto.address}</td>
                      <td>{producto.description}</td>
                      <td>{producto.cant}</td>
                    </tr>
                  );
                })}
              {productos.length === 0 && (
                <tr>
                  <td colSpan='2' className='text-center text-muted'>
                    No hay consumos registrados
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ExtraConsumptionsPage;
