import gql from 'graphql-tag';

export const GET_PREFERENCE_ID = gql`
  query getPreference($amount: Float!, $cli_code: String!) {
    getPreference(amount: $amount, cli_code: $cli_code)
  }
`;

export const GET_SALDO = gql`
  query findClientSaldo($token: String!) {
    findClientSaldo(token: $token)
  }
`;
